import {Pipe, PipeTransform} from '@angular/core';
import {IsbnIllustrationColorEnum} from "../base/contituents/isbn-application/enums/isbn-illustration-color.enum";

@Pipe({
  name: 'isbnColorIllustration'
})
export class ColorIllusPipe implements PipeTransform {

  transform(value: IsbnIllustrationColorEnum): string {
    switch (value) {
      case IsbnIllustrationColorEnum.BlackAndWhite:
        return "Black and White";
      case IsbnIllustrationColorEnum.Color:
        return "Color";
      case IsbnIllustrationColorEnum.None:
          return "None";
    }
    return "Unknown";
  }
}
