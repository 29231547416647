import {Component} from '@angular/core';
import {BreadcrumbsService, BreadcrumbsMenuItem, Breadcrumb} from './BreadcrumbsService';

/**
 * This class represents the navigation bar component.
 */
@Component({
    selector: 'breadcrumbs',
    templateUrl: 'breadcrumbs.component.html',
})

export class BreadcrumbsComponent {

    public items: Array<BreadcrumbsMenuItem> = [];

    constructor(public breadcrumbs: BreadcrumbsService) {

    }

    isVisible() {
        return this.breadcrumbs.getVisibility();
    }

    isActive(breadcrumb: Breadcrumb): boolean {
        if (this.items.length <= 0) {
            return false;
        }

        return this.items[this.items.length - 1] === breadcrumb;

    }

    public getItems(): Array<BreadcrumbsMenuItem> {
        this.items = this.breadcrumbs.getItems();
        return this.items;
    }


}

