import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'brandLogoUrl' })
export class BrandLogoUrlPipe implements PipeTransform {
    transform(input: string) {
        if(typeof input === "undefined"){
            return '';
        }

        var output:string ='';
        var words = input.split(' ');
        for(var k in words){
            output+= words[k].charAt(0);
        }
        return output;
    }
}
