import { EventEmitter, Injectable } from '@angular/core';
import { BaseService } from "../../system/services/BaseService";
import { NicoHttp } from "../../system/requests/NicoHttp";
import {environment} from "../../../environments/environment";
import {IsbnApplication} from "../../admin-module/pages/isbn-applications/models/IsbnApplication";
import {Observable} from "rxjs";
import {BaseIssuedISBN} from "../base/contituents/isbn-application/models/BaseIssuedISBN";
import {map} from "rxjs/operators";
import {ServerResponse} from "../../system/responses/ServerResponse";
@Injectable()
export class IsbnApplicationService extends BaseService {

    /**
     * The resource
     * @type {string}
     */
    protected resourceName: string = 'isbn-applications';
    /**
     * Base resource url
     * @type {string}
     */
    protected resourceBaseUrl: string = '';
    /**
     *
     * @type {User}
     */
    protected resource: IsbnApplication;

    /**
     * Constructor
     * @param http
     */
    public constructor(protected http: NicoHttp) {
        super(http, environment);
        this.resource = new IsbnApplication();
        this.setResourceName(this.resourceName);
    }

    public commit (model: IsbnApplication): Observable<any> {
      return this.http.post(this.resourceBaseUrl + '/' + model.id + '/commit', null);
    }

    public markAsReceived (model: IsbnApplication, value: any): Observable<any> {
      return this.http.post(this.resourceBaseUrl + '/' + model.id + '/mark-as-received', value);
    }

    public issueISBN (model: IsbnApplication, value: any, isbn?: BaseIssuedISBN): Observable <BaseIssuedISBN> {

      let ret = <Observable<BaseIssuedISBN>>(this.http.post (this.resourceBaseUrl + '/' + model.id + '/issued-isbns', value )
        .pipe(map((resp: ServerResponse) => {
          return resp.body;
        })));

      if (isbn && isbn.id) {
        ret = <Observable<BaseIssuedISBN>>(this.http.put (this.resourceBaseUrl + '/' + model.id + '/issued-isbns/' + isbn.id, value )
          .pipe(map((resp: ServerResponse) => {
            return resp.body;
          })));
      }

      return ret;
    }

    public getNewIsbn(app: IsbnApplication) {
      return <Observable<BaseIssuedISBN>>(this.http.get (this.resourceBaseUrl + '/' + app.id + '/issued-isbns/new-isbn-identifier', null, false)
        .pipe(map((resp: ServerResponse) => {
          return resp.body;
        })));
    }

    public commitIssuedISBN(app: IsbnApplication, isbnModel: BaseIssuedISBN) {
      return <Observable<BaseIssuedISBN>>(this.http.post (this.resourceBaseUrl + '/' + app.id + '/issued-isbns/' + isbnModel.id + '/commit', isbnModel )
        .pipe(map((resp: ServerResponse) => {
          return resp.body;
        })));
    }

}
