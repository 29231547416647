import {Pipe, PipeTransform} from '@angular/core';
import {SessionAnalyticsEnum} from '../enums/session-analytics.enum';
import {VideoAnalyticsEnum} from '../enums/video-analytics.enum';

@Pipe({
  name: 'analyticsKeys'
})
export class AnalyticsKeysPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case SessionAnalyticsEnum.AverageSessionDuration:
        return 'mod_manage.mod_dashboard.session_stats.avg_session_duration';
      case SessionAnalyticsEnum.BounceRate:
        return 'mod_manage.mod_dashboard.session_stats.bounce_rate_label';
      case SessionAnalyticsEnum.Bounces:
        return 'mod_manage.mod_dashboard.session_stats.bounces_label';
      case SessionAnalyticsEnum.Hits:
        return 'mod_manage.mod_dashboard.session_stats.total_hits';
      case SessionAnalyticsEnum.NewUsers:
        return 'mod_manage.mod_dashboard.session_stats.new_users_label';
      case SessionAnalyticsEnum.Sessions:
        return 'mod_manage.mod_dashboard.session_stats.sessions_label';
      case SessionAnalyticsEnum.Users:
        return 'mod_manage.mod_dashboard.session_stats.users_label';
      case VideoAnalyticsEnum.AverageEventDuration:
        return 'mod_manage.mod_dashboard.video_stats.avg_view_duration_label';
      case VideoAnalyticsEnum.EventDuration:
        return 'mod_manage.mod_dashboard.video_stats.total_views_duration_label';
      case VideoAnalyticsEnum.TotalEventCount:
        return 'mod_manage.mod_dashboard.video_stats.total_views_label';
      case VideoAnalyticsEnum.UniqueEventCount:
        return 'mod_manage.mod_dashboard.video_stats.unique_views_label';

      default:
    }
  }
}
