import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

  transform(value: string, args: number): string {
    if (value && value.length > args) {
      return value.substring(0, args) + '...';
    } else {
      return value;
    }
  }
}
