import {Pipe, PipeTransform} from "@angular/core";
import {StatusEnum} from "../enums/status.enum";
@Pipe({
    name: 'statusToggleCommand'
})
export class StatusToggleCommandPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        value = parseInt(value, 0 );
        switch (value) {
            case StatusEnum.Unpublished:
                return "mod_commons.publish_label";
            case StatusEnum.Published:
                return "mod_commons.unpublish_label";
            default:

        }
    }

}
