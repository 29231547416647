import {AbstractBaseResource} from '../../../../../system/datamodels/AbstractBaseResource';
import {BasePermissionGroupInterface} from '../interfaces/BasePermissionGroupInterface';
import {BasePermission} from "./BasePermission";
import {Collection} from "../../../../../system/utilities/Collection";
import {forEach} from "@angular/router/src/utils/collection";

export class BasePermissionGroup extends AbstractBaseResource implements BasePermissionGroupInterface {
  public perm_grp_id: number;

  /**
   * The primary key identifier
   * @type {string}
   */
  primaryKey: string = 'perm_grp_id';
  /**
   * The creatable Attributes of the institutions
   */
  creatableAttributes: Array<string> = ['perm_grp_id', 'namespace', 'namespace_id', 'key', 'description', 'permission', 'created_at', 'updated_at'];

  [key: string]: any;


  /**
   * Create
   * @param obj
   */
  public create(obj: any): BasePermissionGroup {
     const ret =  <BasePermissionGroup>super.create(obj);
     if (ret.permission) {
       ret.permission = new Collection<string>();
       ret.permission.setItems(obj.permission);

     }
     return ret;

  }

  hasPermission(permission: string): boolean {
    const permissions = this.permission.all();

    return permissions.indexOf(permission) > -1;
  }

}
