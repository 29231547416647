
import { Helper } from "../utilities/Helper";
/**
 * The validation Messenger Class
 */
export class SmartValidationMessenger {
  /**
   * Current element to set error for
   */
  private _element: Element;
  /**
   * The message object
   */
  private _messageObject: any;
  /**
   * The collection of tooltips
   */
  private _tooltips: Array<NicoTooltip>;

  /**
   * Constructor
   */

  public constructor() {
    this._tooltips = [];
  }

  /**
   * Append string if the string is not already there
   * @param string subject
   * @param string txt
   * @returns string
   */
  private appendStringIfNot(subject: string, txt: string) {
    if (!subject) {
      return txt;
    }
    return (subject.replace(txt, "")) + " " + txt;
  }
  /**
   * Attach the given message object to the given element
   * @param element
   * @param object
   */
  public attach(element: any, object: any) {
    this._tooltips = [];
    if (typeof element === 'string') {
      element = document.getElementById(element);
    }
    /**** if element is null then throw exception ****/
    if (!element || !(element instanceof Element)) {
      throw Error('Invalid element: The given element is either null or invalid');
    }
    this._element = element;
    this._element.className = this.appendStringIfNot(this._element.className, "has-form-error");

    this._messageObject = object;

    this.createTooltipForMessageObject();
    this.showTooltipMessages();
  }

  /**
   * Create tooltip for message object
   */
  private createTooltipForMessageObject() {
    for (let key in this._messageObject) {
      this._tooltips.push(new NicoTooltip(key, this._messageObject[key], this._element));
    }
  }

  /**
   * Show tooltip Messages
   */
  public showTooltipMessages() {
    this._tooltips.forEach((value: NicoTooltip, i: number) => {
      value.show();
    });
  }
  /**
   * Hide tooltip Messages
   */
  public hideTooltipMessages() {
    this._tooltips.forEach((value: NicoTooltip, i: number) => {
      value.hide();
    });
  }

    /**
     * get tooltip by name or id
     * @param id
     */
  public getTooltip (id: string): NicoTooltip {
    for(let i in this._tooltips) {
      if(this._tooltips[i].name === id) {
        return this._tooltips[i];
      }
    }

  }

}

/**
 * The nico tooltip
 */
export class NicoTooltip {
  private _template: HTMLDivElement = null;

  private _keyupListener: any;

  private _parentElement: Element = null;

  private _inputElement: Element = null;
  /**
   * The constructor
   * @param name
   * @param messages
   * @param container
   */
  public constructor(public name: string, public messages: Array<string>, public container: Element) {
    this._createTooltipElement();
    this._getParentElement();
  }

  /**
   *
   *
   */
  private _getParentElement(): Element {
    if (this._parentElement !== null) {
      return this._parentElement;
    }
    //first try to find element by id from the given name
    this._inputElement = document.getElementById(this.name);
    /// if no element, try to use then form name
    if (this._inputElement === null) {
      this._inputElement = this.container.querySelector(`[name='${this.name}']`);
      //if the element is still null, lets try finding the element by angular form name
      if (this._inputElement === null) {
        this._inputElement = this.container.querySelector(`[formControlName='${this.name}']`);
        //if the element is still null, simply return the container itself
        if (this._inputElement === null) {
          this._parentElement = this.container;
          return this.container;
        }
      }
    }

    //lets find the parent element of the element and return it
    this._parentElement = this._inputElement.parentElement;
    this._parentElement.className = Helper.appendTextIfDoesntExist(this._parentElement.className, 'has-error');
    //lets attach keyup event to remove any validation message for the element
    this._keyupListener = () => { this.hide(); };
    if (this._inputElement.tagName.toLowerCase() === 'select') {
      this._inputElement.addEventListener('change', this._keyupListener);
    } else {
      this._inputElement.addEventListener('keyup', this._keyupListener);
    }

    return this._parentElement;
  }

  /***
   * Create tooltip element
   *
   */
  private _createTooltipElement() {
    this._template = document.createElement('div');
    this._template.className = `nico-tooltip nico-tooltip-${this.name} entering`;
    this._template.innerHTML = Helper.arrayGet(this.messages, 0);

    //attach arrow
    let arrowElement = document.createElement("span");
    arrowElement.className = "arrow";
    this._template.appendChild(arrowElement);

    this._template.addEventListener('click', () => {
      this.hide();
    });
  }
  public appendHtmlToDocument() {
    if (this._parentElement.hasAttribute(`data-${this.name}`)) {
      this._parentElement.removeChild(document.getElementsByClassName(`nico-tooltip-${this.name}`)[0]);
    }
    this._parentElement.appendChild(this._template);
    setTimeout(() => { this._template.className = `nico-tooltip nico-tooltip-${this.name} entered`; }, 100);
    this._parentElement.setAttribute(`data-${this.name}`, 'true');
  }
  /**
   * Show the tooltip
   */
  public show() {
    if (this._template === null) {
      this._createTooltipElement();
    }
    this._getParentElement();
    if(this._parentElement){
        this.appendHtmlToDocument();
    }
    return this;
  }

  /***
   * Hide the tooltip
   */
  public hide() {
    if(this._template == null ) {
      return;
    }
    this._parentElement.removeAttribute(`data-${this.name}`);
    this._parentElement.className = Helper.replaceString(this._parentElement.className, 'has-error', '');
    if(this._inputElement) {
        this._inputElement.removeEventListener('keyup', this._keyupListener);
    }
    if(this._template.remove) {
        this._template.remove();
    }else {
      this._template.parentElement.removeChild(this._template);
    }
    this._template = null;
    this._parentElement = null;
    this.messages = null;
  }


}
