import {Title} from "@angular/platform-browser";
import {Injectable} from "@angular/core";
import {isNull, isNullOrUndefined} from "util";

@Injectable()
export class PageTitle {

  private titleAddOn: string = "Test";

  private titlePrefixed: boolean = true;

  private separator: string = "::";

  private addOnShown: boolean = true;

  private titleString: string;

  /**
   *
   * @param title
   */
  public constructor(protected title: Title) {

  }

  public setPageTitleAddOn(value: string) {
    this.titleAddOn = value;
  }


  private createTitle(str: string): string {
    if (this.titleAddOn && this.addOnShown === true) {
      if (this.titlePrefixed === true) {
        str = `${this.titleAddOn} ${this.separator} ${str}`;
      } else {
        str = ` ${str} ${this.separator} ${this.titleAddOn} `;
      }
    }
    return str;
  }

  public showAddOn(show: boolean) {
    this.addOnShown = show;
  }

  public setPrefix(show: boolean) {
    this.titlePrefixed = show;
  }

  /**
   * Set the title of the document
   * @param str
   */
  public setTitle(str: string): void {
    this.titleString = str;
    this.title.setTitle(this.createTitle(str));
  }

  /**
   * Get current page title
   */
  public getTitle(withoutPrefix?: boolean): string {
    if (withoutPrefix === true || isNullOrUndefined(withoutPrefix)) {
      return this.titleString;
    }
    return this.title.getTitle();
  }


}
