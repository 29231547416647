import {Pipe, PipeTransform} from "@angular/core";
import {StatusEnum} from "../enums/status.enum";
@Pipe({
    name: 'status'
})
export class StatusPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        value = parseInt(value, 0 );
        switch (value) {
            case StatusEnum.Unpublished:
                return "mod_commons.unpublished_label";
            case StatusEnum.Published:
                return "mod_commons.published_label";
            case StatusEnum.Suspended:
                return "mod_commons.suspended_label";

            default:

        }
    }

}
