import {Pipe, PipeTransform} from '@angular/core';
import {IsbnTypeEnum} from "../base/contituents/isbn-application/enums/isbn.type.enum";

@Pipe({
  name: 'isbnAppType'
})
export class IsbnAppTypePipe implements PipeTransform {

  transform(value: IsbnTypeEnum): string {
    switch (value) {
      case IsbnTypeEnum.Book:
        return "Book";
      case IsbnTypeEnum.Others:
        return "Others";
      case IsbnTypeEnum.MicroFilm:
        return "Microfilm / Microfiche";
      case IsbnTypeEnum.Map:
        return "Map";
      case IsbnTypeEnum.CassetteCD:
        return "Cassette / CD";
      case IsbnTypeEnum.ComputerSoftware:
        return "Computer software";
    }
    return "Unknown";
  }
}
