import { Component, Injector, EventEmitter, Input, Output, OnInit, ElementRef } from '@angular/core';
import { UploadOutput, UploadInput, humanizeBytes, UploaderOptions, UploadFile } from 'ngx-uploader';
import { UploadFileOptionsInterface } from './upload-file.options.interface';
import { BaseComponent } from "../../system/controllers/BaseComponent";
import { ServerResponse } from "../../system/responses/ServerResponse";
import { isNullOrUndefined } from "util";


@Component({
  selector: 'cmp-upload-file',
  templateUrl: 'upload-file-component.html',
  styleUrls: ['style.scss']
})
export class UploadFileComponent extends BaseComponent implements OnInit {
  /**
   * Label of the input
   */
  @Input('uploadOptions') uploadOptions: UploadFileOptionsInterface;

  /**
   * id for video file
   */

  /**
   * On upload success
   */
  @Output('complete') onComplete: EventEmitter<ServerResponse>;

  @Output('start') onStart: EventEmitter<any>;

  @Output('fileAddedToQueue') onFileAddedToQueue: EventEmitter<any>;

  options: UploaderOptions;
  formData: FormData;
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  uploadProgressValue: number = 50;

  showUploadActivity: boolean = false;
  readyToUpload: boolean = false;
  uniqueId: string = '';
  file: UploadFile;
  uploadDisabled: boolean = false;
  isUploading: boolean = false;
  /**
   * Constructor
   * @param {Injector} injector
   * @param {ElementRef} element
   */
  public constructor(injector: Injector, element: ElementRef) {
    super(injector);
    this.onComplete = new EventEmitter();
    this.onStart = new EventEmitter();
    this.onFileAddedToQueue = new EventEmitter();
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    this.uniqueId = 'input_' + Math.ceil(Math.random() * 10000);

  }

  ngOnInit() {
    if (this.uploadOptions.inputId) {
      this.uniqueId = this.uploadOptions.inputId;
    }

    this.uploadOptions.hidden = isNullOrUndefined(this.uploadOptions.hidden) ? false : this.uploadOptions.hidden;

  }
  getFilesize(): number {
    return this.file.size;
  }
  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'addedToQueue') {
      this.readyToUpload = true;
      this.file = output.file;
      this.onFileAddedToQueue.emit(this.file);
    }
    if (output.type === 'allAddedToQueue' && this.uploadOptions.instantUpload === true) {
      if (this.uploadOptions.onBeforeUpload) {
        this.uploadOptions.onBeforeUpload.subscribe(() => {
          this.startUpload();
        });
      } else {

        this.startUpload();

      }

    }
    if (output.type === 'uploading') {
      this.uploadProgressValue = output.file.progress.data.percentage;
    }
    if (output.type === 'done') {
      this.readyToUpload = false;
      this.showUploadActivity = this.uploadOptions.dismissFeedbackOnComplete === false;

      this.parseResponse(output.file.response, output.file.responseStatus);
      if (this.uploadOptions.disabledOnComplete === true && output.file.responseStatus === 200) {
        this.uploadDisabled = true;
      }
    }
  }

  startUpload(): void {
    this.onStart.emit();
    this.uploadProgressValue = 0;
    this.showUploadActivity = isNullOrUndefined(this.uploadOptions.displayProgressBar) ? true : this.uploadOptions.displayProgressBar;
    this.uploadInput.emit(this.uploadOptions.uploadInputConfig);
  }


  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
    this.showUploadActivity = false;
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  public parseResponse(response: ServerResponse, code: number) {
    if (!response) {
      response = new ServerResponse();
    }
    response.status.statusCode = code;
    this.onComplete.emit(response);
  }


}
