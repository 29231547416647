import {Component, Injector, OnInit} from "@angular/core";
import {BaseComponent} from "../../../system/controllers/BaseComponent";
import {PublicPublisherService} from "../../services/public-publisher.service";

@Component({
  selector: 'public-publishers',
  templateUrl: './public-publishers.component.html'
})
export class PublicPublishersComponent extends BaseComponent implements  OnInit {

  public constructor(injector: Injector) {
    super(injector);
    this.service = injector.get(PublicPublisherService);
  }

  public ngOnInit(): void {
    this.getList();
  }

  public onAdvancedSearchUpdate (event) {

    this.urlParams = {keyword: event.title};

    this.getList();

  }

}
