import {AdvNavbarService} from './AdvNavbarService';
import {NgModule} from '@angular/core';
import {AdvNavbarComponent} from './advNavbar.component';
import {ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
    imports: [CommonModule,
        RouterModule,
        PipesModule,
    ],
    declarations: [AdvNavbarComponent],
    exports: [AdvNavbarComponent],
    providers: []
})
export class AdvNavbarModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AdvNavbarModule,
            providers: [
                AdvNavbarService
            ]
        };
    }
}
