import {Component, Injector, Input} from "@angular/core";
import {AbstractNicoModalComponent} from "../../system/components/modal/AbstractNicoModalComponent";

@Component({
  selector: 'app-authors-lists',
  templateUrl: 'authors-list.component.html'
})
export  class AuthorsListComponent extends AbstractNicoModalComponent {

  public constructor (injector: Injector) {
    super(injector);
  }


}
