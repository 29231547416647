import {NgModule} from '@angular/core';
import {AdminModuleComponent} from './admin-module.component';
import {AdminRouteModule} from './adminmodule.route';
import {AdminToolbarComponent} from './components/toolbar/admin-toolbar.component';
import {AdminCommonModule} from "./admin-common.module";

@NgModule({
    declarations: [
        AdminModuleComponent, AdminToolbarComponent,
    ],
    imports: [
        AdminCommonModule.forRoot(),
        AdminRouteModule,
    ],
    providers: [],
})
export class AdminModule {
}
