import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { User } from "../models/User";
import { NicoSession } from "../../../system/services/NicoSession";
import {environment} from "../../../../environments/environment";
import {isArray} from "util";

@Injectable()
export class AuthMiddleware implements CanActivate {

    protected user: User;

    /**
     * The constructor
     * @param router
     * @param session
     */
    constructor(protected router: Router, protected session: NicoSession) {

    }


    /**
     * Can active method
     * @override
     * @param route
     * @param state
     * @returns {boolean}
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!this.user) {
            this.session.setNamespace(environment.manage_namespace);
            this.user = <User>this.session.getAuthUser();
        }

        if (this.user) {
            return true;
        }
        this.router.navigate(['auth/login']);
        return false;
    }

}
