import {Component, Input, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {NicoModalActionInterface} from "../../system/components/modal/NicoModalActionInterface";


@Component({
    selector: 'nico-image-preview-dialog',
    template: `<img [src]="src" style="width:50%">`
})
export class PreviewImageComponent implements NicoModalActionInterface {

    public data: any;
    public src: string;

    public onNicoModalOkayClick() {

    }

    public onNicoModalCancelClick() {

    }

    public onNicoModalClose() {

    }

    public canDismissNicoModalOnOkay(): boolean {
        return true;
    }

    public canDismissNicoModalOnCancel(): boolean {
        return true;
    }

    public setNicoModalDismisser() {

    }

    setOkayProcessCompleteListener() {

    }

    setCancelProcessCompleteListener() {

    }

    public setNicoModalChildData(data) {
        this.data = data;
        if (data.src instanceof Observable) {
            this.data.src.subscribe((str: string) => {
                this.src = str;
            });
        } else {
            this.src = data.src;
        }
    }

}
