import {Component, Injector, Input} from "@angular/core";
import {BasePublisher} from "../../../shared/base/contituents/publisher/models/BasePublisher";
import {BaseIssuedISBN} from "../../../shared/base/contituents/isbn-application/models/BaseIssuedISBN";
import {AuthorsListComponent} from "../../../shared/authors-list-component/authors-list.component";
import {BaseComponent} from "../../../system/controllers/BaseComponent";

@Component({
  selector: 'app-isbn-card',
  templateUrl: './nico-isbn-card.component.html'
})

export class NicoIsbnCardComponent extends BaseComponent {
  @Input() model: BaseIssuedISBN;
  public constructor(injector: Injector) {
    super(injector);
  }
  public authorListShowMore () {
    this.nicoCtrl.create(AuthorsListComponent, {
      data: {items: this.model.authors},
      htmlTemplateAsIs: true
    }).present();
  }


}
