import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {FormGroup, Validators} from '@angular/forms';
import {User} from '../../models/User';
import {BaseComponent} from "../../../../system/controllers/BaseComponent";
import {NicoModalComponent} from "../../../../system/components/modal/NicoModal";
import {Spinner} from "../../../../system/services/AjaxSpinner";
import {ServerResponse} from "../../../../system/responses/ServerResponse";
import {Toast, ToastEnum} from "../../../../system/services/ToastNotification";

/**
 * This class represents the lazy loaded HomeComponent.
 */
@Component({
    //moduleId: module.id,
    selector: 'sd-auth',
    templateUrl: 'login.component.html',
    styleUrls: [],

})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {
    /**
     * The submitForm form
     * @type FormGroup
     */
    public loginForm: FormGroup;
    /**
     * Is submitForm submit busy
     * @type {boolean}
     */
    public loginSubmitBusy: boolean = false;

    public comp: NicoModalComponent;

    public response: any;

    protected toastMessage: Toast;

    /**
     * The constructor
     * @param {AuthService} authService
     * @param {Injector} injector
     */
    public constructor(protected authService: AuthService,
        injector: Injector) {
        super(injector);
    }

    protected showEmailConfirmedToastIfNecessary () {
        const param = this.activatedRoute.snapshot.queryParams;
        if (param.hasOwnProperty('email-confirmation-error')) {
          // tslint:disable-next-line:triple-equals
            if (param['email-confirmation-error'] == 0) {
                this.showSuccessToast('Your email has been verified. Please login to continue.');
            }
            this.router.navigate(['/auth/login']);

        }
    }

    /**
     * Ng On init implemented method
     */
    public ngOnInit() {
        super.ngOnInit();
        this.setPageTitle('mod_login.module_title');
        this.initLoginForm();
        this.showEmailConfirmedToastIfNecessary();
    }

    /**
     * On onDestroy life cycle message
     */
    public ngOnDestroy() {
        if (this.toastMessage) {
            this.toastMessage.hide();
        }
    }

    /**
     * Init the submitForm form
     */
    private initLoginForm() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.compose([Validators.required])]],
            password: ['', Validators.required]
        });
    }

    /**
     * Login
     */
    public login(event: Event) {
        event.preventDefault();
        if (this.loginSubmitBusy) {
            return;
        }
        this.loginSubmitBusy = true;
        const spinner: Spinner = this.ajaxSpinner.showSpinner();
        this.authService.login(this.loginForm.value)
            .subscribe(
                (d: any) => {
                    this.loginSubmitBusy = false;
                    spinner.hide();
                    // once logged in get authenticated user
                    this.http.setAuthUser(d);
                    const pageAttempted = this.session.getPageAttempt();
                    if (pageAttempted) {
                        this.router.navigate([pageAttempted]).catch(() => {
                            this.router.navigate(['/manage']);
                        });
                        this.session.clearPageAttempt();
                    } else {
                        this.router.navigate(['/manage']);
                    }

                },
                (d: ServerResponse) => {
                    this.toastMessage = new Toast(d.status.message, null, ToastEnum.Danger, false, null, 'centerCenter');
                    this.toastMessage.show('loginToast');
                    this.loginSubmitBusy = false;
                    spinner.hide();
                    if (d.status.statusCode === 417) {
                        this.validationMessenger.attach('loginForm', d.body);
                    } else {

                    }
                }
            );
    }

}
