import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './components/login/login.component';
import {AuthRoutingComponent} from './auth-routing.module';
import {AuthService} from './services/auth.service';
import {AuthMiddleware} from './middlewares/AuthMiddleware';
import {GuestMiddleware} from './middlewares/GuestMiddleware';
import {SharedModule} from '../shared.module';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {LogoutComponent} from './components/logout/logout.component';
import {AuthComponent} from './auth.component';
import {PasswordUpdateComponent} from "./components/password-update/password-update.component";
import {RegisterComponent} from "./components/register/register.component";

@NgModule({
    imports: [CommonModule, AuthRoutingComponent, SharedModule,
    ],
    declarations: [LoginComponent, AuthComponent, ForgotPasswordComponent, ResetPasswordComponent, LogoutComponent,
        PasswordUpdateComponent, RegisterComponent
    ],
    exports: [LoginComponent],
    providers: [AuthService, AuthMiddleware]
})
export class AuthModule {
    static forRoot() {
        return {
            ngModule: AuthModule,
            providers: [AuthMiddleware, GuestMiddleware]
        };
    }
}
