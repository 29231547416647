import {Component, Injector, OnInit} from '@angular/core';
import {NicoModalActionInterface} from '../../system/components/modal/NicoModalActionInterface';
import {BaseComponent} from '../../system/controllers/BaseComponent';
import {FormGroup, Validators} from '@angular/forms';
import {GeneralUserCreateInterface} from './general-user-create.interface';
import {ServerResponse} from '../../system/responses/ServerResponse';

@Component({
  selector: 'new-user-component',
  templateUrl: './new-user.component.html',
})

export class NewUserComponent extends BaseComponent implements NicoModalActionInterface, OnInit {

  protected dismisser: Function;

  public formId = 'add-user-form';

  public formGroup: FormGroup;

  public modalTitle = 'mod_manage.mod_users.add.page_title';

  protected userService: GeneralUserCreateInterface;

  protected okayCompleteListener: Function;

  public constructor(injector: Injector) {
    super(injector);
  }

  /**
   * On init hook
   */
  public ngOnInit() {
    this.setPageTitle('mod_manage.mod_users.add.page_title');
    this.formGroup = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required]
    });
  }

  /**
   * Save form
   */
  public save(): any {
    const spinner = this.ajaxSpinner.showSpinner();
    this.userService.save(this.formGroup.value).subscribe((resp: ServerResponse) => {
      spinner.hide();
      this.dismisser();
      if (this.okayCompleteListener) {
        this.okayCompleteListener();
      }

      if (resp.body.new_user) {
        this.showSuccessToast('mod_manage.mod_users.user_added_success_message');
        this.showSuccessToast('mod_manage.mod_users.user_email_sent_message');
      } else {
        this.showSuccessToast('mod_manage.mod_users.existing_user_added_message');
        this.showInfoToast('mod_manage.mod_users.existing_user_info_message');
      }
    }, (response: ServerResponse) => {
      spinner.hide();
      if (response.status.statusCode === 417) {
        this.validationMessenger.attach(this.formId, response.body);
      } else {
        this.dismisser();
      }
    });
  }

  public cancel() {
    this.dismisser();
  }

  /**
   * @Override
   */
  public canDismissNicoModalOnCancel() {

  }

  /**
   * @Override
   */
  public canDismissNicoModalOnOkay() {

  }

  /**
   * @Override
   */
  public onNicoModalCancelClick() {

  }

  /**
   * @Override
   */
  public onNicoModalClose() {

  }

  /**
   * @Override
   */
  public onNicoModalOkayClick() {

  }

  /**
   * @Override
   */
  public setCancelProcessCompleteListener() {

  }

  /**
   * The data object expects atleast userService Method to be present
   * @Override
   */
  public setNicoModalChildData(data) {
    this.userService = data.service;
  }

  /**
   * @Override
   */
  public setNicoModalDismisser(fn: Function) {
    this.dismisser = fn;
  }

  /**
   * @Override
   */
  public setOkayProcessCompleteListener(fn: Function) {
    this.okayCompleteListener = fn;
  }


}
