import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {StatusEnum} from '../../enums/status.enum';
import {StatusOptions} from '../../enums/StatusOptions';
import {StatusPipe} from '../../pipes/status.pipe';
import {Collection} from '../../utilities/Collection';

@Component({
  selector: 'nico-status',
  template: '<span class="badge badge-pill " [class.badge-success]="value===statusOptions.STATUS_PUBLISHED" [class.badge-warning]="value===statusOptions.STATUS_UNPUBLISHED" [class.badge-danger]="value===statusOptions.STATUS_SUSPENDED">{{ label | translate }}</span>',
})

export class NicoStatusComponent implements OnInit, OnChanges {

  @Input() value: StatusEnum;

  @Input() statusLabelConfig: Collection<StatusLabelConfig>;

  public statusOptions = new StatusOptions;

  public label: string;

  /**
   * on init lifecycle
   */
  ngOnInit() {
  }

  ngOnChanges() {
    this.setStatus();
  }

  /**
   * translate status values
   */
  public setStatus() {
    const statusPipe = new StatusPipe();
    this.label = statusPipe.transform(this.value);
    if (this.statusLabelConfig) {
      this.statusLabelConfig.forEach(config => {
        if (config.status === this.value) {
          this.label = config.value;
        }
      });
    }
  }
}

export interface StatusLabelConfig {
  status: StatusEnum;
  value: string;
}
