import {EnvironmentInterface} from "../app/system/datamodels/EnvironmentInterface";

export const environment: EnvironmentInterface = {
    production: true,
    app_name: 'ISBN',
    login_page: '/auth/login',
    password_update_page: '/auth/password/update',
    api: '/api',
    base: '',

    public_namespace: "public",
    manage_namespace: "manage",
    country_isbn_identifier: "978-9937",
    recaptcha_site_key: '6Lf4li4pAAAAAEeZVduwCSvHnMlenytcLj3aoXaV',
    recaptcha_site_secret: '6Lf4li4pAAAAAI_vDgxwmLmZv5Bm-lC1Hu-e5yIG',
    display_feature: {
      barcode: true, multiple_author: true
    }
};
