import {
  Component, OnDestroy, forwardRef, Input, Output, OnChanges, EventEmitter,
  ElementRef, OnInit, TemplateRef,
} from '@angular/core';
import {FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS} from '@angular/forms';
import {NicoHttp} from '../../requests/NicoHttp';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/internal/operators';
import {ServerResponse} from '../../responses/ServerResponse';
import {Subject} from 'rxjs';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'advanced-select',
  template: `
    <div class="dropdown bst-adv-dropdown" [class.open]="dropdownExpanded">
      <div class="form-control" (click)="toggleDropdown()">
        <span *ngIf="!selectedItem">
            <ng-container *ngIf="config.emptySelectionTemplate">
                <ng-container *ngTemplateOutlet="config.emptySelectionTemplate"></ng-container>
            </ng-container>
            <ng-container *ngIf="!config.emptySelectionTemplate">{{config.selectInfoLabel}}</ng-container>
        </span>
        <span *ngIf="selectedItem">
            <ng-container *ngIf="config.itemTemplate">
                    <ng-container *ngTemplateOutlet="config.itemTemplate;context:{item:selectedItem}"></ng-container>
            </ng-container>
            
        </span>
        <div class="caret-holder"><i class="fa fa-caret-down"></i></div>
      </div>
      <ul class="dropdown-menu">
        <li class="search-type input-group-sm"><input type="text" [ngModel]="text"
                                                      (ngModelChange)="onModelChange($event)"
                                                      class="form-control select-hint" [id]='searchInputId'
                                                      [placeholder]="config.searchPlaceholderText"></li>
        <li *ngIf="busyFetchingItems">
          <div class="text-center py-3"><span class="fa fa-spinner fa-spin fa-3x fa-fw"></span></div>
        </li>
        <li *ngIf="!busyFetchingItems && items.length>0" class="dropdown-menu-selections">
          <ul class="selection-item-group">
            <ng-container *ngIf="!config.disableEmptyField">
              <li *ngIf="!config.emptySelectionTemplate" (click)="selectItem(null)"
                  class="hand-cursor">{{config.emptySelectionText}}</li>
              <li *ngIf="config.emptySelectionTemplate" (click)="selectItem(null)" class="hand-cursor">
                <ng-container *ngTemplateOutlet="config.emptySelectionTemplate"></ng-container>
              </li>
            </ng-container>
            <li *ngFor="let item of items;let i=index" (click)="selectItem(i)" class="hand-cursor"
                [class.active]="isEqual(item,selectedItem)">
              <ng-container *ngTemplateOutlet="config.itemTemplate;context:{item:item}"></ng-container>
            </li>
          </ul>
        </li>
        <li *ngIf="!busyFetchingItems && items.length==0" class="no-result">{{config.emptyResultText}}</li>
        <li *ngIf="config.actionButtonEnabled" class="action-icons">
          <button class="btn btn-primary"
                  (click)="onActionButtonClick($event)">{{config.actionButtonLabel | translate}}</button>
        </li>
      </ul>
    </div>
  `,
  styleUrls: ['advanced-select.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AdvancedSelectComponent), multi: true},
  ],
})
export class AdvancedSelectComponent implements ControlValueAccessor, OnChanges, OnDestroy, OnInit {

  public dropdownExpanded: boolean = false;

  public selectedItem: any;

  public busyFetchingItems: boolean = false;

  public searchInputId: string;

  public items: Array<any> = [];

  private documentClickListener: (event: any) => void;


  /**
   * The input
   *  {string}
   */

  onTouched: () => void;
  /**
   * The text to be displayed in the input
   *  {string}
   */
  public text: string = '';

  public searchTextChanged: Subject<string> = new Subject();


  /**
   * Input value
   *  {number}
   *
   */

  @Input('disabled') isDisabled: boolean = false;

  @Input('value') _value = {};

  @Input('readonly') _readonly: boolean | string | number = false;
  /**
   * The on select event
   */
  @Output('onSelect') onSelect: EventEmitter<any>;
  /**
   * On no result
   *  EventEmitter
   */
  @Output('onNoResult') onNoResult: EventEmitter<any>;
  /**
   * On Loading
   *  EventEmitter
   */
  @Output('onLoading') onLoading: EventEmitter<any>;

  @Output('actionButtonClick') actionButtonClick: EventEmitter<any>;

  /**
   * Static options
   */
  @Input('options') options: any;
  /**
   * Enable the remote option
   *  {boolean}
   */
  @Input('remote') remote: boolean = true;
  /**
   * the url for server call
   *  string
   */
  @Input('url') public url: string = '';

  @Input() config: AdvancedSelectConfigInterface = {};

  public selectedTemplate: TemplateRef<any>;

  /**
   *  function
   */
  propagateChange: any = () => {
  };
  /**
   *  function
   */
  validateFn: any = () => {
  };


  constructor(protected http: NicoHttp, protected elemRef: ElementRef) {

    this.searchInputId = `adv-select${parseInt((Math.random() * 10000000).toString(), 0)}`;
    this.onSelect = new EventEmitter();
    this.onNoResult = new EventEmitter();
    this.onLoading = new EventEmitter();
    this.actionButtonClick = new EventEmitter();
    this.searchTextChanged.pipe(debounceTime(400), distinctUntilChanged()).subscribe((text: string) => {
      this.text = text;
      this.getItems();
    });
  }

  protected initConfig() {
    this.config.emptyResultText = isNullOrUndefined(this.config.emptyResultText) ? 'No results' : this.config.emptyResultText;
    this.config.searchPlaceholderText = isNullOrUndefined(this.config.searchPlaceholderText) ? 'Search' : this.config.searchPlaceholderText;
    this.config.emptySelectionText = isNullOrUndefined(this.config.emptySelectionText) ? 'Deselect' : this.config.emptySelectionText;
    this.config.selectInfoLabel = isNullOrUndefined(this.config.selectInfoLabel) ? 'Select one' : this.config.selectInfoLabel;
    this.config.actionButtonLabel = isNullOrUndefined(this.config.selectInfoLabel) ? 'Add' : this.config.actionButtonLabel;
    this.config.disableEmptyField = isNullOrUndefined(this.config.disableEmptyField) ? false : this.config.disableEmptyField;
  }

  ngOnInit() {
    this.documentClickListener = (event: any) => {
      if (!this.elemRef.nativeElement.contains(event.target)) {
        this.dropdownExpanded = false;
      }
    };
    document.addEventListener('click', this.documentClickListener);
    this.initConfig();
    this.selectedTemplate = this.config.itemTemplate;
    if (!this._value) {
      this.selectedTemplate = this.config.emptySelectionTemplate;
    }
    this.selectedItem = this.value = this._value;
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.documentClickListener);
  }


  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.propagateChange(val);
  }

  ngOnChanges(value) {

  }

  writeValue(value: any) {
    this.text = '';
    //if (value) {
    this.value = value;
    this.selectedItem = value;
    //}
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  getItems(): void {
    if (this.remote === true) {
      if (this._readonly === true || this._readonly === 1 || this._readonly === 'true') {
        return;
      }
      this.busyFetchingItems = true;
      this.http.get(`${this.url}?keyword=${this.text}`).pipe(map((res: ServerResponse) => {
        return res.body.data;
      })).subscribe((d: any) => {
        this.busyFetchingItems = false;
        this.items = d.all();
      }, (d: any) => {
        this.busyFetchingItems = false;
      });
    } else {
      this.items = this.options;
    }

  }

  typeaheadNoResults($event: any, i: number) {
    this.value = null;
    this.onNoResult.emit(this.value);
  }

  typeaheadLoading($event: any, i: number) {
    this.onLoading.emit(null);
  }

  toggleDropdown() {
    if (this._readonly === true || this._readonly === 1 || this._readonly === 'true' || this.isDisabled) {
      return;
    }
    this.dropdownExpanded = !this.dropdownExpanded;
    if (this.dropdownExpanded) {
      setTimeout(() => {
        document.getElementById(this.searchInputId).focus();
      }, 200);
      this.getItems();
    }
  }

  selectItem(index: number) {
    if (this._readonly === true || this._readonly === 1 || this._readonly === 'true') {
      return;
    }
    if (index === null) {
      this.clearItem();
      return;
    }
    if (this.isEqual(this.items[index], this.value)) {
      this.dropdownExpanded = false;
      return;
    }
    this.selectedItem = this.items[index];
    this.value = this.selectedItem;
    this.selectedTemplate = this.config.itemTemplate;
    this.dropdownExpanded = false;
    this.onSelect.emit(this.value);
  }

  clearItem() {
    this.selectedItem = null;
    this.value = this.selectedItem;
    this.dropdownExpanded = false;

    this.selectedTemplate = this.config.emptySelectionTemplate;
    this.onSelect.emit(this.value);
  }

  onModelChange(value: any) {
    this.searchTextChanged.next(value);
  }

  onBlur() {
    this.dropdownExpanded = false;
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  isEqual(val1: any, val2: any) {
    if (this.config.equalityCheckProperty && val1 && val2) {
      return val1[this.config.equalityCheckProperty] === val2[this.config.equalityCheckProperty];
    }

    return val1 === val2;
  }

  onActionButtonClick($event) {
    this.actionButtonClick.emit($event);
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }

}

export interface AdvancedSelectConfigInterface {
  itemTemplate?: TemplateRef<any>;
  selectionTemplate?: TemplateRef<any>;
  displayProperty?: string;
  emptyResultText?: string;
  selectInfoLabel?: string;
  emptySelectionText?: string;
  emptySelectionTemplate?: TemplateRef<any>;
  searchPlaceholderText?: string;
  equalityCheckProperty?: string;
  actionButtonLabel?: string;
  actionButtonEnabled?: boolean;
  disableEmptyField?: boolean;
}
