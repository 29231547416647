/**
 * The helper utilities class
 */
import {PlatformLocation} from "@angular/common";
import {forEach} from "@angular/router/src/utils/collection";
import { isString } from "util";

export class Helper {

  static readonly DATE_FMT = 'MM.dd.yyyy';

  /**
   * Get the value from a given array
   * @param arr
   * @param key
   */
  static arrayGet(arr: Array<any>, key: string | number): any {
    if (!arr) {
      throw Error('Invalid array/object');
    }
    if (arr[<any>key]) {
      return arr[<any>key];
    } else {
      return null;
    }
  }

  static replaceString(subject: string, replace?: string, replaceWith?: string) {
    if (!replace) {
      replace = "";
    }
    if (!replaceWith) {
      replaceWith = '';
    }
    const regex = new RegExp('\\b' + replace + '\\b');
    subject = subject.replace(regex, replaceWith);
    return subject;
  }

  /**
   * Append text to a string, if the string doesn't exists yet
   * @param text
   * @param string
   */
  static appendTextIfDoesntExist(text: string, string: string): string {
    text = Helper.replaceString(text, string);
    text = text.trim();
    text += ' ' + string;
    return text;
  }

    /**
     * Assumes an original images has thumbnail with std [imagename]_[suffix].[extension]
     * @param url
     * @param suffix
     */
  static getThumbnailUrl(url: string, suffix?: string) {
    if (!suffix) {
      suffix = '_thumb';
    }
    let str: any = url.split('.');
    const ext = str.pop();
    str = str.join('.');
    str += suffix + '.' + ext;
    return str;
  }

  static getRandomString(chars?: number){
    if(isNaN(chars) || !chars) {
      chars = 10;
    }
    const charSets = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let ret = "";
    for(let i=0;i<chars;i++) {
      ret +=charSets.charAt((Math.random() * 100 ) % charSets.length);
    }

    return ret;

  }

    /**
     * Get subdomain info from the platform location
     * @param location
     * @param defaultValue
     */
  static getSubdomain (location: PlatformLocation, defaultValue?: string) {
    const host = (<any>location).location.hostname;
    const splits = host.split('.');
    let domain: any = null;
    if(splits.length > 2) {
        domain = splits.shift();
    }
    if(!domain) {
      domain = defaultValue;
    }

    return domain;

  }

  static urlIsFriendly (urlToTest: string, urlCollection: string[]): boolean {
    let isFriendly = false;
      // remove the http(s):// from the url
      urlToTest = urlToTest.replace(/http(s)?:\/\//, "");

      //remove all the folder locations

      urlToTest = (urlToTest.split("/"))[0];
    urlCollection.forEach(url => {
      if (isString(url) && url.indexOf(urlToTest) >= 0) {
        isFriendly = true;
      }
    });

      return isFriendly;
  }

  static getISBNMod13CheckDigit (countryFixedPrefix: any, publisherPrefix: any, isbnNumber: any) {
    const combinedIsbnNumber = ( '' + countryFixedPrefix) + ('' + publisherPrefix) + ( '' + isbnNumber);

    if(combinedIsbnNumber.length <= 10){
      return this.getISBNMod10CheckDigit(countryFixedPrefix, publisherPrefix, isbnNumber);

    }else{
      return this.getISBNMod13CheckDigitActual(countryFixedPrefix, publisherPrefix, isbnNumber);
    }
  }

  static getISBNMod13CheckDigitActual (countryFixedPrefix: any, publisherPrefix: any, isbnNumber: any) {
    countryFixedPrefix = (countryFixedPrefix !== null && countryFixedPrefix !== undefined) ? countryFixedPrefix : '000'
    //countryFixedPrefix = countryFixedPrefix.replace('-', '');
    //    countryFixedPrefix = countryFixedPrefix.replace(/^\s+|\s+$/gm,'');
    countryFixedPrefix = '' + countryFixedPrefix.trim();
    //    countryFixedPrefix = countryFixedPrefix.replace(/^\s+|\s+$/gm,'');
    publisherPrefix = '' + publisherPrefix.trim();
    isbnNumber = '' + isbnNumber.trim();

    // if (isNaN(countryFixedPrefix)) {
    // throw Error('Invalid EAN - Group combination given. All the digits must be numeric');
      //   }
    if (isNaN(publisherPrefix)) {
      throw Error('Invalid publisher prefix. All the digits must be numeric');
    }

    if (isNaN(isbnNumber)) {
      throw Error('Invalid ISBN title value. All the digits must be numeric');
    }

    let remainingDigits = 12 - (countryFixedPrefix.length + publisherPrefix.length + isbnNumber.length);
 
    if (remainingDigits < 0) {
    remainingDigits = 0;
    console.error('Invalid isbn number');
    //  return "";
      //throw Error('Invalid isbn number');
    }

    for (let i = 0; i < remainingDigits; i++ ) {
      isbnNumber = '0' + isbnNumber;
    }
const combinedIsbnNumber = ( '' + countryFixedPrefix) + ('' + publisherPrefix) + ( '' + isbnNumber);
//console.log('combinedIsbnNumber ' + combinedIsbnNumber);
let sum = 0;
let j =0;
for (let i = 0; i < combinedIsbnNumber.length; i++) {
// console.log('value of i = ' + i);
if(isNaN(parseInt(combinedIsbnNumber[i],10))) {
//  console.log('combinedIsbnNumber is not number' + combinedIsbnNumber[i]);
 
         continue;
	 }
	 if (j % 2 === 0) {
	 // console.log(combinedIsbnNumber[i] + ' * ' + '1 = ' + parseInt(combinedIsbnNumber[i],10)); 
	 sum += parseInt(combinedIsbnNumber[i], 10);
	 } else {
	 //	 console.log(combinedIsbnNumber[i] + ' * ' + '3 = ' + parseInt(combinedIsbnNumber[i],10) * 3);
      sum += parseInt(combinedIsbnNumber[i], 10) * 3;
      }
      if(!isNaN(parseInt(combinedIsbnNumber[i],10))) {
       j++;
      }
      }
      const res =  10  - ( sum % 10);
      //    console.log('res = ' + res);
    if (res === 10) {
      return 0;
    }
    return res;
  }

  static getISBNMod10CheckDigit (countryFixedPrefix: any, publisherPrefix: any, isbnNumber: any) {
    countryFixedPrefix = (countryFixedPrefix !== null && countryFixedPrefix !== undefined) ? countryFixedPrefix : '000'
    countryFixedPrefix = countryFixedPrefix.replace('-', '');
    countryFixedPrefix = '' + countryFixedPrefix;
    publisherPrefix = '' + publisherPrefix;
    isbnNumber = '' + isbnNumber;
    if (isNaN(countryFixedPrefix)) {
      throw Error('Invalid EAN - Group combination given. All the digits must be numeric');
    }
    if (isNaN(publisherPrefix)) {
      throw Error('Invalid publisher prefix. All the digits must be numeric');
    }

    if (isNaN(isbnNumber)) {
      throw Error('Invalid ISBN title value. All the digits must be numeric');
    }
    isbnNumber = parseInt(isbnNumber);

    isbnNumber = isbnNumber+'';

    const remainingDigits = 10 - (countryFixedPrefix.length + publisherPrefix.length + isbnNumber.length);
    if (remainingDigits < 0) {
      console.error('Invalid isbn number');
      return "";
      //throw Error('Invalid isbn number');
    }
    const combinedIsbnNumber = ( '' + countryFixedPrefix) + ('' + publisherPrefix) + ( '' + isbnNumber);

    let weight = 10;
    let sum = 0;
    let digit: any;
    let check: any;
    for (let i = 0; i < 9; i++) {
        digit = parseInt(combinedIsbnNumber[i]);
        sum += weight*digit;
        weight--;
    }
  
    check = (11 - (sum % 11)) % 11
    if (check == 10) {
        check = 'X';
    }
    return check;
  }


  static setStorage(key:string, value: any)
  {
    return localStorage.setItem(key,value);
  }

  static getStorage(key:string)
  {
    return localStorage.getItem(key);
  }

  static removeStorage(key:any)
  {
    return localStorage.removeItem(key);
  }

  static clearStorage(){
    return localStorage.clear();
  }
  


}
