import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {AuthModule} from './shared/auth/auth.module';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppService} from './app.service';
import {AdminModule} from './admin-module/admin.module';
import {NicoHttp} from './system/requests/NicoHttp';
import {AjaxSpinner} from './system/services/AjaxSpinner';
import {ToastNotification} from './system/services/ToastNotification';
import {NicoSession} from './system/services/NicoSession';
import {PageTitle} from './system/services/PageTitle';
import {SmartValidationMessenger} from './system/services/SmartValidationMessenger';
import {ViewController} from './system/controllers/ViewController';
import {NicoModalController} from './system/components/modal/NicoModalController';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PublicModule} from "./public-module/public.module";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import {environment} from "../environments/environment";

//For translation

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory
        , deps: [HttpClient]
      }
    }),
    SharedModule.forRoot(),
    AuthModule,
    AdminModule,
    PublicModule,
    BrowserModule,
    BrowserAnimationsModule,
  ],
  providers: [
    /** All the singleton services should be listed here **/
    NicoHttp,
    AjaxSpinner,
    ToastNotification,
    NicoSession,
    PageTitle,
    SmartValidationMessenger,
    ViewController,
    NicoModalController,
    AppService,
    {provide: RECAPTCHA_V3_SITE_KEY, useValue:  environment.recaptcha_site_key}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


//exentionmodule.forRoot()
