import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from "../../../system/controllers/BaseComponent";
import {RouterOutlet} from '@angular/router';
import {slideInAnimation} from '../../../system/animations';
import {ConfirmDialogComponent} from '../../../shared/confirm-dialog/confirm-dialog.component';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [slideInAnimation]
})

export class HomeComponent extends  BaseComponent implements OnInit {
	private ff: boolean = false;
  	private message: string = 'त्रिविवि केन्द्रीय पुस्तकालयमा घटस्थापनादेखि पूर्णिमासम्म दशैं विदा भएको कारण अनलाईन मार्फत प्रदान गरिने ISBN सेवा सो अवधीका लागि वन्द रहने वारे समस्त महानुभावलाई जानकारी गराउँदछौ। सो अवधीमा बुझाएका Online ISBN Application फारामहरू लाई दशैं विदा पछि ISBN प्रदान गरिनेछ। धन्यवाद।';
  	private modalTitle: string = 'घटस्थापनादेखि पूर्णिमासम्म अनलाईन मार्फत वितरण हुने ISBN सेवा वन्द रहने सम्बन्धी जानकारी';
  	constructor(protected  injector: Injector, private meta: Meta) {
    		super(injector);
  	}

	ngOnInit() {
		this.setPageTitle('Home');
      		//      this.getMessage();
		
		this.meta.addTags([
        		{ name: 'title', content:  'TUCL online ISBN Application Nepal'},
        		{ name: 'description', content: 'TUCL Online ISBN Application, collection of all books, author, publication,  Tribhuvan University Central Library (TUCL) which is the authorized body for issuing ISBN in Nepal '},
      		]);

  	}
  	
	public getMessage(){
    		setTimeout (() => {
      			this.nicoCtrl.create(ConfirmDialogComponent, {
        			modalTitle: this.modalTitle,
		        	showCrossButton: true,       
		        	showModalFooter: false,
		        	htmlTemplateAsIs: false,
		        	// modalClass: 'confirm-dialog',
		        	data: {message: this.message}
	      		}).present();
   		},2000);
  	}

  /**
   *  the Method that is called after route param is initialized
   *  If you need to grab something from route and then initialize component. Define them here.
   *  Note, this method will be called every-time if url is changed. So use it wisely.
   */
	public onRouteParamInitialized() {

  	}

  	prepareRoute(outlet: RouterOutlet) {
    		return outlet && outlet.activatedRouteData;
  	}
}
