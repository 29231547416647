import { Component, OnInit, Injector } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { BaseComponent } from "../../../../system/controllers/BaseComponent";
import { ServerResponse } from "../../../../system/responses/ServerResponse";

/**
 * This class represents the lazy loaded HomeComponent.
 */
@Component({
    //moduleId: module.id,
    selector: 'user-logout',
    template: '',
    styleUrls: [],

})
export class LogoutComponent extends BaseComponent implements OnInit {

    /**
     * The constructor
     * @param {AuthService} authService
     * @param {Injector} injector
     */
    public constructor(protected authService: AuthService, injector: Injector) {
        super(injector);
    }

    /**
     * Ng On init implemented method
     */
    public ngOnInit() {
        this.authService.logout().subscribe((d: ServerResponse) => {
            this.http.getSession().clearAuth();
            this.session.clearPageAttempt();
            this.router.navigate(['auth/login']);

        }, d => {
            this.showErrorToast('mod_logout.logout_fail_message');
        });
    }
}
