import { Component, Injector, ElementRef } from '@angular/core';
import { UploadFileComponent } from '../upload-file-component/upload-file.component';
import { UploaderOptions, UploadOutput } from 'ngx-uploader';
import { ServerResponse } from 'src/app/system/responses/ServerResponse';


@Component({
  selector: 'cmp-upload',
  templateUrl: 'upload-multiple.component.html',
  styleUrls: ['style.scss']
})
export class UploadMultipleComponent extends UploadFileComponent {
  options: UploaderOptions = {
    concurrency: 1
  }
  files: File[] = [];
  finishUpload = false;
  public constructor(injector: Injector, element: ElementRef) {
    super(injector, element);
  }

  onUploadOutput(output: UploadOutput): void {



    if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {


      this.files.push(output.file.nativeFile);


    }

    if (output.type === 'allAddedToQueue' && this.uploadOptions.instantUpload === true) {
      this.startUpload();

    }
  }

  startUpload(): void {
    this.ajaxSpinner.showSpinner();
    const formData: FormData = new FormData();
    for (let i = 0; i < this.files.length; i++) {
      const file: File = this.files[i];
      formData.append('file[]', file, file.name);
    }

    this.uploadOptions.uploadInputConfig = {
      ...this.uploadOptions.uploadInputConfig,
      data: <any>formData
    }

    this.http.post(this.uploadOptions.uploadInputConfig.url, formData).subscribe((response: ServerResponse) => {
      this.ajaxSpinner.hideAll();
      this.finishUpload = true;
      this.onComplete.emit(response);
    });

  }


}
