import {
    Component, ViewChild, Output, EventEmitter, ComponentFactoryResolver,
    ViewContainerRef, ComponentRef, Input
} from '@angular/core';
import {NicoModalConfig} from './NicoModalConfig';
import * as _ from 'lodash';
import {NicoModalActionInterface} from './NicoModalActionInterface';
import {Type} from '@angular/core/src/type';
import {from, Observable} from 'rxjs';

@Component({
  selector: 'app-nico-modal',
  template: `
    <div [class.in]="_in" [class.show]="_in" [style.display]="visible===true?'block':'none'"
         [class]="config.modalClass">
      <div class="modal-dialog modal-dialog-centered" id="modalDialog">
        <div class="modal-content" [class.has-ft-img]="!config.showModalHeader && config.coverImage">
          <section class="modal-header" *ngIf="config.showModalHeader">
            <h4 *ngIf="!config.modalTitleTemplate" class="modal-title">
              {{config.modalTitle | translate}}
            </h4>
            <button *ngIf="!config.modalTitleTemplate && config.showCrossButton" type="button" class="close"
                    (click)="destroy()" aria-label="Close"><span aria-hidden="true">&times;</span></button>

            <!--<template
              *ngIf="config.modalTitleTemplate"
              [templateWrapper]="config.modalTitleTemplate">
            </template>-->

          </section>
          <section class="modal-body text-center">
            <button *ngIf="!config.showModalHeader && !config.modalTitleTemplate && config.showCrossButton"
                    type="button" class="close" (click)="destroy()" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
            <ng-template #modalbody></ng-template>
          </section>
          <section *ngIf="config.showModalFooter">
            <section class="modal-footer" *ngIf="config.modalFooterTemplate">
              <!--<template  [templateWrapper]="config.modalFooterTemplate"></template>-->
            </section>
            <section class="modal-footer">
              <button (click)="cancelClicked()" class="btn btn-default btn-md" [autofocus]="autoFocusButton=='cancel'"
                      *ngIf="config.showCancelButton">{{config.cancelLabel}}</button>
              <button (click)="okayClicked()" class="btn btn-primary btn-md"
                      *ngIf="config.showOkayButton"  [autofocus]="autoFocusButton=='okay'">{{config.okayLabel}}</button>
            </section>
          </section>
        </div>
      </div>
    </div>
  `
})
export class NicoModalComponent {

  /**
   * The modal body reference
   */
  @ViewChild('modalbody', {read: ViewContainerRef}) modalbody: ViewContainerRef;

  /**
   * On okay click
   */
  @Output() okayClick: EventEmitter<any>;
  /**
   * On cancel click
   */
  @Output() cancelClick: EventEmitter<any>;

  @Output() destroyed: EventEmitter<any>;

  @Input () autoFocusButton: 'okay' | 'cancel' = "cancel";

  /**
   * The configuration
   */
  public config: NicoModalConfig;

  public visible = false;

  public _in = false;

  public vcr: ComponentRef<any>;

  public modalChildComponent: NicoModalActionInterface;

  public confirmVisible = false;

  /**
   * The constructor
   */
  constructor(protected contentResolver: ComponentFactoryResolver) {
    this.cancelClick = new EventEmitter();
    this.okayClick = new EventEmitter();
    this.destroyed = new EventEmitter();
    this.config = this.getConfig();
  }

  protected getConfig(): NicoModalConfig {
    return {
      showModalHeader: true,
      htmlTemplateAsIs: false,
      showModalFooter: true,
      coverImage: false,
      showCrossButton: true,
      showCancelButton: true,
      showOkayButton: true,
      okayLabel: 'OK',
      cancelLabel: 'Cancel',
      modalTitle: 'Information',
      modalTitleTemplate: null,
      modalFooterTemplate: null,
      modalClass: 'app-nico-modal modal fade',
    };
  }

  protected setObjectValue(obj: any, property: string, value: any) {
    if (value instanceof Observable) {
      value.subscribe((d: string) => {
        obj[property] = d;
      });
    } else {
      obj[property] = value;
    }
  }

  /**
   * Set config
   * @param options
   */
  public setConfig(options: NicoModalConfig) {
    const config: NicoModalConfig = this.getConfig();
    if (options) {
      this.setObjectValue(config, 'modalTitle', options.modalTitle ? options.modalTitle : config.modalTitle);
      this.setObjectValue(config, 'cancelLabel', options.cancelLabel ? options.cancelLabel : config.cancelLabel);
      this.setObjectValue(config, 'okayLabel', options.okayLabel ? options.okayLabel : config.okayLabel);

      config.showModalHeader = options.showModalHeader !== undefined ? options.showModalHeader : config.showModalHeader;
      config.htmlTemplateAsIs = options.htmlTemplateAsIs !== undefined ? options.htmlTemplateAsIs : config.htmlTemplateAsIs;
      config.showModalFooter = options.showModalFooter !== undefined ? options.showModalFooter : config.showModalFooter;
      config.coverImage = options.coverImage !== undefined ? options.coverImage : config.coverImage;
      config.showCrossButton = options.showCrossButton !== undefined ? options.showCrossButton : config.showCrossButton;
      config.showCancelButton = options.showCancelButton !== undefined ? options.showCancelButton : config.showCancelButton;
      config.showOkayButton = options.showOkayButton !== undefined ? options.showOkayButton : config.showOkayButton;

      config.modalTitleTemplate = options.modalTitleTemplate ? options.modalTitleTemplate : config.modalTitleTemplate;
      config.modalFooterTemplate = options.modalFooterTemplate ? options.modalFooterTemplate : config.modalFooterTemplate;

      config.onOkayClick = options.onOkayClick;
      config.onOkayProcessComplete = options.onOkayProcessComplete;
      config.onCancelClick = options.onCancelClick;
      config.onCancelProcessComplete = options.onCancelProcessComplete;
      config.onClose = options.onClose;
      config.onDestroy = options.onDestroy;
      config.modalClass += ' ' + options.modalClass;

    }

    this.config = config;

  }

  /**
   * Set the title of the screen
   * @param title
   */
  setTitle(title: string) {
    this.config.modalTitle = title;
  }

  /**
   * On cancel click
   */
  public cancelClicked(fromDismisser?: boolean) {
    this.modalChildComponent.onNicoModalCancelClick();

    if (this.config.onCancelClick) {
      this.config.onCancelClick();
    }
    if (this.config.onClose) {
      this.config.onClose();
    }
    if (this.modalChildComponent.canDismissNicoModalOnCancel() === true) {
      this.destroy();
    } else if(fromDismisser === true) {
        this.destroy();
    }
  }

  /**
   * On okay click
   */
  public okayClicked(fromDismisser?: boolean) {

    if (this.modalChildComponent.onNicoModalOkayClick) {
      this.modalChildComponent.onNicoModalOkayClick();
    }

    if (this.config.onOkayClick) {
      this.config.onOkayClick();
    }

    if (this.modalChildComponent.canDismissNicoModalOnOkay() === true) {
      this.destroy();

      if (this.config.onClose) {
        this.config.onClose();
      }
      if (this.config.onDestroy) {
        this.config.onDestroy();
      }
    } else if(fromDismisser === true) {
      this.destroy();
    }

  }

  public deleteCancelClicked() {

  }

  public deleteOkayClicked() {

  }

  public setBody(component: Type<NicoModalActionInterface>, data?: any) {
    const factory = this.contentResolver.resolveComponentFactory(component);
    this.modalChildComponent = this.modalbody.createComponent(factory).instance;
    this.modalChildComponent.setNicoModalChildData(data);
    this.modalChildComponent.setOkayProcessCompleteListener(this.config.onOkayProcessComplete);
    this.modalChildComponent.setNicoModalDismisser((action: NicoModalActionEnum) => {
      if (action === NicoModalActionEnum.Positive) {
        this.okayClicked(true);
      } else if (action === NicoModalActionEnum.Negative) {
        this.cancelClicked(true);
      } else {
        this.destroy();
      }
    });
  }

  public setComponentRef(ref: ComponentRef<any>) {
    this.vcr = ref;
  }

  protected addModalOpenClassToBody(add: boolean) {
    //get the class of the body
    let className = document.body.className;
    className = _.replace(className, /modal-open/g, '');
    className = className.trim();
    if (add === true) {
      className += 'modal-open';
    }
    document.body.className = className;
  }

  /**
   * Show the modal
   */
  public present() {
    this.visible = true;
    setTimeout(() => {
      this.addModalOpenClassToBody(true);
      this._in = true;
    }, 10);
  }

  public destroy() {
    this._in = false;
    setTimeout(() => {
      this.addModalOpenClassToBody(false);
      this.visible = false;
      this.vcr.destroy();
      this.modalChildComponent.onNicoModalClose();
      this.destroyed.emit();
      if (this.config.onDestroy) {
        this.config.onDestroy();
      }
    }, 600);
  }

}


@Component({
  'selector': 'nico-empty-modal',
  template: `
    <div [class.in]="_in" [class.show]="_in" [style.display]="visible===true?'block':'none'"
         [class]="config.modalClass">
      <div class="modal-dialog modal-dialog-centered" id="modalDialog">
        <div class="modal-content" [class.has-ft-img]="!config.showModalHeader && config.coverImage">
          <ng-template #modalbody></ng-template>
        </div>
      </div>
    </div>
  `
})

export class NicoEmptyModalComponent extends NicoModalComponent {
  /**
   * The constructor
   */
  constructor(protected contentResolver: ComponentFactoryResolver) {
    super(contentResolver);
  }


}


export enum NicoModalActionEnum {
  Positive, Negative
}
