import {Component, Input, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {NicoModalActionInterface} from "../../system/components/modal/NicoModalActionInterface";


@Component({
    selector: 'nico-confirm-dialog',
    template: `<p>{{label}}</p>`
})
export class ConfirmDialogComponent implements NicoModalActionInterface {

    public data: any;
    public label: string;

    public onNicoModalOkayClick() {

    }

    public onNicoModalCancelClick() {

    }

    public onNicoModalClose() {

    }

    public canDismissNicoModalOnOkay(): boolean {
        return true;
    }

    public canDismissNicoModalOnCancel(): boolean {
        return true;
    }

    public setNicoModalDismisser() {

    }

    setOkayProcessCompleteListener() {

    }

    setCancelProcessCompleteListener() {

    }

    public setNicoModalChildData(data) {
        this.data = data;
        if (data.message instanceof Observable) {
            this.data.message.subscribe((str: string) => {
                this.label = str;
            });
        } else {
            this.label = data.message;
        }
    }

}
