import {EventEmitter, Pipe, PipeTransform} from "@angular/core";
import {StatusEnum} from "../enums/status.enum";
import {Observable} from "rxjs";
import {AppLanguageProviderService} from "../../shared/services/app-language-provider.service";
import {Collection} from "../utilities/Collection";
@Pipe({
    name: 'lang'
})
export class LanguagePipe implements PipeTransform {
    protected options: Collection<{value: string, label: string}> = new Collection<{value: string, label: string}>(
      [
        {value: 'np', label: 'Nepali'},
        {value: 'en', label: 'English'},
        {value: 'tmg', label: 'Tamang'},
        {value: 'mthl', label: 'Maithili'},
      ]
    );
    transform(value: any, ...args: any[]): string {
      const item = this.options.first((item: any) => {
        return item.value === value;
      });
      if (item) {
        return item.label;
      }
      return value;
    }

}
