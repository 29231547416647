
import { Injectable } from "@angular/core";
import {isNullOrUndefined} from "util";
import {Helper} from "../utilities/Helper";

@Injectable()
export class ToastNotification {

  protected toastTimeout = 6000;
  protected autoHide = true;

  //** TODO. Make it configurable
  public constructor() {
    /*if((typeof autoHide)!='undefined' || (typeof autoHide)!=null ){
      this.autoHide = autoHide;
    }
    if(!isNaN(timeout)){
      this.toastTimeout = timeout;
    }*/
  }

  /**
   * Show toast
   * @return Toast
   */
  public showToast(message: string, title?: string, type?: ToastEnum, autoHide?: boolean, setTimeout?: number, position?:any, id?: string ): Toast {
    let timeout = this.toastTimeout;
    let hide = this.autoHide;

    if (setTimeout) {
      timeout = setTimeout;
    }
    if(!isNullOrUndefined(autoHide)) {
      hide = autoHide;
    }

    let toast: Toast = new Toast(message, title, type, hide, timeout, position);
    toast.show(id);
    Toast.toasts.push(toast);
    return toast;
  }

  /**
   * Show success Toast Message
   * @param message
   * @param autoHide
   * @param position
   * @param id
   */
  public success(message: string, autoHide?: boolean, position?:any, id?: string ) {
    this.showToast(message, null, ToastEnum.Success, autoHide, null, position, id);
  }

  /**
   * Show default toast
   * @param message
   * @param autoHide
   * @param position
   * @param id
   */
  public simple(message: string, autoHide?: boolean, position?:any, id?: string ) {
    this.showToast(message, null, ToastEnum.Default, autoHide, null, position, id);
  }

  /**
   * Show info
   * @param message
   * @param autoHide
   * @param position
   * @param id
   */
  public info(message: string, autoHide?: boolean, position?:any, id?: string ) {
    this.showToast(message, null, ToastEnum.Info, autoHide, null, position, id);
  }

  /**
   * Show Warning Toast
   * @param message
   * @param autoHide
   * @param position
   * @param id
   */
  public warning(message: string, autoHide?: boolean, position?:any, id?: string ) {
    this.showToast(message, null, ToastEnum.Warning, autoHide, null, position, id);
  }

  /**
   * Show Danger Toast, useful for error display
   * @param message
   * @param autoHide
   * @param position
   * @param id
   */
  public danger(message: string, autoHide?: boolean, position?:any, id?: string ) {
    this.showToast(message, null, ToastEnum.Danger, autoHide, null, position, id);
  }

  /**
   * Show ban info
   * @param message
   * @param autoHide
   * @param position
   * @param id
   */
  public ban(message: string, autoHide?: boolean, position?:any, id?: string ) {
    this.showToast(message, null, ToastEnum.Ban, autoHide, null, position, id);
  }

  public hideAll() {
    for (let i in Toast.toasts) {
      Toast.toasts[i].hide();
    }
    Toast.toasts = [];
  }

}

/**
 * Toast Class
 * This class is responsible for creating/displaying/destroying/time toasts
 */
export class Toast {
  /**
   * The toast container element
   */
  protected static toastContainer: HTMLDivElement = null;

  protected static isToastContainerAttached: boolean = false;

  /**
   * Spinner Array
   *  Array
   */
  public static toasts: Array<Toast> = [];

  /**
   * The Main div element of the toast
   */
  private alertElement: HTMLDivElement;

  private message: string;
  private title: string;
  private type: ToastEnum;
  private autoHide: boolean;
  private autoHideTimeout: number;
  private timeoutInstance: any;

  protected static defaultToastPosition = 'topRight';
  protected static validToastPositions = ['topLeft', 'topRight', 'topCenter', 'centerLeft',
      'centerRight','centerCenter','bottomLeft', 'bottomRight','bottomCenter'];
  /**
   * The constructor
   * @param message
   * @param title
   * @param type
   * @param autoHide
   * @param autoHideTimeout Timeout in millisecond
   * @param positionOrContainer
   */
  constructor(message: string, title?: string, type?: ToastEnum, autoHide?: boolean, autoHideTimeout?: number, positionOrContainer?: any) {
    Toast.createToastContainer(positionOrContainer);
    this.message = message;
    this.title = title;
    this.type = type;
    this.autoHideTimeout = autoHideTimeout;
    this.autoHide = autoHide;

    if (!this.type) {
      this.type = ToastEnum.Success;
    }
    if (isNullOrUndefined(this.autoHide)) {
      this.autoHide = true;
    }
    if (!this.autoHideTimeout) {
      this.autoHideTimeout = 15000;
    }

  }

  /**
   * Create Toast Container
   * @param positionOrContainer
   */
  private static createToastContainer(positionOrContainer?:any) {

    if(positionOrContainer  && (positionOrContainer instanceof HTMLElement)){
      Toast.toastContainer = <any>positionOrContainer;
    }

    if (Toast.toastContainer === null) {
      Toast.toastContainer = document.createElement('div');
      Toast.toastContainer.id = "nico-toast-container";

      if(Toast.validToastPositions.indexOf(positionOrContainer) >=0) {
        Toast.toastContainer.className = positionOrContainer;
      } else {
        Toast.toastContainer.className = Toast.defaultToastPosition;
      }
    }

  }

  /**
   * Remove the container
   */
  protected static removeContainerIfEmpty() {
    if (!Toast.toastContainer) {
      return;
    }
    if (Toast.toastContainer.hasChildNodes() === false || Toast.toastContainer.innerHTML == '' || Toast.toastContainer.innerText == '') {
      //safely remove the toast-container
      if(Toast.toastContainer.remove){
          Toast.toastContainer.remove();
      }else{
          Toast.toastContainer.parentElement.removeChild(Toast.toastContainer);
      }
      Toast.isToastContainerAttached = false;
    }
  }

  /**
   * Get the alert class
   * @return any
   */
  private getAlertClass(): string {
    switch (this.type) {
      case ToastEnum.Default:
        return 'alert-default';
      case ToastEnum.Danger:
        return 'alert-danger';
      case ToastEnum.Success:
        return 'alert-success';
      case ToastEnum.Info:
        return 'alert-info';
      case ToastEnum.Warning:
        return 'alert-warning';
      case ToastEnum.Ban:
        return 'alert-ban';
      default:
        throw Error('Unsupported toast notification type');
    }
  }
  /**
   * Get the glyphicon
   * @return any
   */
  private getGlyphicon(): string {
    switch (this.type) {
      case ToastEnum.Default:
        return 'fa-info-circle';
      case ToastEnum.Danger:
        return 'fa-times-circle';
      case ToastEnum.Success:
        return 'fa-check-circle';
      case ToastEnum.Info:
        return 'fa-info-circle';
      case ToastEnum.Warning:
        return 'fa-exclamation-circle';
      case ToastEnum.Ban:
        return 'fa-ban';
      default:
        throw Error('Unsupported toast notification type');
    }
  }
  /**
   * Create spinner
   */
  private createToast(id?: string) {
    if(!isNullOrUndefined(id)) {
        //remove the first same IDed element
        const elem = document.getElementById(id);
        if(elem) {
            elem.parentElement.removeChild(elem);
        }
    }
    this.alertElement = document.createElement('div');
    if(isNullOrUndefined(id)) {
      id = Helper.getRandomString(10);
    }
    this.alertElement.id = id;
    let closeElement = document.createElement('span');
    let className = `nico-alert nico-${this.getAlertClass()}`;
    this.alertElement.className = className + ' pre-enter';
    closeElement.innerHTML = '&times;';
    closeElement.className = 'close';

    this.alertElement.innerHTML = `<i class="alert-icon fa ${this.getGlyphicon()}"></i> ${this.message}`;

    this.alertElement.appendChild(closeElement);

    closeElement.addEventListener('click', () => {
      this.removeToast();
    });
    if (Toast.isToastContainerAttached === false) {
      document.body.appendChild(Toast.toastContainer);
      Toast.isToastContainerAttached = true;
    }
    Toast.toastContainer.appendChild(this.alertElement);
    //for animation purpose update class to entered after few seconds
    setTimeout(() => { this.alertElement.className = className + ' entered' }, 100);
    this.enableAutohideIfNecessary();

  }

  private enableAutohideIfNecessary() {

    if (this.autoHide === false) {
      return;
    }

    this.timeoutInstance = setTimeout(() => {
      this.hide();
    }, this.autoHideTimeout);
  }

  /**
   * Remove all toast
   */
  private removeToast() {
    if (this.alertElement) {
      let className = `alert-icon nico-alert nico-${this.getAlertClass()}`;
      this.alertElement.className = className + ' exiting';
      //give some time about 600ms for exiting
      setTimeout(() => {
        if(this.alertElement.remove){
            this.alertElement.remove();
        }else {
          this.alertElement.parentElement.removeChild(this.alertElement);
        }
        //remove the toast from the container
        Toast.toasts.splice(Toast.toasts.indexOf(this), 1);
        //unset the timeout
        clearTimeout(this.timeoutInstance);
        Toast.removeContainerIfEmpty();
      }, 500);
    }
  }

  public show(id?: string) {
    this.createToast(id);
  }

  public hide() {
    this.removeToast();
  }
}
/**
 * The Toast Enum
 */
export enum ToastEnum {
  Default,
  Success,
  Danger,
  Warning,
  Info,
  Ban,
}
