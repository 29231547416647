import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../../system/controllers/BaseComponent';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends BaseComponent implements OnInit {

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.setPageTitle('mod_commons.error_404_label');
  }

  public goBack($event) {
    $event.stopPropagation();
    if (this.session.getAuthUser()) {
      this.router.navigate(['/manage']);
    } else {
      /**
       * route unauthorized user to some page
       */
      this.location.back();
    }
  }
}
