export class AdvNavbarService {

    public navbarStack: Array<AdvNavbar> = [];

    public visibility: boolean = true;

    public create(option: AdvNavbarSettings): AdvNavbar {
        const nav = new AdvNavbar(option);
        this.navbarStack.push(nav);
        return nav;
    }

    public getOptions(): AdvNavbar {
        if (this.navbarStack.length === 0) {
            return null;
        }
        //always return the last one. because we only show the last navbar
        return this.navbarStack[this.navbarStack.length - 1];

    }

    public setVisibility(visible: boolean) {
        this.visibility = visible;
    }

    public removeNavbar(): void {
        this.navbarStack.pop();
    }

    public destroyNavbar(): void {
        this.navbarStack.length = 0;
    }

}

export class AdvNavbar implements AdvNavbarSettings {
    public title: string;
    public subTitle: string;
    public className: string;
    public urlPrefix: string;
    public classNamePrefix: string;
    public menuItems: Array<AdvNavMenuItem>;
    public action: () => {};
    public actionTitle: string;
    public logo: string;
    public styles: {[key: string]: any};
    public showLogo: boolean;

    constructor(settings: AdvNavbarSettings) {
        this.setOptions(settings);
    }

    public setOptions(options: AdvNavbarSettings) {
        this.title = options.title;
        this.subTitle = options.subTitle;
        this.className = options.className;
        this.urlPrefix = options.urlPrefix;
        this.classNamePrefix = options.classNamePrefix;
        this.menuItems = options.menuItems;
        this.action = options.action;
        this.actionTitle = options.actionTitle;
        this.logo = options.logo;
        this.styles = options.styles;
        this.showLogo = options.showLogo;
    }

    public setTitle(title: string): AdvNavbar {
        this.title = title;
        return this;
    }

    public setSubTitle(value: string): AdvNavbar {
        this.subTitle = value;
        return this;
    }

    public setLogo(logo: string): AdvNavbar {
        this.logo = logo;
        return this;
    }

    public setClassName(val: string): AdvNavbar {
        this.className = val;
        return this;
    }

    public setClassNamePrefix(val: string): AdvNavbar {
        this.classNamePrefix = val;
        return this;
    }

    public setUrlPrefix(val: string): AdvNavbar {
        this.urlPrefix = val;

        return this;
    }

    public setStyles(obj: {}) {
        this.styles = obj;
        return this;
    }

    public getUrlPrefix(): string {
        return this.urlPrefix;
    }

}

export interface AdvNavbarSettings {
    title?: string;
    subTitle?: string;
    className?: string;
    urlPrefix?: string;
    classNamePrefix?: string;
    menuItems?: Array<AdvNavMenuItem>;
    action?: () => {};
    actionTitle?: string;
    logo?: string;
    styles?: {};
    showLogo?: boolean;

}

export interface AdvNavMenuItem {
    label: string;
    routerLink: Array<any>;
}
