import {Directive, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[appOnResize]'
})
export class OnResizeDirective implements OnInit {

  @Output() onResize: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private elementRef: ElementRef,
  ) {
  }

  ngOnInit() {
    this.onResize.emit(this.elementRef.nativeElement.clientHeight);
  }

  @HostListener('window:resize', ['$event'])
  onListenerTriggered($event) {
    $event.stopPropagation();
    this.onResize.emit({height: this.elementRef.nativeElement.clientHeight, width: this.elementRef.nativeElement.clientWidth});
  }
}
