import {Component, Input} from "@angular/core";
import {BasePublisher} from "../../../shared/base/contituents/publisher/models/BasePublisher";

@Component({
  selector: 'app-publisher-card',
  templateUrl: './nico-publisher-card.component.html'
})

export class NicoPublisherCardComponent {
  @Input() model: BasePublisher;
}
