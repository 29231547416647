
import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from "../../system/controllers/BaseComponent";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'auth-cmpt',
    templateUrl: 'auth.component.html',

})
export class AuthComponent extends BaseComponent implements OnInit{

    public dateYear: number;
    /**
     * The constructor
     * @param {Injector} injector
     */
    public constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit () {
        super.ngOnInit();
        this.dateYear = (new Date()).getFullYear();
        this.http.setNamespace(environment.manage_namespace);
    }
}
