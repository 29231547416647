import {Component} from '@angular/core';
import {AdvNavbarService, AdvNavbarSettings, AdvNavbar, AdvNavMenuItem} from './AdvNavbarService';

/**
 * This class represents the navigation bar component.
 */
@Component({
    selector: 'adv-navbar',
    templateUrl: 'adv-navbar.component.html',
})

export class AdvNavbarComponent {

    constructor(public advNavBar: AdvNavbarService) {

    }

    getUrl(routes: Array<any>) {
        const prefix = this.advNavBar.getOptions().getUrlPrefix();

        if (prefix) {
            return [prefix].concat(routes);
        } else {
            return routes;
        }
    }

    onActionClick() {
        if (this.advNavBar.getOptions().action) {
            this.advNavBar.getOptions().action();
        }
    }

}

