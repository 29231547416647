import {BaseService} from "../../system/services/BaseService";
import {BaseUserInterface} from "../base/contituents/user/interfaces/UserBaseInterface";
import {Injectable} from "@angular/core";
import {NicoHttp} from "../../system/requests/NicoHttp";
import {environment} from "../../../environments/environment";
import {BaseUser} from "../base/contituents/user/models/BaseUser";

@Injectable()
export class BaseUserService extends BaseService {
    protected resource: BaseUserInterface;
    protected resourceBaseUrl: "";
    protected resourceName: "";

    /**
     * Constructor
     * @param http
     */
    public constructor(protected http: NicoHttp) {
        super(http, environment);
        this.resource = new BaseUser();
        this.setResourceName("users");
    }
}
