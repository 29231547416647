import {NicoHttp} from '../requests/NicoHttp';
import {RequestOptionsArgs} from '@angular/http';
import {ServerResponse} from '../responses/ServerResponse';
import {BaseResourceInterface} from '../datamodels/BaseResourceInterface';
import {ServerResponseBodyInterface} from '../responses/interfaces/ServerResponseInterface';
import {Params} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {EnvironmentInterface} from '../datamodels/EnvironmentInterface';
import {Inject} from '@angular/core';
import {Collection} from '../utilities/Collection';
import {StatusEnum} from '../enums/status.enum';

/**
 * The Base Service Class
 */
export abstract class BaseService {
  /**
   * The resource
   *  string
   */
  protected abstract resourceName: string;

  protected rawResourceString: string;
  /**
   * Base resource url
   *  string
   */
  protected abstract resourceBaseUrl: string;
  /**
   * The Resource
   *  any
   */
  protected abstract resource: BaseResourceInterface;

  protected params: Params;


  /**
   *
   * @param http
   * @param environment
   */
  public constructor(protected http: NicoHttp, @Inject('EnvironementInterface') protected environment: EnvironmentInterface) {
    this.generateResourceUrl();
  }

  /**
   * Raw resource string
   */
  public getRawResourceString(): string {
    return this.rawResourceString;
  }

  /**
   * Get Api Base url
   * @returns string
   */
  public getBaseApiUrl() {
    return this.environment.api;
  }

  /**
   * Build url form params. Example /password/reset/{token}, if config contains {token:'somevalue'}, the token value will be placed
   * @param url
   * @param config
   * @return string
   */
  public buildUrlFromParams(url: string, config: Params) {
    if (!config) {
      config = {};
    }

    for (const key in config) {
      url = url.replace('{' + key + '}', config[key]);
    }

    return url;

  }

  /**
   * Generate Resource Url
   */
  public generateResourceUrl(params?: Params) {
    this.params = params;
    this.resourceBaseUrl = `${this.getBaseApiUrl()}/${this.resourceName}`;

    if (this.params) {
      this.resourceBaseUrl = this.buildUrlFromParams(this.resourceBaseUrl, params);
    }
  }

  /**
   * Set the resource
   * @param resource
   * @param params
   */
  public setResourceName(resource?: string, params?: any) {
    if (!resource) {
      resource = this.getRawResourceString();
    }
    this.resourceName = resource;
    this.params = params;
    this.generateResourceUrl(params);
  }

  /**
   * Get the list of resource
   * @param options
   * @return Observable<BaseResourceInterface>
   */
  public get(options?: RequestOptionsArgs, showToast?: boolean): Observable<ServerResponseBodyInterface> {
    return this.http.get(this.resourceBaseUrl, options, showToast).pipe(map((d: ServerResponse) => {
      let ret: Collection<BaseResourceInterface> = null;
      if (d.body.total >= 0 && !isNaN(d.body.total)) { // this mean it has paginated items
        ret = this.resource.createFromPaginatedCollection(d.body);
      } else {
        ret = this.resource.createFromCollection(d.body.data);
      }

      return ret;
    }));
  }

  public options(opts?: RequestOptionsArgs, showToast?: boolean): Observable<ServerResponseBodyInterface> {
    return this.http.options(this.resourceBaseUrl, opts, showToast).pipe(map((d: ServerResponse) => {
      let ret: Collection<BaseResourceInterface> = null;
      if (d.body.total >= 0 && !isNaN(d.body.total)) { // this mean it has paginated items
        ret = this.resource.createFromPaginatedCollection(d.body);
      } else {
        ret = this.resource.createFromCollection(d.body.data);
      }
      ret.forEach((item: BaseResourceInterface) => {
        (<any>item).hidden = false;
      });
      return ret;
    }));
  }

  /**
   * Find the resource
   * @param id
   * @param options
   * @return Observable<BaseResourceInterface>
   */
  public find(id: any, options?: RequestOptionsArgs, showToast?: boolean): Observable<BaseResourceInterface> {
    return this.http.get(this.resourceBaseUrl + '/' + id, options, showToast).pipe(map((d: ServerResponse) => {
      return this.resource.create(d.body);

    }));
  }

  /**
   * Save the given resource
   * @param data
   * @param id
   * @param options
   * @return Observable<ServerResponse>
   */
  public save(data: any, id?: number, options?: RequestOptionsArgs, showToast?: boolean): Observable<ServerResponse> {
    let response: Observable<ServerResponse> = null;
    if (id > 0) {
      response = this.http.put(this.resourceBaseUrl + '/' + id, data, options, showToast);
    } else {
      response = this.http.post(this.resourceBaseUrl, data, options, showToast);
    }
    return response;
  }

  /**
   * Delete the given resource
   * @param data
   * @param options
   * @param showToast
   * @return Observable<ServerResponse>
   */
  public destroy(data: BaseResourceInterface, options?: RequestOptionsArgs, showToast?: boolean): Observable<ServerResponse> {
    return this.http.delete(`${this.resourceBaseUrl}/${((<any>data)[(<any>data).primaryKey])}`, options, showToast);
  }

  /**
   * Publish/Unpublish a given resource
   * @param id
   * @param currentStatus
   * @param options
   * @param showToast
   */
  public togglePublish(id: number, currentStatus: number, options?: RequestOptionsArgs, showToast?: boolean): Observable<ServerResponse> {
    return this.http.put(`${this.resourceBaseUrl}/${id}/status`, {
      status: currentStatus === StatusEnum.Published ? StatusEnum.Unpublished : (currentStatus === StatusEnum.Unpublished ?
        StatusEnum.Published : currentStatus)
    }, options, showToast);
  }

  /**
   * change order for given items/resources
   * @param orderedItems
   */
  public order(orderedItems: Collection<BaseResourceInterface>): Observable<any> {
    return this.http.post(this.resourceBaseUrl + '/order', {
      ids: orderedItems.map((item: BaseResourceInterface) => {
        return item.id;
      }).all()
    });
  }
}

