import {
  ServerResponseInterface, ServerResponseBodyInterface,
  ServerResponseStatusInterface
} from "./interfaces/ServerResponseInterface";
import {Headers, Response} from "@angular/http";

export class ServerResponse implements ServerResponseInterface {
  /**
   * The body of the response from server
   */
  public body: ServerResponseBodyInterface = {};
  /**
   * The status object from server response
   */
  public status: ServerResponseStatusInterface  = {message: '', code: '', statusCode: 0, messageShown: false};

  /**
   * Parse error from response
   * @param response
   */
  public static fromResponse (response: Response, options?: any): ServerResponse {
    const ret = new ServerResponse();
    try {
      const json: any = response.json();
      if (!json) {
        ret.body = null;
        ret.status = {
          message: response.statusText,
          code: response.status,
          statusCode: response.status,
          messageShown: options ? options.messageShown : false
        };
      } else {
        ret.body = json.body;
        ret.status = json.status || {};
        ret.status.statusCode = response.status;
        ret.status.messageShown = options ? options.messageShown : false;
      }
      return ret;
    } catch (e) {
      throw {
        body: null,
        status: {
          statusCode: response.status,
          messageShown: false,
          message: "An error occurred. Please try reloading the browser."
        }
      };
    }
  }

}
