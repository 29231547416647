import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { Helper } from '../utilities/Helper';


@Pipe({
    name: 'customDateFormat'
})

export class DateFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (args === 'dot') {
            return super.transform(value, Helper.DATE_FMT);
        }

    }
}
