
import {BreadcrumbsComponent} from "./breadcrumbs.component";
import {BreadcrumbsService} from "./BreadcrumbsService";
import {NgModule, ModuleWithProviders} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
@NgModule({
  imports: [CommonModule,RouterModule],
  declarations: [BreadcrumbsComponent],
  exports:[BreadcrumbsComponent],
  providers:[]
})
export class BreadcrumbsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: BreadcrumbsModule,
      providers: [
        BreadcrumbsService
      ]
    };
  }
}
