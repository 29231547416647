import {EventEmitter, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthenticableInterface} from '../datamodels/AuthenticableInterface';

export class NicoSession {
  protected namespace: string = '';

  /**
   * The token key
   *  string
   *
   */
  private _tokenKey = 'auth_token';
  /**
   * The auth user
   *  string
   *
   */
  private _userKey = 'auth_user';
  /**
   * The authenticated user
   */
  private _authUser: any;


  protected _uacNamespaceKey = 'uac_namespace';

  private _pageAttemptKey = "page_attempt";

  /**
   * Auth user updated event handlers
   */
  private authUserUpdated: EventEmitter<any>;

  public constructor(@Inject('AuthenticatableInterface') protected auth: AuthenticableInterface) {
    this.authUserUpdated = new EventEmitter();
  }

  public authUserUpdate(): Observable<any> {
    return this.authUserUpdated;
  }

  public getNamespace(): string {
    return this.namespace;
  }

  public setNamespace(str: string) {
    this.namespace = str;
    return this;
  }


  /**
   * @return string
   */
  protected getFullyQualifiedKey(key: string) {
    return `${this.namespace}.${key}`;
  }
  /**
   * Set the auth token in the local storage for later use
   * This token gets appended in every following request unless modified otherwise
   * @param value
   * @param namespace
   */
  public setToken(value: string, namespace?: string) {
    localStorage.setItem(this.getFullyQualifiedKey(this._tokenKey), value);
  }

  /**
   * Get the current auth token
   * @return any
   */
  public getToken(): string {
    return localStorage.getItem(this.getFullyQualifiedKey(this._tokenKey));
  }

  /**
   * Set authenticated user in the session
   * @param user
   */
  public setAuthUser(user: any) {
    this._authUser = user;
    localStorage.setItem(this.getFullyQualifiedKey(this._userKey), JSON.stringify(user));
    this.authUserUpdated.emit(user);
  }

  /**
   * Get the authenticated user
   * @return any
   */
  public getAuthUser(): AuthenticableInterface {
    if (this._authUser != null ) {
      if (!this._authUser.can) {
        return this.auth.create(this._authUser);
      }
      return this._authUser;
    }
    let obj = localStorage.getItem(this.getFullyQualifiedKey(this._userKey));
    if (obj) {
      try {
          obj = JSON.parse(obj);
      } catch (e) {
          obj = null;
      }
    }
    if (!obj) {
      return null;
    }
    try {
      return this.auth.create(obj);
    } catch (e) {
      this.clearAuth();
    }

  }

  /**
   * Remove auth user and token from storage
   */
  public clearAuth() {
    localStorage.removeItem(this.getFullyQualifiedKey(this._tokenKey));
    localStorage.removeItem(this.getFullyQualifiedKey(this._userKey));
    localStorage.removeItem(this.getFullyQualifiedKey(this._uacNamespaceKey));
    this._authUser = null;
  }

  public setPageAttempt (str: string) {
    localStorage.setItem(this.getFullyQualifiedKey(this._pageAttemptKey), str);
  }

  public getPageAttempt(): string {
    return localStorage.getItem(this.getFullyQualifiedKey(this._pageAttemptKey));
  }

  public clearPageAttempt () {
    localStorage.removeItem(this.getFullyQualifiedKey(this._pageAttemptKey));
  }

}
