import {BasePublisherInterface} from "../interfaces/BasePublisherInterface";
import {AbstractBaseResource} from "../../../../../system/datamodels/AbstractBaseResource";
import {BaseUser} from "../../user/models/BaseUser";
import {BasePublisherPrefix} from "./BasePublisherPrefix";

export class BasePublisher extends AbstractBaseResource implements BasePublisherInterface  {
    public id: number;
    /**
     * The primary key identifier
     * @type {string}
     */
    primaryKey: string = "id";
    /**
     * The creatable Attributes of the institutions
     */
    creatableAttributes: Array<string> = ['id', 'title', 'street', 'city', 'phone_number', 'fax_number', 'email', 'logo_url'];

    /**
     * Create
     * @param obj
     */
    public create(obj: any): BasePublisherInterface {
        const ret: BasePublisherInterface =  super.create(obj);
        if (obj.administrator) {
          ret.administrator = (new BaseUser()).create(obj.administrator);
        }
        const prefix = new BasePublisherPrefix();
        if (obj.prefixes) {
          ret.prefixes = prefix.createFromCollection(obj.prefixes);
        }

        if (obj.active_prefix) {
          ret.active_prefix = prefix.create(obj.active_prefix);
        }

        return ret;
    }

}
