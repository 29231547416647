import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

import {AdvancedSelectComponent} from './components/advanced-select/advanced-select.component';
import {NicoEmptyModalComponent, NicoModalComponent} from './components/modal/NicoModal';

import {PipesModule} from './pipes/pipes.module';
import {AdvNavbarModule} from './components/adv-navbar/advNavbar.module';
import {BreadcrumbsModule} from './components/breadcrumbs/breadcrumbs.module';
import {EnvironmentInterface} from './datamodels/EnvironmentInterface';
import {NicoDateComponent} from './components/nico-date/nico-date.component';
import {NicoStatusComponent} from './components/nico-status/nico-status.component';
import {NicoStatusInputComponent} from './components/nico-status-input/nico-status-input.component';
import {AdvancedSearchComponent} from './components/advanced-search/advanced-search.component';
import {
  AccordionModule,
  BsDatepickerModule,
  BsDropdownModule,
  ButtonsModule,
  ModalModule,
  TabsModule,
  TooltipModule,
  TypeaheadModule
} from 'ngx-bootstrap';
import {FontAwesomeIconInputComponent} from "./components/font-awesome-icon-input/font-awesome-icon-input.component";
import {InputExpandDirective, NicoExpandedTextareaComponent} from "./directives/input-expand.directive";
import {AngularEditorModule} from "@kolkov/angular-editor";

/**
 * Created by Amar on 1/14/2017.
 */

@NgModule({
  imports: [
    AdvNavbarModule.forRoot(),
    BreadcrumbsModule.forRoot(),
    PipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    AngularEditorModule,
  ],
  entryComponents: [NicoModalComponent, NicoEmptyModalComponent, NicoExpandedTextareaComponent],
  declarations: [
    AdvancedSelectComponent, NicoStatusInputComponent, AdvancedSearchComponent,
    NicoModalComponent, NicoEmptyModalComponent, NicoDateComponent, NicoStatusComponent, FontAwesomeIconInputComponent,
    InputExpandDirective, NicoExpandedTextareaComponent
  ],
  exports: [
    AdvancedSelectComponent, NicoDateComponent, NicoStatusComponent, NicoStatusInputComponent, AdvancedSearchComponent,
      FontAwesomeIconInputComponent, InputExpandDirective, NicoExpandedTextareaComponent,
    AdvNavbarModule,
    BreadcrumbsModule,
    PipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BsDropdownModule,
    BsDatepickerModule,
    ButtonsModule,
    ModalModule,
    TabsModule,
    AccordionModule,
    TooltipModule,
    TypeaheadModule,
    AngularEditorModule
  ],

})

export class SystemModule {
  static forRoot(env: EnvironmentInterface, userFactory: Function): ModuleWithProviders {
    return {
      ngModule: SystemModule,
      providers: [
        {provide: 'EnvironmentInterface', useValue: env},
        {provide: 'AuthenticatableInterface', useFactory: userFactory }
      ]
    };
  }
}
