import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {FormGroup, Validators} from '@angular/forms';
import {User} from '../../models/User';
import {BaseComponent} from "../../../../system/controllers/BaseComponent";
import {NicoModalComponent} from "../../../../system/components/modal/NicoModal";
import {Spinner} from "../../../../system/services/AjaxSpinner";
import {ServerResponse} from "../../../../system/responses/ServerResponse";
import {Toast, ToastEnum} from "../../../../system/services/ToastNotification";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {UploadFileOptionsInterface} from "../../../upload-file-component/upload-file.options.interface";

/**
 * This class represents the lazy loaded HomeComponent.
 */
@Component({
    //moduleId: module.id,
    selector: 'sd-register',
    templateUrl: 'register.component.html',
    styleUrls: [],

})
export class RegisterComponent extends BaseComponent implements OnInit, OnDestroy {
    /**
     * The submitForm form
     * @type FormGroup
     */
    public form: FormGroup;
    /**
     * Is submitForm submit busy
     * @type {boolean}
     */
    public busy: boolean = false;

    public comp: NicoModalComponent;

    public formId: string = "registerForm";

    public response: any;

    protected toastMessage: Toast;

    public companyDocsUploadOption: UploadFileOptionsInterface;

    public companyRegisterUploadOption: UploadFileOptionsInterface;

  /**
   * Constructor
   * @param authService
   * @param recaptchaService
   * @param injector
   */
    public constructor(protected authService: AuthService, protected recaptchaService: ReCaptchaV3Service,
        injector: Injector) {
        super(injector);
    }

    protected setUploadOptions(forId: string, label: string) {
     

      const opt: UploadFileOptionsInterface = {
        title: label,
        instantUpload: true,
        cancellable: false,
        disabledOnComplete: true,
        inputId: forId,
        hidden: false,
        uploadInputConfig: {
          type: 'uploadAll',
          url: this.authService.getBaseApiUrl() + '/auth/upload-resources-register',
          headers: {'Authorization': 'Bearer ' + this.session.getToken()},
          method: 'POST',
          fieldName: 'file',
          data: {'type': 'image'},
        }
      };
      if (forId === 'company_docs_url') {
        this.companyDocsUploadOption = opt;
      } else if (forId === 'company_register_url') {
        this.companyRegisterUploadOption = opt;
      }
    }

    /**
     * Ng On init implemented method
     */
    public ngOnInit() {
        this.setPageTitle('mod_register.module_title');
        this.initForm();
       this.setUploadOptions('company_docs_url','Valid company document file');        
    }

    public onFileUploadComplete (event: ServerResponse, formControlName: string) {
      this.form.controls[formControlName].setValue(event.body.path);
    }

    /**
     * On onDestroy life cycle message
     */
    public ngOnDestroy() {
        if (this.toastMessage) {
            this.toastMessage.hide();
        }
    }

    /**
     * Init the submitForm form
     */
    private initForm() {
        this.form = this.formBuilder.group({
            first_name: ['', [Validators.compose([Validators.required])]],
            last_name: ['', [Validators.compose([Validators.required])]],
            email: ['', [Validators.compose([Validators.required])]],
            name_of_publisher: ['', [Validators.compose([Validators.required])]],
            address_of_publisher: ['', [Validators.compose([Validators.required])]],
            contact_number: ['', [Validators.compose([Validators.required])]],
            register_type: ['', [Validators.compose([Validators.required])]],
            password: ['', Validators.required],
            password_confirmation: ['', Validators.required],
            company_docs_url: [null, [Validators.required]],
        });
    }

    /**
     * Login
     */
    public submit(event: Event) {
      event.preventDefault();
      if (this.busy) {
        return;
      }
      this.recaptchaService.execute('isbnReCaptchaVerificationCode').subscribe((token: string) => {
        this.busy = true;
        const spinner: Spinner = this.ajaxSpinner.showSpinner();
        const value = this.form.value;
        value.recaptcha_token = token;
        this.authService.register(this.form.value)
          .subscribe(
            (d: User) => {
              this.busy = false;
              spinner.hide();

              this.router.navigate(['/auth/login']);
              this.showSuccessToast('mod_register.register_success_message', false);
            },
            (d: ServerResponse) => {
              this.toastMessage = new Toast(d.status.message, null, ToastEnum.Danger, false, null, 'centerCenter');
              this.toastMessage.show('registerToast');
              this.busy = false;
              spinner.hide();
              if (d.status.statusCode === 417) {
                this.validationMessenger.attach( this.formId, d.body);
              } else {

              }
            }
          );
      }, error =>  {
        this.showErrorToast("Oops! there seems to be suspicious activity. Please try reloading your browser and try again.");
      });
    }

    public onChangeRgisterType(event) {
      // if(event.target.value === 'publisher') {
      //   this.form.get('company_docs_url').setValidators([Validators.required]);
      // } else {
      //   this.form.get('company_docs_url').setValidators(null);
        
      // }
      // this.form.get('company_docs_url').updateValueAndValidity();
    }

}
