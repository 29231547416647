import {Component, Injector, OnDestroy, OnInit} from "@angular/core";
import {AbstractNicoModalComponent} from "../../system/components/modal/AbstractNicoModalComponent";
import {Validators} from "@angular/forms";
import {IsbnApplicationService} from "../services/isbn-application.service";
import {ServerResponse} from "../../system/responses/ServerResponse";
import {BaseIssuedISBN} from "../base/contituents/isbn-application/models/BaseIssuedISBN";
import * as moment from "moment";
import {Subscription} from "rxjs";
import {Helper} from "../../system/utilities/Helper";

@Component({
  selector: 'app-issue-isbn-modal',
  templateUrl: './isbn-issue-modal.component.html'
})
export class IsbnIssueModalComponent extends AbstractNicoModalComponent implements OnInit, OnDestroy {

  public modalTitle = "Issue ISBN";

  public formId = "isbn-create-modal";

  public editDisabled = false;

  public checkDigit;

  public autoGeneratedIsbn: BaseIssuedISBN = null;

  public isbnNumberChangeSubscription: Subscription = null;

  public invalidIsbn = false;

  public customIdentifierModel = true;

  public constructor (injector: Injector) {
    super(injector);
    this.service = injector.get(IsbnApplicationService);
  }

  protected watchFormChangeForCheckDigitUpdate () {
    let callback: any;
    let prefix =  null;
    if (!this.data.isbn && this.data.application.publisher.active_prefix) {
      prefix = this.data.application.publisher.active_prefix.prefix;
    } else
    if (this.data.isbn && this.data.isbn.publisher_prefix) {
      prefix = this.data.isbn.publisher_prefix.prefix;
    }

    let country_isbn_identifier = (this.data.application.publisher.active_prefix) ? this.data.application.publisher.active_prefix.country_prefix : null;

    callback = (value: any) => {
      this.checkDigit = null;
      this.invalidIsbn = false;
      // tslint:disable-next-line:triple-equals
      if (value || value == 0) {
        try {
          this.checkDigit = Helper.getISBNMod13CheckDigit(country_isbn_identifier,
            prefix, value
          );
        } catch (e) {
          this.invalidIsbn = true;
        }

      }
    };
    if (callback) {
      this.isbnNumberChangeSubscription = this.formGroup.controls['isbn_number'].valueChanges.subscribe(callback);
    }

  }

  public ngOnInit() {
    this.formGroup = this.formBuilder.group({
      issued_date: ['', Validators.required],
      isbn_number: ['', Validators.required],
    });
    this.watchFormChangeForCheckDigitUpdate();
    if (this.data.isbn) {
      this.formGroup.patchValue({issued_date: this.data.isbn.issued_date.format('YYYY-MM-DD'),
      isbn_number: this.data.isbn.isbn_number});
      this.customIdentifierModel = false;
      if (this.data.isbn.readonly) {
        this.formGroup.disable();
        // this.editDisabled = true;
        this.customIdentifierModel = true;
      }
      this.modalTitle = "View ISBN";
    } else {
      this.getNewIsbnIdentifier();
    }

  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if(this.isbnNumberChangeSubscription) {
      this.isbnNumberChangeSubscription.unsubscribe();
    }
  }

  public onCustomIdentifierChange () {
    if (this.customIdentifierModel) {
      this.formGroup.controls['isbn_number'].setValue(this.autoGeneratedIsbn.isbn_number);
    }
  }

  public getNewIsbnIdentifier () {
    const spinner = this.ajaxSpinner.showSpinner();
    const subscription =  (<IsbnApplicationService> this.service)
      .getNewIsbn(this.data.application)
      .subscribe((issuedIsbn: BaseIssuedISBN) => {
        spinner.hide();
        if (subscription) {
          subscription.unsubscribe();
        }
        this.autoGeneratedIsbn = issuedIsbn;
        this.formGroup.controls['isbn_number'].setValue(this.autoGeneratedIsbn.isbn_number);
      }, (err: ServerResponse) => {
        spinner.hide();
        if (subscription) {
          subscription.unsubscribe();
        }
      });
  }

  public commit () {
    const spinner = this.ajaxSpinner.showSpinner();
    const subscription =  (<IsbnApplicationService> this.service).commitIssuedISBN(this.data.application, this.data.isbn)
      .subscribe((issuedIsbn: BaseIssuedISBN) => {
        this.dismisser();
        spinner.hide();
        if (subscription) {
          subscription.unsubscribe();
          this.okayCompleteListener(issuedIsbn, 'The ISBN issued has been committed permanently.');
        }
      }, (err: ServerResponse) => {
        spinner.hide();
        if (subscription) {
          subscription.unsubscribe();
        }
        this.validationMessenger.attach(this.formId, err.body);
      });
  }
  public save(): any {
    const spinner = this.ajaxSpinner.showSpinner();
    this.formGroup.value.issued_date = moment(this.formGroup.value.issued_date).format('YYYY-MM-DD');
    const subscription = (<IsbnApplicationService> this.service).issueISBN(this.data.application, this.formGroup.value, this.data.isbn).subscribe((issuedIsbn: BaseIssuedISBN) => {
      spinner.hide();
      if (subscription) {
        subscription.unsubscribe();
        this.dismisser();
        let message = 'New ISBN has been issued to the application';
        if (this.data.isbn) {
          message = "ISBN has been updated successfully.";
        }
        this.okayCompleteListener(issuedIsbn, message);
      }
    }, (err: ServerResponse) => {
      spinner.hide();
      if (subscription) {
        subscription.unsubscribe();
      }
      if (err.status.statusCode === 417) {
        this.validationMessenger.attach(this.formId, err.body);
      }
    });

  }

}
