import {BaseResourceInterface} from './BaseResourceInterface';
import {Collection} from '../utilities/Collection';
import {PaginatedCollection} from '../utilities/PaginatedCollection';
import {StatusEnum} from '../enums/status.enum';
import {isNullOrUndefined} from "util";

export abstract class AbstractBaseResource implements BaseResourceInterface {

  creatableAttributes: Array<string>;

  public status: StatusEnum;

  public id: number;

  primaryKey: string = 'id';

  [key: string]: any;

  getRandomString(length?: number): string {
    let str = '';
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_1234567890';
    if (isNaN(length)) {
      length = 10;
    }

    for (let i = 0; i < length; i++) {
      str += chars[Math.ceil(Math.random() * 100 % chars.length)];
    }

    return str;
  }

    /**
     * Get primary key name
     */
  public getPrimaryKeyName (): string {
    return this.primaryKey;
  }

  public getPrimaryKeyValue (): number {
    return this[this.getPrimaryKeyName()];
  }

  /**
   * create method
   * @param obj
   */
  public create(obj: any): BaseResourceInterface {
    const ret: BaseResourceInterface = Object.create(this);
    this.creatableAttributes.forEach((value: string, index: number) => {
      if (!isNullOrUndefined(obj[value])) {

        ret[value] = obj[value];
      }

    });
    if (ret[this.primaryKey]) {
      ret['htmlId'] = this.getRandomString() + '-' + ret[this.primaryKey];
    } else {
      ret['htmlId'] = this.getRandomString();
    }
    return ret;
  }

  public createFromAbstractCollectionObject (obj: {_items: Array<any>} | any) {
    return this.createFromCollection(obj._items);
  }

  /**
   * Create from collection
   * @param items
   */
  public createFromCollection(items: Collection<any>): Collection<BaseResourceInterface> {
    const ret: Collection<BaseResourceInterface> = new Collection<BaseResourceInterface>();
    const inst = Object.create(this);
    items.forEach((value: any, index: number) => {
      ret.push(inst.create(value));
    });
    return ret;
  }

  /**
   * Create from collection
   * @param body
   */
  public createFromPaginatedCollection(body: any): PaginatedCollection<BaseResourceInterface> {
    const ret = new PaginatedCollection<BaseResourceInterface>();
    ret.firstPageUrl = body.first_page_url;
    ret.from = body.from;
    ret.currentPage = body.current_page;
    ret.to = body.to;
    ret.lastPageUrl = body.last_page_url;
    ret.total = body.total;
    ret.nextPageUrl = body.next_page_url;
    ret.path = body.path;
    ret.perPage = body.per_page;
    ret.setItems(this.createFromCollection(body.data).all());
    return ret;
  }

}
