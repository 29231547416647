import {Injectable} from '@angular/core';
import {NicoHttp} from '../../../system/requests/NicoHttp';
import {map} from 'rxjs/operators';
import {ServerResponse} from '../../../system/responses/ServerResponse';
import {EnvironmentInterface} from '../../../system/datamodels/EnvironmentInterface';
import {environment} from '../../../../environments/environment';

@Injectable()
export class AdminToolbarService {
  protected environment: EnvironmentInterface;

  /**
   * Constructor
   * @param http
   */
  public constructor(protected http: NicoHttp) {
    this.environment = environment;
  }

  /**
   * Get user info
   */
}
