import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '../system/controllers/BaseComponent';
import {environment} from "../../environments/environment";
import {AuthService} from "../shared/auth/services/auth.service";

@Component({
  selector: 'admin-root',
  templateUrl: './public-module.component.html',
})
export class PublicModuleComponent extends BaseComponent implements OnInit{

  /**
   * The boolean to set if the sidebar is expanded or not
   * @type {boolean}
   */
  public flexCollapsed: boolean = false;

  public qrToggle: boolean = false;

    /**
     * Constructor
     * @param injector
     * @param authUserService
     */
  public constructor(injector: Injector, protected authUserService:  AuthService) {
    super(injector);
  }

  public ngOnInit () {
    super.ngOnInit();
    this.http.setNamespace(environment.public_namespace);
    /**
     * Event handler when an toolbar icon is clicked
     */
    if (this.appService.getSidenavCollapseEvent()) {
      this.appService.getSidenavCollapseEvent().subscribe((collapsed: boolean) => {
        this.flexCollapsed = collapsed;
      });
    }

  }

  toggle() {

    this.qrToggle = !this.qrToggle;
    
  }

  /**
   * Called when route params are initialized
   */
  public onRouteParamInitialized() {

  }
}
