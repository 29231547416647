import {Component, Injector, OnInit} from "@angular/core";
import {BaseComponent} from "../../../system/controllers/BaseComponent";
import {PublicIsbnsService} from "../../services/public-isbns.service";
import {AuthorsListComponent} from "../../../shared/authors-list-component/authors-list.component";
import {BaseIssuedISBN} from "../../../shared/base/contituents/isbn-application/models/BaseIssuedISBN";
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'public-isbn-show',
  templateUrl: './public-isbn-show.component.html'
})
export class PublicIsbnShowComponent extends BaseComponent  {
  public model = "";
  public constructor(injector: Injector, private meta: Meta) {
    super(injector);
    this.service = injector.get(PublicIsbnsService);
  }

  onRouteParamInitialized() {
    super.onRouteParamInitialized();
    const  spinner = this.ajaxSpinner.showSpinner();
    const subscription = this.service.find(this.routeParams.get('id'))
      .subscribe((item: BaseIssuedISBN) => {
        spinner.hide();
	this.model = <any>item;
	this.meta.addTags([
          { name: 'title', content: item.title  + 'published ISBN ' + item.issued_isbn.full_isbn_number_original },
          { name: 'description', content: 'TUCL Online ISBN Application published ISBN, ' + item.title },
          { name: 'keywords', content: item.issued_isbn.full_isbn_number_original  },
        ]);	
        subscription.unsubscribe();
      }, () => {
        spinner.hide();
        if (subscription) {
          subscription.unsubscribe();
        }
        this.router.navigate(['/published-isbns']);
      });
  }

}
