import {ModuleWithProviders, NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {PublicNavbarComponent} from "./components/nav-bar/public-navbar.component";

@NgModule({
    declarations: [
      PublicNavbarComponent
    ],
    imports: [
        SharedModule,
    ],
    entryComponents: [

    ],
  exports: [
    SharedModule,
    PublicNavbarComponent,
  ],
})
export class PublicCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: PublicCommonModule,
            providers: [
            ]
        };
    }
}
