
export class BreadcrumbsService {

    protected items:Array<Breadcrumb> = [];

    protected visible = true;

    setVisibility (visible:boolean) :void{
        this.visible = visible;
    }

    getVisibility ():boolean {
        return this.visible;
    }

    getItems():Array<BreadcrumbsMenuItem>{
        return this.items;
    }

    pushItem (item:BreadcrumbsMenuItem):Breadcrumb{
        let breadcrumb = new Breadcrumb(item);
        this.items.push(breadcrumb);
        return breadcrumb;
    }

    popItem (): Breadcrumb{
        return this.items.pop();
        /*this.items.forEach((val,index)=>{
            if(val===breadcrumb){
                this.items.splice(index,this.items.length);
            }
            return false;
        })*/
    }

}

export interface BreadcrumbsMenuItem {
    label:string;
    routerLink:Array<any>;
}

export class Breadcrumb implements BreadcrumbsMenuItem{
    public label:string;

    public routerLink:Array<any> = [];

    constructor(item:BreadcrumbsMenuItem){
        this.setItem(item);
    }
    protected setItem (item:BreadcrumbsMenuItem){
        this.label = item.label;
        this.routerLink = item.routerLink;
    }
    setBreadcrumb (item:BreadcrumbsMenuItem){
        this.setItem(item);
    }

    getLabel ():string{
        return this.label;
    }
    getRouterLink ():Array<any> {
        return this.routerLink;
    }
    updateLabel(label:string){
        this.label = label;
    }

    updateRouterLink(routerLink:Array<any>){
        this.routerLink = routerLink;
    }


}