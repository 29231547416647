import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'firstLetter'})
export class FirstLetterPipe implements PipeTransform {
  transform(input: string) {
    if (typeof input === 'undefined') {
      return '';
    }

    let output: string = '';
    const words = input.split(' ');
    output += words[0].charAt(0);
    output += words[words.length - 1].charAt(0);
    return output;
  }
}
