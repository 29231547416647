
import { Injectable, ComponentFactoryResolver, ViewContainerRef, Component, ComponentRef } from "@angular/core";
import { ViewControllerTypeEnum } from "./enums/ViewControllerTypeEnum";

@Injectable()

export class ViewController {
  /**
   * The view container ref
   */
  private vcr: ViewContainerRef;

  private resolver: ComponentFactoryResolver;

  /**
   * Set the view container
   * @param vRef
   */
  public setViewContainerRef(vRef: ViewContainerRef) {
    this.vcr = vRef;
  }

  public setComponentFactoryResolver(resolver: ComponentFactoryResolver) {
    this.resolver = resolver;
  }

  /**
   * Create Component
   * @param component
   * @return ComponentRef<any>
   */
  public create(component: any) {
    let factory = this.resolver.resolveComponentFactory(component);
    return <ComponentRef<any>>this.vcr.createComponent(factory);
  }





}
