import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AuthMiddleware} from '../shared/auth/middlewares/AuthMiddleware';
import {AdminModuleComponent} from './admin-module.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'manage', component: AdminModuleComponent, canActivate: [AuthMiddleware], children: [
          {
            path: '',
            loadChildren: './pages/dashboard/dashboard.module#DashboardModule',
          },
          {
            path: 'publishers',
            loadChildren: './pages/publishers/publishers.module#PublishersModule',
          },
          {
            path: 'isbn-applications',
            loadChildren: './pages/isbn-applications/isbn-application.module#IsbnApplicationModule',
          },
          {
            path: 'issued-isbns',
            loadChildren: './pages/isbn-issued/isbn-issued.module#IsbnIssuedModule',
          },
          {
            path: 'users',
            loadChildren: './pages/users/users.module#UsersModule',
          },
          {
            path: 'me',
            loadChildren: './pages/me/me.module#MeModule',
          },
          {
            path: 'notices',
            loadChildren: './pages/notice/notice.module#NoticeModule',
          },
        ]
      },
    ])
  ],
  exports: [RouterModule]
})
export class AdminRouteModule {
}
