import {NicoModalActionInterface} from "./NicoModalActionInterface";
import {FormGroup} from "@angular/forms";
import {BaseComponent} from "../../controllers/BaseComponent";
import {OnInit} from '@angular/core';
import {isNullOrUndefined} from "util";

export class AbstractNicoModalComponent extends BaseComponent implements NicoModalActionInterface , OnInit {
    protected dismisser: Function;

    public formId = "add-user-form";

    public data: any;

    public formGroup: FormGroup;

    public modalTitle = "Set modalTitle property";

    protected okayCompleteListener: Function;
    protected cancelCompleteListener: Function;

    public cancel () {
        this.dismisser ();
    }

    ngOnInit () {
        super.ngOnInit();
        this.oldPageTitle = this.pageTitle.getTitle();
    }

    /**
     * @Override
     */
    public canDismissNicoModalOnCancel() {
        return true;
    }

    /**
     * @Override
     */
    public canDismissNicoModalOnOkay() {
        return true;
    }

    /**
     * @Override
     */
    public onNicoModalCancelClick() {

    }

    /**
     * @Override
     */
    public onNicoModalClose() {
      if (!isNullOrUndefined(this.oldPageTitle)) {
        this.setPageTitle(this.oldPageTitle);
      }
    }

    /**
     * @Override
     */
    public onNicoModalOkayClick() {

    }

    /**
     * @Override
     */
    public setCancelProcessCompleteListener(fn: Function) {
        this.cancelCompleteListener = fn;
    }

    /**
     * @Override
     */
    public setNicoModalChildData(data) {
        this.data = data;
    }

    /**
     * @Override
     */
    public setNicoModalDismisser(fn: Function) {
        this.dismisser = fn;
    }

    /**
     * @Override
     */
    public setOkayProcessCompleteListener(fn: Function) {
        this.okayCompleteListener = fn;
    }


}
