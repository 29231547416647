import {Component, Injector, ViewContainerRef} from '@angular/core';
import {ConfirmConfigInterface} from './shared/confirm-dialog/confirm-config.interface';
import {ConfirmDialogComponent} from './shared/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {AppService} from './app.service';
import {BaseComponent} from './system/controllers/BaseComponent';
import {NicoModalComponent} from './system/components/modal/NicoModal';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent {
  title = 'app';

  constructor(translate: TranslateService, injector: Injector, vcr: ViewContainerRef, protected appService: AppService) {
    super(injector);
    this.appService.setAppComponent(this);
    this.pageTitle.showAddOn(true);
    this.pageTitle.setPageTitleAddOn(environment.app_name);
    this.nicoCtrl.getViewController().setViewContainerRef(vcr);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  /**
   * Show confirm dialog box
   * @param {ConfirmConfigInterface} confirmConfig
   */
  public showConfirmDialog(confirmConfig: ConfirmConfigInterface): NicoModalComponent {
    const modal = this.nicoCtrl.create(ConfirmDialogComponent, {
      modalTitle: confirmConfig.title ? this.translate.get(<any>confirmConfig.title) : this.translate.get('mod_commons.confirm_title'),
      showCrossButton: false,
      cancelLabel: confirmConfig.cancelLabel ? confirmConfig.cancelLabel : this.translate.get('mod_commons.cancel_label'),
      okayLabel: confirmConfig.confirmLabel ? confirmConfig.confirmLabel : this.translate.get('mod_commons.confirm_label'),
      showModalFooter: true,
      htmlTemplateAsIs: false,
      onOkayClick: confirmConfig.onConfirm,
      onCancelClick: confirmConfig.onCancel,
      modalClass: 'confirm-dialog',
      data: {message: confirmConfig.message ? this.translate.get(confirmConfig.message) : this.translate.get('mod_commons.delete_confirmation_message')}
    });
    modal.present();
    return modal;
  }
}
