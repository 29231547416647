import {isNullOrUndefined} from 'util';


export enum AjaxSpinnerEnum {
  Cube = '<div class=\'cssload-thecube\'><div class=\'cssload-cube cssload-c1\'></div><div class=\'cssload-cube cssload-c2\'></div><div class=\'cssload-cube cssload-c4\'></div><div class=\'cssload-cube cssload-c3\'></div> </div>',
  WhirlPool = '<div class=\'cssload-container\'><div class=\'cssload-whirlpool\'></div></div>',
  DancingLine = '<div class=\'cssload-container-dancing-line\'></div>',
  FloatingBar = '<div id="floatingBarsG"><div class="blockG" id="rotateG_01"></div><div class="blockG" id="rotateG_02"></div><div class="blockG" id="rotateG_03"></div><div class="blockG" id="rotateG_04"></div><div class="blockG" id="rotateG_05"></div><div class="blockG" id="rotateG_06"></div><div class="blockG" id="rotateG_07"></div><div class="blockG" id="rotateG_08"></div></div>',
  TinyFloatingBar = '<div id="floatingBarsG" class="tiny-floating-bar"><div class="blockG" id="rotateG_01"></div><div class="blockG" id="rotateG_02"></div><div class="blockG" id="rotateG_03"></div><div class="blockG" id="rotateG_04"></div><div class="blockG" id="rotateG_05"></div><div class="blockG" id="rotateG_06"></div><div class="blockG" id="rotateG_07"></div><div class="blockG" id="rotateG_08"></div></div>',
  MaterialSpinner = '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
}


export class AjaxSpinner {
  /**
   * Spinner Array
   *  Array
   */
  protected spinners: Array<Spinner> = [];

  /**
   * Show spinner
   * @return Spinner
   */
  public showSpinner(container?: string, type?: AjaxSpinnerEnum): Spinner {
    const spinner: Spinner = new Spinner(container, type);
    spinner.show();
    this.spinners.push(spinner);
    return spinner;
  }

  public hideAll() {
    for (const i in this.spinners) {
      this.spinners[i].hide();
    }
  }

}

export class Spinner {
  protected element: HTMLDivElement;

  protected _html: AjaxSpinnerEnum;
  protected parentContainer: HTMLElement = null;

  /**
   * COnstructor
   * @param container
   * @param type
   */
  constructor(container?: string, type?: AjaxSpinnerEnum) {

    if (container) {
      this.parentContainer = document.getElementById(container);
    }

    if (!this.parentContainer) {
      this.parentContainer = document.body;
    }
    let className: string = this.parentContainer.className || '';
    className = (className.replace('cssload-parent', '')).trim();
    this.parentContainer.className = className + ' cssload-parent';
    this._html = type;
    if (isNullOrUndefined(this._html)) {
      this._html = AjaxSpinnerEnum.MaterialSpinner;
    }
  }

  private createSpinner() {

    this.element = document.createElement('div');
    this.element.className = 'cssload-overlay';
    this.element.innerHTML = this._html;

    this.parentContainer.appendChild(this.element);

  }

  public show() {
    this.createSpinner();
  }

  public hide() {

    if (this.element) {
      if(this.element.remove){
        this.element.remove();
      }else {
        this.parentContainer.removeChild(this.element);
      }
      let className: string = this.parentContainer.className || '';
      className = (className.replace('cssload-parent', '')).trim();
      this.parentContainer.className = className;
    }
    this.element = null;
  }
}
