import {AbstractBaseResource} from "../../../../../system/datamodels/AbstractBaseResource";
import {BaseIsbnApplicationInterface} from "../interfaces/BaseIsbnApplicationInterface";
import {BasePublisher} from "../../publisher/models/BasePublisher";
import * as moment from "moment";
import {BasePublisherPrefix} from "../../publisher/models/BasePublisherPrefix";
import {environment} from "../../../../../../environments/environment";
import {Helper} from "../../../../../system/utilities/Helper";

export class BaseIssuedISBN  extends AbstractBaseResource implements BaseIsbnApplicationInterface  {
    public id: number;
    /**
     * The primary key identifier
     * @type {string}
     */
    primaryKey: string = "id";
    /**
     * The creatable Attributes of the institutions
     */
    creatableAttributes: Array<string> = [
      'isbn_number', 'issued_date', 'id', 'readonly', 'base64_barcode', 'full_isbn_number'
    ];

    create(obj: any): BaseIssuedISBN {
      const ret =  <BaseIssuedISBN> super.create(obj);
      if (obj.issued_date) {
        ret.issued_date = moment(obj.issued_date);
      }
      ret.full_isbn_number_original = ret.full_isbn_number
      if (obj.publisher_prefix) {
        ret.publisher_prefix = (new BasePublisherPrefix()).create(obj.publisher_prefix);
	ret.full_isbn_number_original = ret.full_isbn_number;
        ret.full_isbn_number = `${(ret.publisher_prefix) ? ret.publisher_prefix.country_prefix:'000'}-${ret.publisher_prefix.prefix}-${ret.isbn_number}-`
          + Helper.getISBNMod13CheckDigit(ret.publisher_prefix.country_prefix, ret.publisher_prefix.prefix, ret.isbn_number);
	  let sss = ret.publisher_prefix.country_prefix;
	  console.log('-------------------');  
	  console.log(sss.length);
    console.log(ret.full_isbn_number);	
	  }


      if (obj.publisher) {
        ret.publisher = (new BasePublisher()).create(obj.publisher);
      }
      return ret;
    }


}
