import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {PublicModuleComponent} from './public-module.component';
import {PageNotFoundComponent} from '../shared/page-not-found/page-not-found.component';
import {PublicPublishersComponent} from "./pages/publishers/public-publishers.component";
import {HomeComponent} from "./pages/home/home.component";
import {PublicIsbnsComponent} from "./pages/isbns/public-isbns.component";
import {PublicIsbnShowComponent} from "./pages/isbn-show/public-isbn-show.component";
import {PublicNoticeComponent } from './pages/news/public-notice.component';


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '', component: PublicModuleComponent, children: [
          {
            path: '',
            component: HomeComponent
          },
          {
            path: 'publishers',
            component: PublicPublishersComponent
          },
          {
            path: 'published-isbns',
            component: PublicIsbnsComponent
          },
          {
            path: 'published-isbns/:id/show',
            component: PublicIsbnShowComponent
          },
          {
            path: 'news',
            component: PublicNoticeComponent
          }
        ]
      },
      {
        path: '404',
        component: PageNotFoundComponent
      },
      {
        path: '**',
        redirectTo: '404'
      }
    ])
  ],
  exports: [RouterModule]
})
export class PublicModuleRoute {
}
