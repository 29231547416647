import {Component, EventEmitter, Injector, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {BaseComponent} from '../../../system/controllers/BaseComponent';
import {AdminSideNavbarService} from './admin-side-navbar.service';
import {Collection} from '../../../system/utilities/Collection';
import {AdminSidebarMenuItemInterface} from './admin-sidebar-menu-item.interface';
import {isNullOrUndefined} from 'util';
import {Subscription} from "rxjs";

@Component({
  selector: 'admin-side-navbar',
  templateUrl: './admin-side-navbar.component.html',
  styleUrls: ['./admin-side-navbar.component.scss'],
})
export class AdminSideNavbarComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Menu items that are shown on the sidebars
   */
  public menuItems: Collection<AdminSidebarMenuItemInterface>;

  public content: TemplateRef<any>;

  public collapsed: boolean = false;

  protected userUpdateSubscription: Subscription;

  /**
   * Constructor
   * @param injector
   * @param adminSideNavbar
   */
  constructor(injector: Injector, protected adminSideNavbar: AdminSideNavbarService) {
    super(injector);
    this.menuItems = new Collection();
  }

  protected loadMenuItems () {
    this.userUpdateSubscription = this.adminSideNavbar.getMenuItems(true).subscribe((items: Collection<AdminSidebarMenuItemInterface>) => {
      this.menuItems.setItems(items.all());
    });
  }
  /**
   * On init hook
   */
  ngOnInit() {
    super.ngOnInit();
    this.loadMenuItems();
    this.userUpdateSubscription = this.session.authUserUpdate().subscribe(() => {
      this.loadMenuItems();
    });


    this.adminSideNavbar.getTemplate().subscribe((template: TemplateRef<any>) => {
      this.content = template;
      this.collapsed = false;
      this.appService.fireSidenavCollapseEvent(this.collapsed);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.userUpdateSubscription.unsubscribe();
  }

  /**
   * go back
   * @param event
   */
  public onBackArrowClick(event: MouseEvent) {
    event.stopPropagation();
  }

  /**
   * emit collapsed state
   * @param $event
   */

  public toggleSideNav($event) {
    $event.preventDefault();
    if (isNullOrUndefined(this.content)) {
      this.collapsed = !this.collapsed;
      this.appService.fireSidenavCollapseEvent(this.collapsed);
    }
  }
}
