import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'cmp-paginator',
  templateUrl: 'paginator.component.html'
})

export class PaginatorComponent implements OnInit, OnChanges {

  @Input() offset: number;
  /**
   * Per page limit
   */
  @Input() limit: number;
  /**
   * Total data
   */
  @Input() size: number;

  @Input() range: number = 1;
  /**
   * Current page
   */
  @Input() currentPage: number;

  /**
   * lazy loading option
   */
  @Input() lazyLoading: boolean = false;

  protected pageWindowSize = 7;

  protected startIndex = 1;

  /**
   * Total pages
   */
  public totalPages: number;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  pages: number[];

  constructor() {
  }

  ngOnInit() {
    this.getPages();
  }

  ngOnChanges() {
    this.getPages();
  }

  getPages() {
    this.totalPages = Math.ceil(this.size / this.limit);
    if(isNaN(this.totalPages)){
      this.totalPages = 0;
    }

    if( this.totalPages < this.pageWindowSize ) {
      this.pages = new Array(this.totalPages);
    } else {
      this.pages = new Array(this.pageWindowSize + 1);
      this.startIndex = Math.floor(this.currentPage / this.pageWindowSize) * (this.pageWindowSize);

      if(this.totalPages - this.currentPage <= this.pageWindowSize) {
        this.startIndex = this.totalPages - this.pageWindowSize;
      }

    }

  }

  selectPage(page: number) {
    if (this.isValidPageNumber(page, this.totalPages)) {
      this.pageChange.emit(page);
    }
  }

  isValidPageNumber(page: number, totalPages: number): boolean {
    return page > 0 && page <= totalPages;
  }

}
