import {Component, Input, Injector} from '@angular/core';

@Component({
    selector: 'nico-acronym',
    template: `<span class="acronym">{{acronymizeWords()}}</span>`
})
export class NicoAcronymComponent {
    /**
     * Take input from element
     */
    @Input('words') words: string;

    @Input('limit') limit: number = 0;

    /**
     * The acronym of the word
     */
    public acronym: string;

    /**
     * Acronymize the words
     */
    public acronymizeWords() {
        //split the words first
        if (!this.words) {
            return '';
        }
        if (!this.limit) {
            this.limit = 1;
        }
        let strings = this.words.split(' ');
        this.acronym = '';
        for (let i = 0; i < this.limit; i++) {
            this.acronym += strings[i].toUpperCase().charAt(0);
        }
        return this.acronym;
    }

}
