import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Component, OnInit, forwardRef, Input } from '@angular/core';

@Component({
    selector: 'adv-checkbox',
    templateUrl: 'adv-checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AdvCheckboxComponent),
            multi: true
        }
    ]
})

export class AdvCheckboxComponent implements OnInit, ControlValueAccessor {
    @Input() label;
    @Input('value') _value = 'single';
    onChange: any = () => { };
    onTouched: any = () => { };

    get value() {
        return this._value;
    }

    set value(val) {
        this._value = val;
        this.onChange(val);
        this.onTouched();
    }

    constructor() { }

    ngOnInit() { }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value) {
        if (value) {
            this.value = value;
        }
    }

    switch() {
        if (this.value === 'multiple') {
            this.value = 'single';
        } else if (this.value === 'single') {
            this.value = 'multiple';
        }
        // this.value = !this.value;
    }
}
