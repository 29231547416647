import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AdvCheckboxComponent } from './adv-checkbox/adv-checkbox.component';
import { AuthMiddleware } from './auth/middlewares/AuthMiddleware';
import { GuestMiddleware } from './auth/middlewares/GuestMiddleware';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NicoAcronymComponent } from '../system/components/nico-acronym/nico-acronym.component';
import { PaginatorComponent } from './pagination/paginator.component';
import { UploadFileComponent } from './upload-file-component/upload-file.component';
import { IconPickerModule } from 'ngx-icon-picker';
import { environment } from '../../environments/environment';
import { User } from './auth/models/User';
import { SystemModule } from '../system/system.module';
import { NgxUploaderModule } from 'ngx-uploader';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { ActionFlyMenuComponent } from '../system/components/action-fly-menu/action-fly-menu.component';
import { OnScrollDirective } from './directives/on-scroll.directive';
import { ColorPickerModule } from 'ngx-color-picker';
import { BaseUserService } from './services/base-user.service';
import { UserSelectInputComponent } from './user-select-input/user-select-input.component';
import { NewUserComponent } from './new-user-component/new-user.component';
import { NgDragDropModule } from 'ng-drag-drop';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ContactTypePipe } from './pipes/contact-type.pipe';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { OnResizeDirective } from './directives/on-resize.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AnalyticsKeysPipe } from './pipes/analytics-keys.pipe';
import { HMSPipe } from './pipes/hms.pipe';
import { UploadMultipleComponent } from './upload-component/upload-multiple.component';
import {PreviewImageComponent} from "./preview-image-component/preview-image.component";
import {AppLanguageProviderService} from "./services/app-language-provider.service";
import {PermissionGroupListComponent} from "./groups-permissions/permission-group-list/permission-group-list.component";
import {IsbnAppTypePipe} from "./pipes/isbn-app-type.pipe";
import {ColorIllusPipe} from "./pipes/color-illus.pipe";
import {IsbnIllusTypePipe} from "./pipes/isbn-illus.type.pipe";
import {IsbnIssueModalComponent} from "./isbn-issue-modal/isbn-issue-modal.component";
import {IsbnApplicationService} from "./services/isbn-application.service";
import {RecaptchaV3Module} from "ng-recaptcha";
import {AuthorsListComponent} from "./authors-list-component/authors-list.component";
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';


/**
 * Do not specify providers for admin-module that might be imported by a lazy loaded module.
 */
export function userFactory() {
  return new User();
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxUploaderModule,
    ReactiveFormsModule,
    HttpModule,
    SystemModule.forRoot(environment, userFactory),
    IconPickerModule,
    ColorPickerModule,
    NgScrollbarModule,
    NgDragDropModule.forRoot(),
    DragDropModule,
    RecaptchaV3Module,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  declarations: [
    PaginatorComponent,
    NavbarComponent,
    NicoAcronymComponent,
    UploadFileComponent,
    ConfirmDialogComponent,
    AdvCheckboxComponent,
    TruncateTextPipe,
    ContactTypePipe,
    HMSPipe,
    OnScrollDirective,
    OnResizeDirective,
    ActionFlyMenuComponent,
    NewUserComponent,
    UserSelectInputComponent,
    PageNotFoundComponent,
    AnalyticsKeysPipe,
    UploadMultipleComponent,
    PreviewImageComponent,
    PermissionGroupListComponent,
    IsbnAppTypePipe,
    ColorIllusPipe,
    IsbnIllusTypePipe,
    IsbnIssueModalComponent,
    AuthorsListComponent,
  ],
  providers: [
    BaseUserService, AppLanguageProviderService, IsbnApplicationService
  ],
  entryComponents: [
    ConfirmDialogComponent,
    NewUserComponent,
    PreviewImageComponent,
    IsbnIssueModalComponent,
    AuthorsListComponent
  ],
  exports: [
    PaginatorComponent,
    NavbarComponent,
    NicoAcronymComponent,
    UploadFileComponent,
    AdvCheckboxComponent,
    ConfirmDialogComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconPickerModule,
    RouterModule,
    SystemModule,
    TranslateModule,
    ColorPickerModule,
    NgxUploaderModule,
    DragDropModule,
    TruncateTextPipe,
    ContactTypePipe,
    HMSPipe,
    OnScrollDirective,
    OnResizeDirective,
    ActionFlyMenuComponent,
    UserSelectInputComponent,
    NewUserComponent,
    NgDragDropModule,
    NgScrollbarModule,
    PageNotFoundComponent,
    AnalyticsKeysPipe,
    UploadMultipleComponent,
    PermissionGroupListComponent,
    IsbnAppTypePipe,
    IsbnIllusTypePipe,
    ColorIllusPipe,
    RecaptchaV3Module,
    AuthorsListComponent,
    NgMultiSelectDropDownModule,
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        AuthMiddleware,
        GuestMiddleware
      ]
    };
  }
}
