import {Observable, Subscription} from 'rxjs';
import {AdminSidebarMenuItemInterface} from './admin-sidebar-menu-item.interface';
import {Collection} from '../../../system/utilities/Collection';
import {EventEmitter, Injectable, TemplateRef} from '@angular/core';
import {PermissionTypeEnum} from '../../../shared/enums/permission-type.enum';
import {NicoSession} from "../../../system/services/NicoSession";
import {BaseUser} from "../../../shared/base/contituents/user/models/BaseUser";
@Injectable()
export class AdminSideNavbarService {

  protected menuItems: Collection<AdminSidebarMenuItemInterface>;

  protected menutItemChanges: EventEmitter<Collection<AdminSidebarMenuItemInterface>>;

  protected templateObserver: EventEmitter<TemplateRef<any>>;

  protected currentTemplate;

  public constructor(protected session: NicoSession) {
    this.menuItems = this.getDefaultMenuItems();
    this.menutItemChanges = new EventEmitter();
    this.templateObserver = new EventEmitter();


  }
  /**
   * Get default menu item
   */
  protected getDefaultMenuItems(): Collection<AdminSidebarMenuItemInterface> {
    const authUser: BaseUser = <BaseUser>this.session.getAuthUser();
    const permissionGrpId = authUser ? (authUser.permissionGroups.all()[0]).perm_grp_id : null ;
    const admin = [1,2];


    return new Collection<AdminSidebarMenuItemInterface>([
      {
        routerLink: 'publishers',
        title: 'mod_sidebar.publishers_label',
        icon: 'fas fa-atlas',
        active: false,
        visible: authUser ? authUser.can('publishers.read') : false
      },
      {
        routerLink: 'isbn-applications',
        title: 'mod_sidebar.isbn_application_label',
        icon: 'fas fa-mail-bulk',
        active: false,
        visible: authUser ? authUser.can('isbn_applications.read') : false
      },

    {
        routerLink: 'issued-isbns',
        title: 'mod_sidebar.issued_isbn_label',
        icon: 'fas fa-book',
        active: false,
        visible: authUser ? authUser.can('isbn.read') : false
      },

       {
       routerLink: 'users',
       title: 'mod_sidebar.users_label',
       icon: 'fa fa-user',
       active: false,
       visible: authUser ? authUser.can('users.create') : false
     },

     {
      routerLink: 'publishers/list-request-publisher',
      title: 'View Publisher Request',
      icon: 'fa fa-list-alt',
      active: false,
      visible: admin.includes(permissionGrpId)
    },
    {
      routerLink: 'publishers/list-request-owner',
      title: 'View Publisher Owner  Request',
      icon: 'fa fa-list',
      active: false,
      visible: admin.includes(permissionGrpId)
    },
    {
      routerLink: 'publishers/request-publisher',
      title: 'Request Add Publisher',
      icon: 'fa fa-user',
      active: false,
      visible: !admin.includes(permissionGrpId)
    },
    {
      routerLink: 'publishers/publisher-owner',
      title: 'Request Publisher Ownership',
      icon: 'fa fa-book',
      active: false,
      visible: !admin.includes(permissionGrpId)
    },
    {
      routerLink: 'notices',
      title: 'View Notices',
      icon: 'fa  fa-sticky-note ',
      active: false,
      visible: admin.includes(permissionGrpId)
    }
    ]);
  }

  /**
   * Get menu items
   */
  public getMenuItems(reloaded?: boolean): Observable<Collection<AdminSidebarMenuItemInterface>> {
    setTimeout(() => {
      if (reloaded) {
        this.menuItems = this.getDefaultMenuItems();
      }
      this.menutItemChanges.emit(this.menuItems);
    }, 10);

    return this.menutItemChanges;
  }

  /**
   * Set the menu items. If null is given, the menu item is reverted back to defaults
   * @param items
   */
  public setMenuItems(items?: Collection<AdminSidebarMenuItemInterface>) {
    if (!items) {
      items = this.getDefaultMenuItems();
    }
    this.menutItemChanges.emit(items);
  }

  /**
   * Get the template
   */
  public getTemplate() {
    return this.templateObserver;
  }

  /**
   * Set template
   * @param content
   */
  public setTemplate(content: TemplateRef<any>) {
    this.currentTemplate = content;
    this.templateObserver.emit(content);
  }

  public getCurrentTemplate() {
    return this.currentTemplate;
  }


}

