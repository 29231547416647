import {Component, EventEmitter, Injector, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';

import {BaseComponent} from '../../../system/controllers/BaseComponent';
import {NicoSession} from '../../../system/services/NicoSession';
import {AuthenticableInterface} from '../../../system/datamodels/AuthenticableInterface';
import {User} from '../../../shared/auth/models/User';
import {AdminToolbarService} from './admin-toolbar.service';

/**
 * This class represents the toolbar component.
 */
@Component({
  //moduleId: module.id,
  selector: 'admin-toolbar',
  templateUrl: 'admin-toolbar.component.html',
  styleUrls: ['admin-toolbar.component.scss'],
  providers: [AdminToolbarService],
})
export class AdminToolbarComponent extends BaseComponent implements OnInit, OnDestroy {


  protected baseUserChangeSubscription: Subscription;

  /**
   * The event emitter for dashboard icon click
   */
  @Output('dashboardIconClick') dashboardIconClick: EventEmitter<null>;

  /**
   * Constructor
   * @param injector
   * @param session
   * @param toolbarService
   */
  public constructor(injector: Injector, protected session: NicoSession, protected toolbarService: AdminToolbarService) {
    super(injector);
    this.dashboardIconClick = new EventEmitter();
  }

  ngOnInit() {
    super.ngOnInit();
    this.baseUserChangeSubscription = this.session.authUserUpdate().subscribe((d: AuthenticableInterface) => {
      this.authUser = <User>d;
    });
  }

  ngOnDestroy() {
    this.baseUserChangeSubscription.unsubscribe();
  }

  public onIconBarClick() {
    this.dashboardIconClick.emit();
  }

  public getUserImage(): string {
    return this.authUser.thumb_url;
  }

}
