import {Injectable, ComponentRef, Component} from "@angular/core";
import { ViewController } from "../../controllers/ViewController";
import {NicoEmptyModalComponent, NicoModalComponent} from "./NicoModal";
import { NicoModalConfig } from "./NicoModalConfig";

@Injectable()
export class NicoModalController {

  private modelComponent: any;

  private plainComponent: any;


  /**
   * The view Controller
   * @param viewController
   */
  constructor(private viewController: ViewController) {
    this.modelComponent = NicoModalComponent;
    this.plainComponent = NicoEmptyModalComponent;
  }
  public getViewController() {
    return this.viewController;
  }
  /**
   * Attach a component
   * @param cmpt
   * @param options
   * @returns NicoModalComponent
   */
  public create(cmpt: any, options?: NicoModalConfig): NicoModalComponent | NicoEmptyModalComponent {
    let component: ComponentRef<any> = null;
    if (options.htmlTemplateAsIs === true) {
        component = this.viewController.create(this.plainComponent);
    } else {
        component = this.viewController.create(this.modelComponent);
    }
    component.instance.setConfig(options);
    component.instance.setBody(cmpt, options.data);
    component.instance.setComponentRef(component);
    return component.instance;
  }

}