import {ModuleWithProviders, NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {AdminSideNavbarService} from "./components/admin-side-navbar/admin-side-navbar.service";
import {AdminSideNavbarComponent} from "./components/admin-side-navbar/admin-side-navbar.component";

@NgModule({
    declarations: [
        AdminSideNavbarComponent,
    ],
    imports: [
        SharedModule,
    ],
    entryComponents: [

    ],
    exports: [
        SharedModule, AdminSideNavbarComponent,
    ],
})
export class AdminCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AdminCommonModule,
            providers: [
                AdminSideNavbarService
            ]
        };
    }
}
