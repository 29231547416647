import {Pipe, PipeTransform} from '@angular/core';
import {IsbnIllustrationTypeEnum} from "../base/contituents/isbn-application/enums/isbn-illustration-type.enum";

@Pipe({
  name: 'isbnIllusType'
})
export class IsbnIllusTypePipe implements PipeTransform {

  transform(value: IsbnIllustrationTypeEnum): string {
    switch (value) {
      case IsbnIllustrationTypeEnum.Chart:
        return "chart";
      case IsbnIllustrationTypeEnum.None:
        return "None";
      case IsbnIllustrationTypeEnum.Map:
        return "Map";
      case IsbnIllustrationTypeEnum.Tables:
        return "Tables";
    }
    return "Unknown";
  }
}
