import {BaseService} from "../../system/services/BaseService";
import {Collection} from "../../system/utilities/Collection";
import {RequestOptionsArgs} from "@angular/http";
import {EventEmitter, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {NicoHttp} from "../../system/requests/NicoHttp";
import {environment} from "../../../environments/environment";
@Injectable()
export class AppLanguageProviderService extends BaseService {
  protected resource: any;
  protected resourceBaseUrl: string;
  protected resourceName: string;
  /**
   * Constructor
   * @param http
   */
  public constructor(protected http: NicoHttp) {
    super(http, environment);
  }
  public get(options?: RequestOptionsArgs, showToast?: boolean): Observable<Collection<{value: string, label: string}>> {
    const observable: EventEmitter<Collection<{value: string, label: string}>> = new EventEmitter();
    setTimeout( () => {
      observable.emit( new Collection([
        {value: 'Nepali', label: 'Nepali'},
        {value: 'English', label: 'English'},
        {value: 'Hindi', label: 'Hindi'},
        {value: 'Newari', label: 'Newari'},
        {value: 'Maithali', label: 'Maithali'},
        {value: 'Bhojpuri', label: 'Bhojpuri'},
        {value: 'Tharu', label: 'Tharu'},
        {value: 'Tamang', label: 'Tamang'},
        {value: 'Rai', label: 'Rai'},
        {value: 'Magar', label: 'Magar'},
        {value: 'Limbu', label: 'Limbu'},
        {value: 'Awadhi', label: 'Awadhi'},
        {value: 'Russian', label: 'Russian'},
        {value: 'Chinese', label: 'Chinese'},
        {value: 'Spanish', label: 'Spanish'},
        {value: 'Tibetan', label: 'Tibetan'},
        {value: 'French', label: 'French'},
      ]));
    }, 10);
    return observable;
  }

}
