import {NgModule} from '@angular/core';

import {BrandLogoUrlPipe} from './brandLogoUrl.pipe';
import {DateFormatPipe} from './date-format.pipe';
import {FirstLetterPipe} from './firstLetter.pipe';
import {SafePipe} from './safe.pipe';
import {StatusPipe} from "./status.pipe";
import {StatusToggleCommandPipe} from "./status-toggle-command.pipe";
import {ResourceUrlPipe} from "./resource-url.pipe";
import {LanguagePipe} from "./language.pipe";

/**
 * Created by Amar on 2/5/2017.
 */
@NgModule({
    imports: [],
    declarations: [FirstLetterPipe, BrandLogoUrlPipe, DateFormatPipe, SafePipe, StatusPipe, StatusToggleCommandPipe, ResourceUrlPipe, LanguagePipe],
    exports: [FirstLetterPipe, BrandLogoUrlPipe, DateFormatPipe, SafePipe, StatusPipe, StatusToggleCommandPipe, ResourceUrlPipe, LanguagePipe],
    providers: []
})
export class PipesModule {
}
