import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {GuestMiddleware} from './middlewares/GuestMiddleware';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {LogoutComponent} from './components/logout/logout.component';
import {AuthComponent} from './auth.component';
import {PasswordUpdateComponent} from "./components/password-update/password-update.component";
import {RegisterComponent} from "./components/register/register.component";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'auth', component: AuthComponent, children: [
                    {path: '', redirectTo: 'login', pathMatch: 'full'},
                    {path: 'register', component: RegisterComponent, canActivate:[GuestMiddleware]},
                    {path: 'login', component: LoginComponent, canActivate: [GuestMiddleware]},
                    {path: 'logout', component: LogoutComponent, },
                    {path: 'password/update', component: PasswordUpdateComponent, },
                    {path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [GuestMiddleware]},
                    {path: 'password/reset/:token', component: ResetPasswordComponent, canActivate: [GuestMiddleware]}
                ]
            },

        ])
    ],
    exports: [RouterModule]
})
export class AuthRoutingComponent {
}
