import {Component, OnInit, OnDestroy, Injector} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from "../../../../system/controllers/BaseComponent";
import {NicoModalComponent} from "../../../../system/components/modal/NicoModal";
import {Spinner} from "../../../../system/services/AjaxSpinner";
import {ServerResponse} from "../../../../system/responses/ServerResponse";

/**
 * This class represents the lazy loaded HomeComponent.
 */
@Component({
    //moduleId: module.id,
    selector: 'forgot-password',
    templateUrl: 'forgot-password.component.html',
    styleUrls: [],

})
export class ForgotPasswordComponent extends BaseComponent implements OnInit, OnDestroy {
    /**
     * The submitForm form
     * @type FormGroup
     */
    public cmptForm: FormGroup;
    /**
     * The Login error message
     * @type string
     */
    public loginErrorMessage: string;
    /**
     * Is submitForm submit busy
     * @type {boolean}
     */
    public formSubmitting: boolean = false;

    public comp: NicoModalComponent;


    public constructor(inject: Injector,
                       protected authService: AuthService ) {
        super(inject);
    }

    /**
     * Ng On init implemented method
     */
    public ngOnInit() {
        super.ngOnInit();
        this.setPageTitle('mod_forgot_password.module_title');
        this.initForm();
    }

    /**
     * On onDestroy life cycle message
     */
    public ngOnDestroy() {
    }

    /**
     * Init the submitForm form
     */
    private initForm() {
        this.cmptForm = this.formBuilder.group({
            email: ['', [Validators.compose([Validators.required])]]
        });
    }

    /**
     * Login
     */
    public submitForm(event: Event) {
        event.preventDefault();
        if (this.formSubmitting) {
            return;
        }
        this.formSubmitting = true;
        const spinner: Spinner = this.ajaxSpinner.showSpinner();
        this.authService.forgotPassword(this.cmptForm.value)
            .subscribe((d: ServerResponse) => {
                this.formSubmitting = false;
                spinner.hide();
                this.cmptForm.reset();
                this.showSuccessToast('mod_forgot_password.reset_email_sent_message');
            }, (d: ServerResponse) => {
                this.formSubmitting = false;
                if (d.status.statusCode === 417) {
                    this.validationMessenger.attach('cmpntForm', d.body);
                }
                spinner.hide();
            });
    }


}
