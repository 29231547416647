import {
    Component, OnDestroy, forwardRef, Input, Output, OnChanges, EventEmitter,
    ElementRef, OnInit,
} from '@angular/core';
import {FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {NicoHttp} from '../../requests/NicoHttp';
import {StatusOptions} from "../../enums/StatusOptions";

@Component({
    selector: 'nico-status-input',
    template: `
        <label [for]="id" class="form-control-label" *ngIf="labelVisible!==false">{{'mod_commons.status_label'|translate}}</label>
        <select [id]="id" class="custom-select" (change)="selectItem($event)">
            <option [value]="i" *ngFor="let i of statusOptions" [selected]="value == i">{{(i|status)|translate}}</option>
        </select>
    `,
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NicoStatusInputComponent), multi: true},
    ],
})
export class NicoStatusInputComponent implements ControlValueAccessor, OnChanges, OnDestroy , OnInit {

    /**
     * The input
     *  {string}
     */

    onTouched: () => void;
    /**
     * The text to be displayed in the input
     *  {string}
     */
    public text: string = '';

    /**
     * Input value
     *  {number}
     *
     */
    @Input('value') _value = {};
    /**
     * The on select event
     */
    @Output() change: EventEmitter<any>;

    @Input() id: string;

    @Input() labelVisible: boolean;


    public statusOptions: StatusOptions = new StatusOptions();

    /**
     *  function
     */
    propagateChange: any = () => {
    }
    /**
     *  function
     */
    validateFn: any = () => {
    }

    constructor(protected http: NicoHttp, protected elemRef: ElementRef) {

        this.change = new EventEmitter();


    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }


    get value() {
        return this._value;
    }

    set value(val) {
        this._value = val;
        this.propagateChange(val);
    }

    ngOnChanges(value) {
    }

    writeValue(value: any) {
        this.value = value;
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => any): void {
        this.onTouched = fn;
    }


    selectItem($event) {
        this.value = $event.currentTarget.options[$event.currentTarget.options.selectedIndex].value;
        this.change.emit($event);
    }


    onBlur() {
        this.onTouched();
    }

    setDisabledState(isDisabled: boolean): void {

    }

}
