import {BaseUserInterface} from '../interfaces/UserBaseInterface';
import {AuthenticableInterface} from '../../../../../system/datamodels/AuthenticableInterface';
import {AbstractBaseResource} from '../../../../../system/datamodels/AbstractBaseResource';
import {BasePermissionGroup} from "../../uac/models/BasePermissionGroup";
import {Collection} from "../../../../../system/utilities/Collection";
import {BasePermissionGroupInterface} from "../../uac/interfaces/BasePermissionGroupInterface";
import {group} from "@angular/animations";
import {isNullOrUndefined} from "util";

export class BaseUser extends AbstractBaseResource implements BaseUserInterface, AuthenticableInterface {
  public id: number;
  /**
   * The primary key identifier
   * @type {string}
   */
  primaryKey: string = 'id';
  /**
   * The creatable Attributes of the institutions
   */
  creatableAttributes: Array<string> = ['id', 'first_name', 'last_name', 'middle_name', 'image_url', 'thumb_url', 'email', 'username', 'status', 'created_at', 'updated_at', 'permission_groups', 'resellers', 'sites','address_of_publisher','name_of_publisher','contact_number', 'register_type','company_docs_url'];

  [key: string]: any;

  public permissionGroups: Collection<BasePermissionGroupInterface>;


  /**
   * Create
   * @param obj
   */
  public create(obj: any): any {
    const ret: BaseUserInterface = <BaseUserInterface>super.create(obj);

    ret.thumb_url = ret.image_url;

    if (ret.permission_groups ) {
      ret.permission_groups = (new BasePermissionGroup()).createFromCollection(ret.permission_groups);
      ret.permissionGroups = ret.permission_groups;
    }

    return ret;
  }

  /**
   * Get user's full name
   */
  public getFullName() {
    const user = <BaseUserInterface>this;
    //we're doing it to avoid typescript error
    let ret = user.first_name;
    if (user.middle_name) {
      ret += ' ' + user.middle_name;
    }
    ret += ' ' + user.last_name;
    return ret;
  }

  /**
   * Permission check
   */
  public can(permission: string): boolean {
    if (isNullOrUndefined(this.permissionGroups)) {
      return true;
    }
    const groups: BasePermissionGroupInterface[] = this.permissionGroups.all();
    for (const index in groups) {
      if (groups[index].hasPermission(permission)) {
        return true;
      }
    }
    return false;

  }

}
