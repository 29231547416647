import {AppComponent} from './app.component';
import {ConfirmConfigInterface} from './shared/confirm-dialog/confirm-config.interface';
import {NicoModalComponent} from './system/components/modal/NicoModal';
import {EventEmitter} from '@angular/core';

export class AppService {
  /**
   * The app component
   */
  protected appComponent: AppComponent;

  protected collapseEvent: EventEmitter<boolean>;

  protected namespace: string;

  public constructor() {
    this.collapseEvent = new EventEmitter();
  }

  /**
   * Set the app component
   * @param {AppComponent} app
   */
  public setAppComponent(app: AppComponent) {
    this.appComponent = app;
  }

  public showConfirmDialog(config: ConfirmConfigInterface): NicoModalComponent {
    return this.appComponent.showConfirmDialog(config);
  }

  /**
   * get sidenav collapsed event
   */
  public getSidenavCollapseEvent(): EventEmitter<boolean> {
    return this.collapseEvent;
  }

  /**
   * fire sidenav collapse event
   * @param collapsed
   */
  public fireSidenavCollapseEvent(collapsed: boolean) {
    this.collapseEvent.emit(collapsed);
  }

}
