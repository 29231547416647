import {Params} from "@angular/router";

export class GlobalQueryParam {
    protected static instance: GlobalQueryParam;
    protected params: Params;

    public constructor () {
        this.params = {};
    }

    public static getInstance (): GlobalQueryParam {
        if(GlobalQueryParam.instance == null) {
            GlobalQueryParam.instance = new GlobalQueryParam();
        }
        return GlobalQueryParam.instance;
    }

    public setParam (key: string, value: any) {
        this.params[key] = value;
    }

    public getParams () {
        return this.params;
    }
}