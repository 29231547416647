import { Injectable } from "@angular/core";
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {Observable} from "rxjs";
import {NicoSession} from "../../../system/services/NicoSession";
import {environment} from "../../../../environments/environment";

@Injectable()
export class GuestMiddleware implements CanActivate{

  protected user:any;

  /**
   * The constructor
   * @param router
   * @param session
   */
  constructor(protected router:Router, protected session: NicoSession){
    this.session.setNamespace(environment.manage_namespace);
  }


    /**
     * Can active method
     * @override
     * @param route
     * @param state
     * @returns {boolean}
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
        if(!this.user){
          this.user = this.session.getAuthUser();
        }
        if(this.user) {
          this.router.navigate(['manage']);
          return false;
        }
        return true;
    }



}
