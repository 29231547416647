import {BasePublisherInterface} from "../interfaces/BasePublisherInterface";
import {AbstractBaseResource} from "../../../../../system/datamodels/AbstractBaseResource";
import {BaseUser} from "../../user/models/BaseUser";
import {BasePublisherPrefixInterface} from "../interfaces/BasePublisherPrefixInterface";
import * as moment from "moment";
import {Collection} from "../../../../../system/utilities/Collection";

export class BasePublisherPrefix extends AbstractBaseResource implements BasePublisherPrefixInterface  {
    public id: number;

    /**
     * The primary key identifier
     * @type {string}
     */
    primaryKey: string = "id";
    /**
     * The creatable Attributes of the institutions
     */
    creatableAttributes: Array<string> = ['id', 'publisher_id', 'prefix', 'effective_date', 'country_prefix'];

    public create(obj: any): BasePublisherPrefixInterface {
      const ret: BasePublisherPrefixInterface = super.create(obj);
      if (ret.effective_date) {
        ret.effective_date = moment(ret.effective_date, 'YYYY-MM-DD');
      }
      return ret;
    }

    public createFromCollection(items: Collection<any>): Collection<BasePublisherPrefixInterface> {
      const ret: Collection<any> =  super.createFromCollection(items);
      const modelsBeforeToday = ret.where((item: BasePublisherPrefixInterface) => {
        if (!item.effective_date) {
          return false;
        }
        return item.effective_date.isSameOrBefore(moment());
      });
      if (modelsBeforeToday.length > 0) {
        ret.forEach((item: BasePublisherPrefixInterface) => {
          item.active = item.id === modelsBeforeToday.getItem(0).id;
        });
      }

      return ret;
    }

}
