import {animate, state, style, transition, trigger} from '@angular/animations';

export const fadeInOutAnimation = trigger('fadeInFadeOut', [
    state('in', style({
        opacity: 1,
        display: 'block',
    })),
    state('out', style({
        opacity: 0,
        display: 'none',
    })),
    transition('out => in', [
        animate('0.3s')
    ]),
    transition('in => out', [
        animate('0.3s')
    ])
]);
