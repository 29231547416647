import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {isNullOrUndefined, equals} from 'util';
import * as _ from 'lodash';
import { Helper } from "src/app/system/utilities/Helper";


@Component({
  selector: 'app-advanced-search',
  templateUrl: 'advanced-search.component.html',
  styleUrls: ['advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {
  /**
   * Text cleared event
   */
  @Output() update: EventEmitter<AdvanceSearchParamsInterface>;

  /**
   * Advanced search options
   */
  @Input() searchOptions: AdvancedSearchOptionInterface;
  /**
   * Search modal
   */
  public searchModel: AdvanceSearchParamsInterface = {
    title: '',
    sort_order: 'desc',
    status: '',
    sort_by: 'created_at'
  };

  private oldSearchModel: AdvanceSearchParamsInterface;

  constructor() {
    this.update = new EventEmitter();
    this.oldSearchModel = Object.assign({}, this.searchModel);
  }

  /**
   * Get Default options
   */
  private setDefaultOptions(replace?: AdvancedSearchOptionInterface) {

    const option: AdvancedSearchOptionInterface = {
      sortOptionEnabled: true,
      statusOptionEnabled: true,
      textInputEnabled: true
    };

    if (!isNullOrUndefined(replace)) {
      option.textInputEnabled = isNullOrUndefined(replace.textInputEnabled) ? option.textInputEnabled : replace.textInputEnabled;
      option.statusOptionEnabled = isNullOrUndefined(replace.statusOptionEnabled) ? option.statusOptionEnabled : replace.statusOptionEnabled;
      option.sortOptionEnabled = isNullOrUndefined(replace.sortOptionEnabled) ? option.sortOptionEnabled : replace.sortOptionEnabled;
    }

    this.searchOptions = option;
  }

  /**
   * Trigger change event
   */
  private triggerChange() {
    if (!_.isEqual(this.searchModel, this.oldSearchModel)) {
      this.update.emit(this.searchModel);
    }
    this.oldSearchModel = Object.assign({}, this.searchModel);
  }

  /**
   * On init event
   */
  public ngOnInit() {
    this.setDefaultOptions(this.searchOptions);
    if(Helper.getStorage('titleSearch') && Helper.getStorage('getFiltered')){
      this.searchModel.title = Helper.getStorage('titleSearch');
    }
    Helper.removeStorage('titleSearch')
  }

  /**
   * On change event listener
   */
  public onChange() {
    this.triggerChange();
  }

  /**
   * Clear the text
   */
  public clear() {
    this.searchModel.title = '';
    this.triggerChange();
    Helper.removeStorage('titleSearch')
  }

  /**
   * On search command callback
   * @param $event
   */
  public onSearchCommand($event: KeyboardEvent) {
    $event.stopPropagation();
    if ($event.keyCode === 13) {
      this.triggerChange();
    }
  }

}

export interface AdvanceSearchParamsInterface {
  title: string;
  sort_order: 'asc' | 'desc';
  status: string | number;
  sort_by: 'created_at' | 'title' | 'id';
}

export interface AdvancedSearchOptionInterface {
  textInputEnabled?: boolean;
  sortOptionEnabled?: boolean;
  statusOptionEnabled?: boolean;
}
