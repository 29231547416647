import { Inject, Injectable } from '@angular/core';
import { Http, RequestOptionsArgs, Response, RequestMethod, Headers } from '@angular/http';
import { ServerResponse } from '../responses/ServerResponse';
import { NicoSession } from '../services/NicoSession';
import { ToastNotification } from '../services/ToastNotification';
import { TranslateService } from '@ngx-translate/core';
import { Collection } from '../utilities/Collection';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EnvironmentInterface } from '../datamodels/EnvironmentInterface';
import { AuthenticableInterface } from '../datamodels/AuthenticableInterface';
import {Router} from "@angular/router";
import {Helper} from "../utilities/Helper";
import {isNullOrUndefined} from "util";
import {GlobalQueryParam} from "./GlobalQueryParam";
import {BaseUser} from "../../shared/base/contituents/user/models/BaseUser";

// import {UploadInput} from 'ngx-uploader';

@Injectable()
export class NicoHttp {
    /**
     * The authenticated user
     */
    private _authUser: any;

    protected namespace: string = "";

    protected errorListeners: any = {};

    /**
     * Constructor
     * @param http
     * @param session
     * @param toast
     * @param translate
     * @param router
     * @param environment
     */
    public constructor(private http: Http,
        private session: NicoSession,
        private toast: ToastNotification,
        private translate: TranslateService,
        private router: Router,
        @Inject('EnvironmentInterface') private environment: EnvironmentInterface) {

    }

    public getSession(): NicoSession {
        return this.session;
    }

    /**
     * Get the namespace
     */
    public getNamespace (): string {
        return this.session.getNamespace();
    }

    /**
     * Set the namespace
     * @param str
     */
    public setNamespace (str: string) {
        this.namespace = str;
        this.session.setNamespace(str);
        return this;
    }

    /**
     * Set error callback
     * @param fn
     * @param name
     */
    public setErrorCallback (fn: Function, name?:any) {
        if(!this.errorListeners[this.namespace]) {
            this.errorListeners[this.namespace] = [];
        }
        this.errorListeners[this.namespace].push ({
            name: isNullOrUndefined(name) ? Helper.getRandomString(10) : name,
            callback: fn
        });
    }

    private callErrorCallbacks (error: any, namespace?: string) {
        if(!namespace) {
            namespace = this.namespace;
        }

        const listeners: Array<any> = this.errorListeners[namespace];

        if(!listeners) {
            return;
        }

        listeners.forEach((item) => {
            item.callback(error);
        });

    }

    /**
     * Set Auth User
     * @param user
     */
    public setAuthUser(user: AuthenticableInterface) {
        this._authUser = (new BaseUser()).create(user);
        this.session.setAuthUser(user);
    }

    /**
     * Get the authenticated user
     * @return User
     */
    public getAuthUser(): any {
        if (!this._authUser) {
            this._authUser = this.session.getAuthUser();
        }
        return this._authUser;
    }

    /**
     * The request method
     * @param url
     * @param options
     * @param  showToast
     * @param transformResponse
     * @return any
     */
    public request(url: string, options?: RequestOptionsArgs, showToast?: boolean, transformResponse?: boolean): Observable<ServerResponse | any> {
        options = this.mergeOptions(options);
        if (showToast !== false) {
            showToast = true;
        }
        if(isNullOrUndefined(transformResponse)){
            transformResponse = true;
        }
        return this.http.request(url, options).pipe(
            map((response: Response) => {
                if(transformResponse === false) {
                    return response.text();
                }
                const result = ServerResponse.fromResponse(response);
                if (result.body) {
                    if (result.body.access_token) {
                        // we store the access-token for later use
                        this.session.setToken(result.body['access_token']);
                    }

                    if (result.body.data) {
                        result.body.data = new Collection(result.body.data);
                    }

                }
                // if header has the access-token, set the token
                if (response.headers.get('Access-Token')) {
                    this.session.setToken(response.headers.get('Access-Token'));
                }
                return result;
            }),
            catchError((e: any, caught: Observable<any>) => {
                if(transformResponse === false) {
                    return throwError(e);
                }
                const resp = ServerResponse.fromResponse(e);
                let shown = false;

                this.callErrorCallbacks(resp, this.session.getNamespace());

                if(this.session.getNamespace() === this.environment.manage_namespace) {
                    if (resp.status.code === 'Unauthorized' || resp.status.code === 'unauthorized') {
                        // clear the session an redirect to submitForm page
                        this.session.clearAuth();
                        this.session.setPageAttempt(window.location.pathname);
                        shown = true;
                        this.translate.get('session_time_out_label').subscribe((d: string) => {
                            this.toast.info(d, true, null, 'sessionInfoToast');
                        });
                        setTimeout(() => {
                            window.location.href = this.environment.login_page;
                        }, 1000);
                    } else if (e.status === 0) {
                        shown = true;
                        this.translate.get('no_internet_connection_label').subscribe((d: string) => {
                            this.toast.danger(d, true, null, 'noConnectionInfo');
                        });
                    }else if (resp.status.code === 'password_not_updated') {
                        this.toast.info(resp.status.message);
                        this.router.navigate([this.environment.password_update_page]);
                        shown = true;
                    }

                    const result = ServerResponse.fromResponse(e, { messageShown: shown });
                    if (!shown && showToast) {
                        result.status.messageShown = true;
                        this.toast.danger(result.status.message, true, null, 'systemToast');
                    }
                    return throwError(result);
                } else if (this.session.getNamespace() === this.environment.public_namespace) {
                    //try requesting for token
                    const result = ServerResponse.fromResponse(e, { messageShown: shown });

                    if (!shown && showToast) {
                        result.status.messageShown = true;
                        this.toast.danger(result.status.message, true, null, 'systemToast');
                    }
                    return throwError(result);
                }

            })
        );
    }

    /**
     * Get request
     * @param url
     * @param options
     * @param showToast
     * @param transformResponse
     */
    public get(url: string, options?: RequestOptionsArgs, showToast?: boolean, transformResponse?: boolean): Observable<ServerResponse> {
        options = options || {};
        options.method = RequestMethod.Get;
        return this.request(url, options,showToast, transformResponse);
    }

    /**
     * The post request
     * @param url
     * @param body
     * @param options
     * @param  showToast
     * @param transformResponse
     * @return Observable
     */
    public post(url: string, body: any, options?: RequestOptionsArgs, showToast?: boolean, transformResponse?: boolean): Observable<ServerResponse> {
        options = options || {};
        options.method = RequestMethod.Post;
        options.body = body;
        return this.request(url, options,showToast, transformResponse);
    }

    /**
     * The Put request
     * @param url
     * @param body
     * @param options
     * @param  showToast
     * @param transformResponse
     * @return Observable
     */
    public put(url: string, body: any, options?: RequestOptionsArgs, showToast?: boolean, transformResponse?: boolean): Observable<ServerResponse> {
        options = options || {};
        options.method = RequestMethod.Put;
        options.body = body;
        return this.request(url, options,showToast, transformResponse);
    }

    /**
     * The patch request
     * @param url
     * @param body
     * @param options
     * @param  showToast
     * @param transformResponse
     * @return Observable
     */
    public patch(url: string, body: any, options?: RequestOptionsArgs, showToast?: boolean, transformResponse?: boolean): Observable<ServerResponse> {
        options = options || {};
        options.method = RequestMethod.Patch;
        options.body = body;
        return this.request(url, options,showToast, transformResponse);
    }

    /**
     * The head request
     * @param url
     * @param options
     * @param  showToast
     * @param transformResponse
     * @return Observable
     */
    public head(url: string, options?: RequestOptionsArgs, showToast?: boolean, transformResponse?: boolean): Observable<ServerResponse> {
        options = options || {};
        options.method = RequestMethod.Head;
        return this.request(url, options,showToast, transformResponse);
    }

    /**
     * The delete request
     * @param url
     * @param options
     * @param  showToast
     * @param transformResponse
     * @return Observable
     */
    public delete(url: string, options?: RequestOptionsArgs, showToast?: boolean, transformResponse?: boolean): Observable<ServerResponse> {
        options = options || {};
        options.method = RequestMethod.Delete;
        return this.request(url, options,showToast, transformResponse);
    }

    /**
     * The options request
     * @param url
     * @param options
     * @param  showToast
     * @param transformResponse
     * @return Observable
     */
    public options(url: string, options?: RequestOptionsArgs, showToast?: boolean, transformResponse?: boolean): Observable<ServerResponse> {
        options = options || {};
        options.method = RequestMethod.Options;
        return this.request(url, options,showToast, transformResponse);
    }

    /**
     * Merge the given options with default options
     * @param options
     */
    private mergeOptions(options: RequestOptionsArgs): RequestOptionsArgs {
        if (!options.headers) {
            options.headers = new Headers();
        }
        if(!options.params) {
            options.params = {};
        }
        //attach global params
        const params = GlobalQueryParam.getInstance().getParams();
        for(let key in params) {
            options.params[key] = params[key];
        }
        //attach authorization header
        options.headers.append('Authorization', 'Bearer ' + this.session.getToken());
        return options;
    }

    /**
     * The upload request
     * @param url
     * @param options
     * @param  showToast
     * @return Observable
     */
    // public upload (url:string,options?:UploadInput,showToast?:boolean):Observable<ServerResponse> {
    //   options  = options || {};
    //   options.method = RequestMethod.Post;
    //   options.type ='uploadAll';
    //   return this.uploadrequest(url,options);
    // }

}
