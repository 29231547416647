export enum PermissionTypeEnum {
  CreateUsers = 'users.create',
  ReadUsers = 'users.read',
  UpdateUsers = 'users.update',
  DeleteUsers = 'users.delete',
  CreateResellers = 'resellers.create',
  ReadResellers = 'resellers.read',
  UpdateResellers = 'resellers.update',
  DeleteResellers = 'resellers.delete',
  InviteUserResellers = 'resellers.userInvite',
  UpdateAdminResellers = 'resellers.updateAdmin',
  CreateSites = 'sites.create',
  ReadSites= 'sites.read',
  UpdateSites = 'sites.update',
  DeleteSites = 'sites.delete',
  CreateVideos = 'videos.create',
  ReadVideos = 'videos.read',
  UpdateVideos = 'videos.update',
  DeleteVideos = 'videos.delete',
  CreateBuckets = 'buckets.create',
  ReadBuckets = 'buckets.read',
  UpdateBuckets = 'buckets.update',
  DeleteBuckets = 'buckets.delete',
  ReadPermissions = 'permissions.read',
  CreatePermissionGroups = 'permissionGroups.create',
  ReadPermissionGroups = 'permissionGroups.read',
  UpdatePermissionGroups = 'permissionGroups.update',
  DeletePermissionGroups = 'permissionGroups.delete',
  AssignUserPermissionGroups = 'permissionGroups.assignUser',
  RemoveUserPermissionGroups = 'permissionGroups.removeUser',
  CreateCalculators = 'calculators.create',
  ReadCalculators = 'calculators.read',
  UpdateCalculators = 'calculators.update',
  DeleteCalculators = 'calculators.delete',
}
