import {Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../system/controllers/BaseComponent';
import {Collection} from '../../../system/utilities/Collection';
import {BasePermissionGroup} from '../../base/contituents/uac/models/BasePermissionGroup';
import {isNullOrUndefined} from 'util';
import {Observable, Subject} from 'rxjs';
import {PaginatedCollection} from '../../../system/utilities/PaginatedCollection';
import {ServerResponse} from '../../../system/responses/ServerResponse';
import {BaseUser} from '../../base/contituents/user/models/BaseUser';
import {PermissionGroupPermissionOptions} from '../../enums/permission-options';
import {OperatorOptions} from '../../enums/operator-options';
import {PermissionGroupActionInterface} from '../interfaces/permission-group-action.interface';
import {AjaxSpinnerEnum} from '../../../system/services/AjaxSpinner';

@Component({
  selector: 'app-permission-group-list',
  templateUrl: './permission-group-list.component.html',
  styleUrls: ['./permission-group-list.component.scss']
})
export class PermissionGroupListComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() selectedUser: BaseUser;

  @Input() options: PermissionGroupListOptionsInterface;

  @Input() service: PermissionGroupActionInterface | any;

  @Output() onToggle: EventEmitter<any> = new EventEmitter();

  protected selectedPermissionGroups: Collection<BasePermissionGroup>;

  public permissionGroups: Collection<BasePermissionGroup>;

  public permissionGroupPermissionOptions: PermissionGroupPermissionOptions = new PermissionGroupPermissionOptions();

  /**
   * operator AND / OR
   */
  public operatorOption: OperatorOptions = new OperatorOptions();

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (isNullOrUndefined(this.options)) {
      this.options = {select: true, showHeader: true, className: ''};
    } else {
      this.setOptions();
    }
    this.processSelectedUser();
  }

  protected processSelectedUser () {
    if (isNullOrUndefined(this.selectedUser)) {
        this.getPermissionGroups();
    } else {
        if (this.selectedUser.id) {
            this.selectedPermissionGroups = this.selectedUser.permission_groups;
            this.getPermissionGroups().subscribe(() => {
                if (this.selectedPermissionGroups) {
                    this.setSelectedUserPermissionGroups();
                }
            });
        }
    }
  }

  ngOnChanges() {
    this.processSelectedUser();
  }

  public setOptions() {
    this.options.select = isNullOrUndefined(this.options.select) ? true : this.options.select;
    this.options.showHeader = isNullOrUndefined(this.options.showHeader) ? true : this.options.showHeader;
    this.options.className = isNullOrUndefined(this.options.select) ? '' : this.options.className;
  }


  /**
   * get all available permission groups
   */
  public getPermissionGroups(): Observable<any> {
    const sub = new Subject();
    const spinner = this.ajaxSpinner.showSpinner('permissionGroupList', AjaxSpinnerEnum.FloatingBar);
    this.service.get().subscribe((collection: PaginatedCollection<BasePermissionGroup>) => {
      spinner.hide();
      this.permissionGroups = collection;
      this.permissionGroups.forEach(group => {
        group.checked = false;
      });
      sub.next(this.permissionGroups);
    }, (err: ServerResponse) => {
      spinner.hide();
      this.showErrorToast(err.status.message);
      sub.error(err);
    });
    return sub;
  }

  /**
   * set active users permission groups on available permission groups
   */
  public setSelectedUserPermissionGroups() {
    this.permissionGroups.forEach(permissionGroup => {
      this.selectedPermissionGroups.forEach(group => {
        if (group.perm_grp_id === permissionGroup.perm_grp_id) {
          permissionGroup.checked = true;
        }
      });
    });
  }

  /**
   * add/remove permission groups to user
   * @param $event { event: Event }
   * @param group {group: BasePermissionGroup}
   */
  public onSelectPermissionGroup($event, group) {
    $event.stopPropagation();
    const spinner = this.ajaxSpinner.showSpinner(group.htmlId, AjaxSpinnerEnum.TinyFloatingBar);
    if ($event.target.checked) {
      this.service.setPermissionGroup(group.perm_grp_id, this.selectedUser.id).subscribe(() => {
        spinner.hide();
        this.onToggle.emit($event);
      }, (err: ServerResponse) => {
        spinner.hide();
        $event.target.checked = false;
        this.showErrorToast(err.status.message);
      });
    } else {
      this.service.removePermissionGroup(group.perm_grp_id, this.selectedUser.id).subscribe(() => {
        spinner.hide();
        this.onToggle.emit($event);
      }, (err: ServerResponse) => {
        spinner.hide();
        $event.target.checked = true;
        if (!err.status.messageShown) {
            this.showErrorToast(err.status.message);
        }

      });
    }
  }
}


export interface PermissionGroupListOptionsInterface {
  select?: boolean;
  showHeader?: boolean;
  className?: string;
}

