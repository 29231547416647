import {Directive, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[appOnScroll]'
})
export class OnScrollDirective implements OnInit {

  @Output() onScroll: EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
  }

  @HostListener('scroll', ['$event'])
  onListenerTriggered($event) {
    $event.stopPropagation();
    const scrollTop = this.elementRef.nativeElement.scrollTop;
    const scrollHeight = this.elementRef.nativeElement.scrollHeight;
    const offsetHeight = this.elementRef.nativeElement.offsetHeight;
    const contentHeight = scrollHeight - offsetHeight + 1;
    if (contentHeight < scrollTop) {
      this.onScroll.emit($event);
    }
  }
}
