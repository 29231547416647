import {Pipe, PipeTransform} from "@angular/core";
import {ContactTypeEnum} from '../enums/contact-type.enum';
@Pipe({
  name: 'contactType'
})
export class ContactTypePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    value = parseInt(value, 0 );
    switch (value) {
      case ContactTypeEnum.email:
        return "mod_manage.mod_sites.resources.mod_contacts.email_label";
      case ContactTypeEnum.phone:
        return "mod_manage.mod_sites.resources.mod_contacts.phone_label";
      case ContactTypeEnum.address:
        return "mod_manage.mod_sites.resources.mod_contacts.address_label";
      default:
    }
  }
}
