import {AbstractBaseResource} from "../../../../../system/datamodels/AbstractBaseResource";
import {BaseIsbnApplicationInterface} from "../interfaces/BaseIsbnApplicationInterface";
import {BaseResourceInterface} from "../../../../../system/datamodels/BaseResourceInterface";
import {BasePublisher} from "../../publisher/models/BasePublisher";
import {BaseIssuedISBN} from "./BaseIssuedISBN";

export class BaseIsbnApplication extends AbstractBaseResource implements BaseIsbnApplicationInterface  {
    public id: number;
    /**
     * The primary key identifier
     * @type {string}
     */
    primaryKey: string = "id";
    /**
     * The creatable Attributes of the institutions
     */
    creatableAttributes: Array<string> = [
      'id',
      'requested_date', 'isbn_type', 'title',
      'edition', 'subject', 'author_name', 'accession_number', 'received_status',
      'language', 'type', 'corporate_author', 'corporate_author_address',
      'affiliation_author_address', 'year', 'preliminary_page', 'textual_page',
      'number_of_pages', 'price', 'call_number', 'book_number', 'keywords',
      'illustration_type1', 'illustration_color', 'photo_illustration', 'book_cover_url',
      'last_isbn_number', 'deposit_slip_url', 'readonly', 'authors','illustrations','created_at','currency'
    ];

    create(obj: any): BaseIsbnApplication {
      const ret =  <BaseIsbnApplication> super.create(obj);
      if (obj.publisher) {
        ret.publisher = (new BasePublisher()).create(obj.publisher);
      }
      if (obj.issued_isbn) {
        ret.issued_isbn = (new BaseIssuedISBN()).create(obj.issued_isbn);
      }
      return ret;
    }


}
