import {Component, forwardRef, Input, OnChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'font-awesome-icon-input',
  templateUrl: 'font-awesome-icon-input.component.html',
  styleUrls: ['font-awesome-icon-input.component.scss'],
  providers: [
      {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FontAwesomeIconInputComponent), multi: true},
  ],
})
export class FontAwesomeIconInputComponent implements ControlValueAccessor {

    public value;

    public isDisabled;

    protected propagateChangeListener: Function;

    protected propagateTouchListener: Function;

    public dropdownExpanded: boolean = false;

    public items: any[] = [
        {className: 'fa fa-file', label: 'Simple file'},
        {className: 'fa fa-file-pdf', label: 'PDF'},
        {className: 'fa fa-file-word', label: 'Word document'},
        {className: 'fa fa-file-excel', label: 'Excel document'},
        {className: 'fa fa-file-powerpoint', label: 'Presentation document'},
    ];

    writeValue(value: any) {
        for(let i=0; i< this.items.length; i++) {
            if(value === this.items[i].className) {
                this.value = this.items[i];
                break;
            }
        }
    }

    registerOnChange(fn: any) {
        this.propagateChangeListener = fn;
    }

    registerOnTouched(fn: () => any): void {
        this.propagateTouchListener = fn;
    }


    setDisabledState(isDisabled: boolean): void {
      this.isDisabled =isDisabled;
    }

    selectItem(i: any) {
        this.value = i;
        this.propagateChangeListener(this.value.className);
        this.dropdownExpanded = false;
    }

    toggleDropdown() {
        this.dropdownExpanded = !this.dropdownExpanded;
    }

}