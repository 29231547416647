import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'resourceUrl' })
export class ResourceUrlPipe implements PipeTransform {
    transform(input: string) {
        if(typeof input === "undefined"){
            return '';
        }
        var httpStrings = ['http://', 'https://'];
        var output:string = input;
        httpStrings.forEach(httpString => {
            if (input.indexOf(httpString) === 0) {
                output = input.substring(httpString.length);
            }
        });
        return output;
    }
}
