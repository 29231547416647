import {Injectable} from "@angular/core";
import {BaseService} from "../../system/services/BaseService";
import {NicoHttp} from "../../system/requests/NicoHttp";
import {environment} from "../../../environments/environment";
import {BaseIsbnApplication} from "../../shared/base/contituents/isbn-application/models/BaseIsbnApplication";

@Injectable()
export class PublicIsbnsService extends BaseService {
  /**
   * The resource
   * @type {string}
   */
  protected resourceName: string = 'open/publications';
  /**
   * Base resource url
   * @type {string}
   */
  protected resourceBaseUrl: string = '';
  /**
   *
   * @type {User}
   */
  protected resource: BaseIsbnApplication;

  /**
   * Constructor
   * @param http
   */
  public constructor(protected http: NicoHttp) {
    super(http, environment);
    this.resource = new BaseIsbnApplication();
    this.setResourceName(this.resourceName);
  }
}
