import {Component, Directive, ElementRef, EventEmitter, HostListener, Input, Output} from "@angular/core";
import {NicoModalController} from "../components/modal/NicoModalController";
import {NicoModalActionInterface} from "../components/modal/NicoModalActionInterface";

@Directive({
    selector: "[nicoInputExpand]"
})

export class InputExpandDirective {

    @Input() richTextEditor: boolean = false;

    @Output() onEditComplete: EventEmitter<string>;

    @Input() value: string;

    constructor(private el: ElementRef, private nicoCtrl: NicoModalController) {
        this.onEditComplete = new EventEmitter();
    }

    @HostListener('dblclick') onDoubleClick () {
        this.nicoCtrl.create(NicoExpandedTextareaComponent, {
            htmlTemplateAsIs: true,
            modalClass: "expanded-textarea-container custom-modal custom-modal-xl",
            onOkayProcessComplete: (data: any) => {
                this.onEditComplete.emit(data);
            },
            data: {richTextEditor: this.richTextEditor, value: this.value}
        }).present();
    }

}

@Component({
    selector: 'nico-expanded-textarea',
    template: `<section class='nico-expanded-textarea'>
                  <div> <angular-editor *ngIf="data.richTextEditor" [(ngModel)]="value" [config]="{editable: true, minHeight:'20rem'}"
                  ></angular-editor>
                  <textarea *ngIf="!data.richTextEditor" [(ngModel)]="value" class="form-control" rows="10"></textarea></div> 
                  <div class="button-container">
                  <button class="btn btn-primary" (click)="onNicoModalOkayClick()">Okay</button>
                  <button class="btn btn-default" (click)="onNicoModalCancelClick()">Cancel</button>
                  </div>
                  
                </section>`
})
export class NicoExpandedTextareaComponent implements NicoModalActionInterface {
    public value: string = '';
    public data: any;
    public dismisser: ()=>{};
    public okayListener: (value: any) => {};
    canDismissNicoModalOnCancel () {}
    canDismissNicoModalOnOkay () {}
    onNicoModalCancelClick () {
        this.dismisser();
    }

    onNicoModalClose () {
    }

    onNicoModalOkayClick () {
        this.okayListener(this.value);
        this.dismisser();
    }

    setCancelProcessCompleteListener () {
        this.dismisser();
    }

    setNicoModalChildData (data: any) {
        this.data = data;
        if(!this.data) {
            this.data = {};
        }
        this.value = this.data.value;
    }
    setNicoModalDismisser (dismisser: ()=> {}) {
        this.dismisser = dismisser;
    }
    setOkayProcessCompleteListener (okayListener: (value: any) => {}) {
        this.okayListener = okayListener;
    }
    
}