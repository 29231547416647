import {
  Component,
  EventEmitter, Injector,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {AdvancedSelectConfigInterface} from '../../system/components/advanced-select/advanced-select.component';
import {BaseUserService} from '../services/base-user.service';
import {BaseUserInterface} from '../base/contituents/user/interfaces/UserBaseInterface';
import {BaseUser} from '../base/contituents/user/models/BaseUser';
import {BaseComponent} from '../../system/controllers/BaseComponent';
import {NewUserComponent} from '../new-user-component/new-user.component';
import {GeneralUserCreateInterface} from '../new-user-component/general-user-create.interface';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'user-select-input',
  templateUrl: './user-select-input.component.html',
  styleUrls: ['user-select-input.component.scss']
})

export class UserSelectInputComponent extends BaseComponent implements OnInit {

  public userFetchUrl: string = '';

  @ViewChild('tmpl') template: TemplateRef<any>;

  @ViewChild('tmplUnassigned') tmplUnassigned: TemplateRef<any>;
  /**
   * On select event
   */
  @Output() onSelect: EventEmitter<BaseUser>;

  @Output() actionComplete: EventEmitter<any>;

  @Input() value: any;

  @Input() userService: GeneralUserCreateInterface;

  @Input() actionBtnEnabled: boolean;

  @Input() disabled: boolean;

  /**
   * @var config
   */
  public config: AdvancedSelectConfigInterface = {};

  /**
   * Constructor
   * @param injector
   * @param service
   */
  public constructor(injector: Injector, protected service: BaseUserService) {
    super(injector);
    this.onSelect = new EventEmitter();
    this.actionComplete = new EventEmitter();
  }

  /**
   * ON init hook
   */
  public ngOnInit() {
    this.userFetchUrl = this.service.getBaseApiUrl() + '/users';
    this.config.itemTemplate = this.template;
    this.config.emptySelectionTemplate = this.tmplUnassigned;
    this.config.equalityCheckProperty = 'id';
    this.config.actionButtonEnabled = isNullOrUndefined(this.actionBtnEnabled) ? true : this.actionBtnEnabled;
    this.config.actionButtonLabel = 'mod_manage.mod_users.add.add_new_user_label';
  }

  /**
   *
   * @param value
   */
  public onAdvSelect(value: BaseUserInterface) {
    this.onSelect.emit(<any>value);
  }

  public onActionButtonClick(event: MouseEvent) {
    this.nicoCtrl.create(NewUserComponent, {
      htmlTemplateAsIs: true,
      onDestroy: () => {
        this.actionComplete.emit();
      },
      data: {service: this.userService},
      modalClass: 'custom-modal custom-modal-540',
    }).present();
  }

}
