import {Component, Injector, Input, OnInit} from "@angular/core";
import {BaseComponent} from "../../../system/controllers/BaseComponent";
import { PublicNoticeService } from "../../services/public-news.service";
import { ViewPublicNoticeComponent } from "./view-public-notice/view-public-notice.component";
import { ConfirmDialogComponent } from "src/app/shared/confirm-dialog/confirm-dialog.component";
@Component({
  selector: 'public-notice',
  templateUrl: './public-notice.component.html',
  styleUrls: ['./public-notice.component.scss']

})

export class PublicNoticeComponent extends BaseComponent implements  OnInit  {
  // @Input() model: BaseIssuedISBN;
  public constructor(injector: Injector) {
    super(injector);
    this.service = injector.get(PublicNoticeService);
  }

  public ngOnInit(): void {
    this.setPageTitle('ISBN Notices');
    this.getList();
  }

  public ShowMessage(item:any)
  {
      this.nicoCtrl.create(ConfirmDialogComponent, {
        modalTitle: 'Notice',
        showCrossButton: true,       
        showModalFooter: true,
        htmlTemplateAsIs: false,
        // modalClass: 'confirm-dialog',
        data: {message: item.body}
      }).present();
  }


}
