import {Injectable} from "@angular/core";
import {BaseService} from "../../system/services/BaseService";
import {NicoHttp} from "../../system/requests/NicoHttp";
import {environment} from "../../../environments/environment";
import {BasePublisher} from "../../shared/base/contituents/publisher/models/BasePublisher";

@Injectable()
export class PublicPublisherService extends BaseService {
  /**
   * The resource
   * @type {string}
   */
  protected resourceName: string = 'open/publishers';
  /**
   * Base resource url
   * @type {string}
   */
  protected resourceBaseUrl: string = '';
  /**
   *
   * @type {User}
   */
  protected resource: BasePublisher;

  /**
   * Constructor
   * @param http
   */
  public constructor(protected http: NicoHttp) {
    super(http, environment);
    this.resource = new BasePublisher();
    this.setResourceName(this.resourceName);
  }
}
