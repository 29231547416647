import {Component, Injector, OnInit} from "@angular/core";
import {BaseComponent} from "../../../system/controllers/BaseComponent";
import {PublicIsbnsService} from "../../services/public-isbns.service";
import {AuthorsListComponent} from "../../../shared/authors-list-component/authors-list.component";
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'public-isbns',
  templateUrl: './public-isbns.component.html'
})
export class PublicIsbnsComponent extends BaseComponent implements  OnInit {

  public constructor(injector: Injector, private meta: Meta) {
    super(injector);
    this.service = injector.get(PublicIsbnsService);
  }

  public ngOnInit(): void {
  	this.getList();
	this.setPageTitle('Published ISBN');
	this.meta.addTags([
      		{ name: 'title', content: 'Published ISBN Nepal' },
      		{ name: 'description', content: 'TUCL Online ISBN Application published ISBN' },
    	]);
  }

  public onAdvancedSearchUpdate (event) {
    this.urlParams = {keyword: event.title};
    this.getList();
  }

}
