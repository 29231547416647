import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {StatusEnum} from '../../enums/status.enum';
import {StatusToggleCommandPipe} from '../../pipes/status-toggle-command.pipe';
import {isNullOrUndefined} from 'util';
import {PermissionTypeEnum} from '../../../shared/enums/permission-type.enum';

@Component({
  selector: 'app-action-fly-menu',
  template: `
      <div [class]="className" dropdown #dropdown='bs-dropdown' placement="bottom right" *ngIf="commands.length > 0">
          <a class="custom-icon-button" dropdownToggle (click)="stopPropagation($event)">
              <i class="material-icons">
                  {{matIcon}}
              </i>
          </a>
          <ul id="dropdown-custom" *dropdownMenu class="dropdown-menu
         dropdown-menu-right" role="menu"
              aria-labelledby="button-custom">
              <ng-container *ngFor="let item of commands">
                  <ng-container *ngIf="item?.active">

                      <li role="menuitem">
                          <a class="dropdown-item"
                             (click)="callItemClickHandler($event, item);dropdown.hide()">
                              <i [class]="item.icon"></i>
                              {{item.label|translate}}</a>
                      </li>
                  </ng-container>
              </ng-container>
          </ul>
      </div>`
})
export class ActionFlyMenuComponent implements OnInit, OnChanges {
  /**
   * Current Status value
   */
  @Input() status: StatusEnum;

  @Input() placement: string;

  @Input() matIcon: string = 'more_vert';

  @Input() className: string = 'btn-group';

  @Input() addOns: Array<FlyMenuItemInterface>;

  @Input() addOnPrepend: boolean = false;

  @Output() action: EventEmitter<FlyMenuActionEnum | any>;

  @Input() options: ActionFlyMenuOptionsInterface = {};

  public commands: Array<FlyMenuItemInterface> = [];

  public statusMenuItem: FlyMenuItemInterface;

  public showFlyMenu = true;

  /**
   * Constructor
   */
  public constructor() {
    this.action = new EventEmitter();
    this.addOns = [];
  }

  /**
   * Get default actions
   */
  protected getDefaultsActionItems(): Array<FlyMenuItemInterface> {
    const ret: FlyMenuItemInterface[] = [
      {
        label: 'mod_commons.edit_label',
        icon: '',
        name: <any>FlyMenuActionEnum.Edit,
        active: !this.options.disableEditItem,
      },
      {
        label: 'mod_commons.remove_label',
        icon: '',
        name: <any>FlyMenuActionEnum.Remove,
        active: !this.options.disableRemoveItem,
      },

    ];
    if (this.status !== StatusEnum.Suspended) {
      ret.unshift(this.statusMenuItem);
    }
    return ret;
  }

  /**
   * On init life cycle hook
   */
  public ngOnInit() {
    if(isNullOrUndefined(this.options)) {
        this.options = {};
    }
    this.commands = this.getDefaultsActionItems();
    if (!isNullOrUndefined(this.addOns) && this.addOns.length > 0) {
      if (this.addOnPrepend === true) {
        this.commands = [].concat(this.addOns, this.commands);
      } else {
        this.commands = [].concat(this.commands, this.addOns);
      }
    }
  }

  /**
   * On changes event
   * @param change
   */
  public ngOnChanges(change) {
    if (!isNullOrUndefined(this.statusMenuItem)) {
      this.statusMenuItem.label = (new StatusToggleCommandPipe()).transform(this.status);
    }

  }

  /**
   * private action command
   * @param _action
   * @private
   */
  private _action(_action: FlyMenuActionEnum | any) {

    if (_action === FlyMenuActionEnum.Custom) {
      this.action.emit(name);
    } else {
      this.action.emit(_action);
    }

  }

  /**
   * Call item event handler
   * @param event
   * @param item
   */
  public callItemClickHandler(event: Event, item: FlyMenuItemInterface) {
    event.stopPropagation();
    this._action(item.name);
  }

  public stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

}

export enum FlyMenuActionEnum {
  Status, Edit, Remove, Custom
}

export interface FlyMenuItemInterface {
  label: string;
  icon?: string;
  name: string;
  active: boolean;
}

export interface ActionFlyMenuOptionsInterface {
  disableEditItem?: boolean;
  disableRemoveItem?: boolean;
  disableStatusItem?: boolean;
}
