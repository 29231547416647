import {PermissionTypeEnum} from './permission-type.enum';

export class PermissionOptions {
  public READ: PermissionTypeEnum = PermissionTypeEnum.ReadPermissions;
}

export class UserPermissionOptions {
  public CREATE: PermissionTypeEnum = PermissionTypeEnum.CreateUsers;
  public READ: PermissionTypeEnum = PermissionTypeEnum.ReadUsers;
  public UPDATE: PermissionTypeEnum = PermissionTypeEnum.UpdateUsers;
  public DELETE: PermissionTypeEnum = PermissionTypeEnum.DeleteUsers;
}

export class ResellerPermissionOptions {
  public CREATE: PermissionTypeEnum = PermissionTypeEnum.CreateResellers;
  public READ: PermissionTypeEnum = PermissionTypeEnum.ReadResellers;
  public UPDATE: PermissionTypeEnum = PermissionTypeEnum.UpdateResellers;
  public DELETE: PermissionTypeEnum = PermissionTypeEnum.DeleteResellers;
  public UPDATE_ADMIN: PermissionTypeEnum = PermissionTypeEnum.UpdateAdminResellers;
  public INVITE_USER: PermissionTypeEnum = PermissionTypeEnum.InviteUserResellers;
}

export class SitesPermissionOptions {
  public CREATE: PermissionTypeEnum = PermissionTypeEnum.CreateSites;
  public READ: PermissionTypeEnum = PermissionTypeEnum.ReadSites;
  public UPDATE: PermissionTypeEnum = PermissionTypeEnum.UpdateSites;
  public DELETE: PermissionTypeEnum = PermissionTypeEnum.DeleteSites;
}

export class VideoPermissionOptions {
  public CREATE: PermissionTypeEnum = PermissionTypeEnum.CreateVideos;
  public READ: PermissionTypeEnum = PermissionTypeEnum.ReadVideos;
  public UPDATE: PermissionTypeEnum = PermissionTypeEnum.UpdateVideos;
  public DELETE: PermissionTypeEnum = PermissionTypeEnum.DeleteVideos;
}

export class BucketPermissionOptions {
  public CREATE: PermissionTypeEnum = PermissionTypeEnum.CreateBuckets;
  public READ: PermissionTypeEnum = PermissionTypeEnum.ReadBuckets;
  public UPDATE: PermissionTypeEnum = PermissionTypeEnum.UpdateBuckets;
  public DELETE: PermissionTypeEnum = PermissionTypeEnum.DeleteBuckets;
}

export class PermissionGroupPermissionOptions {
  public CREATE: PermissionTypeEnum = PermissionTypeEnum.CreatePermissionGroups;
  public READ: PermissionTypeEnum = PermissionTypeEnum.ReadPermissionGroups;
  public UPDATE: PermissionTypeEnum = PermissionTypeEnum.UpdatePermissionGroups;
  public DELETE: PermissionTypeEnum = PermissionTypeEnum.DeletePermissionGroups;
  public ASSIGN_USER: PermissionTypeEnum = PermissionTypeEnum.AssignUserPermissionGroups;
  public REMOVE_USER: PermissionTypeEnum = PermissionTypeEnum.RemoveUserPermissionGroups;
}

export class CalculatorPermissionOptions {
  public CREATE: PermissionTypeEnum = PermissionTypeEnum.CreateCalculators;
  public READ: PermissionTypeEnum = PermissionTypeEnum.ReadCalculators;
  public UPDATE: PermissionTypeEnum = PermissionTypeEnum.UpdateCalculators;
  public DELETE: PermissionTypeEnum = PermissionTypeEnum.DeleteCalculators;
}
