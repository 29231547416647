import {Component, Input, OnInit} from "@angular/core";
import * as moment from "moment";

@Component({
    selector: 'nico-date',
    template: "<span class='date nico-date'>{{output}}</span>"
})

export class NicoDateComponent implements OnInit {
    /**
     * The output value
     */
    public output: string = "";
    /**
     * Input value
     */
    @Input() value: string;

    @Input() format: string;

    @Input() countUp: boolean = false;

    /**
     *
     */
    public ngOnInit () {
        if (!this.format) {
            this.format = 'MM/DD/YYYY';
        }
        this.output = moment(this.value).utc(true).local().format(this.format);
        if (this.countUp) {
            this.updateOutputForCountUp();
        }
    }

    protected updateOutputForCountUp () {
        const now = moment().utc();
        const diff = now.diff(moment(this.value).utc(true), "m");

        if (diff === 0) {
            this.output = "Less than a minute ago.";
        } else if (diff < 60 ) {
            this.output = `${diff} minutes ago.`;
        } else if (diff < 60 * 24) {
            const hour = Math.floor(diff / 60);
            if (hour < 2) {
                this.output = `${hour} hour ago.`;
            } else {
                this.output = `${hour} hours ago.`;
            }
        }
    }

}
