import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from "../../../../system/controllers/BaseComponent";
import { NicoModalComponent } from "../../../../system/components/modal/NicoModal";
import { Spinner } from "../../../../system/services/AjaxSpinner";
import { ServerResponse } from "../../../../system/responses/ServerResponse";
import {TranslatePipe} from "@ngx-translate/core";

/**
 * This class represents the lazy loaded HomeComponent.
 */
@Component({
    //moduleId: module.id,
    selector: 'reset-password',
    templateUrl: 'reset-password.component.html',
    styleUrls: [],

})
export class ResetPasswordComponent extends BaseComponent implements OnInit, OnDestroy {
    /**
     * The submitForm form
     * @type FormGroup
     */
    public cmptForm: FormGroup;
    /**
     * The Login error message
     * @type string
     */
    public loginErrorMessage: string;
    /**
     * Is submitForm submit busy
     * @type {boolean}
     */
    public formSubmitting: boolean = false;

    public comp: NicoModalComponent;

    public resetSuccessResponse: boolean = false;

    public constructor(inject: Injector,
        protected authService: AuthService) {
        super(inject);
    }

    /**
     * Ng On init implemented method
     */
    public ngOnInit() {
        super.ngOnInit();
        this.setPageTitle('mod_reset_password.module_title');
    }

    /**
     * Life cycle
     */
    public onRouteParamInitialized() {
        this.initForm();
        if (this.isInvalidRequest()) {
            this.router.navigate(['auth/login']);
        }
    }

    /**
     * Check if the access is invalid
     * @return {boolean}
     */
    public isInvalidRequest() {
        return !this.routeParams.get('token')||!this.queryParameters.email;
    }

    /**
     * On onDestroy life cycle message
     */
    public ngOnDestroy() {
    }

    /**
     * Init the submitForm form
     */
    private initForm() {
        this.cmptForm = this.formBuilder.group({
            email: [this.queryParameters.email, [Validators.compose([Validators.required])]],
            token: [this.routeParams.get('token'), [Validators.required]],
            password: ['', [Validators.required]],
            password_confirmation: ['', [Validators.required]],
        });
    }

    /**
     * Login
     */
    public submitForm(event: Event) {
        event.preventDefault();
        if (this.formSubmitting) {
            return;
        }
        this.formSubmitting = true;
        const spinner: Spinner = this.ajaxSpinner.showSpinner();
        this.authService.resetPassword(this.cmptForm.value)
            .subscribe((d: ServerResponse) => {
                this.formSubmitting = false;
                spinner.hide();
                this.resetSuccessResponse = true;
                //TODO: Add translation to toast service
                this.toast.success('Your password has been reset. Please login with new password to continue.');
                this.showPasswordResetSuccessScreen();
            }, (d: ServerResponse) => {
                this.formSubmitting = false;
                if (d.status.statusCode === 417) {
                    this.validationMessenger.attach('cmpntForm', d.body);
                }
                spinner.hide();
            });
    }

    /**
     * Show password reset success screen
     */
    public showPasswordResetSuccessScreen() {
        this.router.navigate(['auth/login']);
        this.resetSuccessResponse = true;
    }


}
