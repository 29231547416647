import { StatusEnum } from "./status.enum";

export class StatusOptions {

    public STATUS_PUBLISHED: StatusEnum = StatusEnum.Published;

    public STATUS_UNPUBLISHED: StatusEnum = StatusEnum.Unpublished;

    public STATUS_SUSPENDED: StatusEnum = StatusEnum.Suspended;

    private _items: Array<StatusEnum> = [StatusEnum.Unpublished, StatusEnum.Published];

    get length() {
        return this._items.length;
    }

    add(item) {

    }
    [Symbol.iterator]() {
        return this._items.values();
    }
}
