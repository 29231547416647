// import {AbstractBaseResource} from "../../../../system/datamodels/AbstractBaseResource";
import {BaseResourceInterface} from "../../../../../system/datamodels/BaseResourceInterface";
import {AbstractBaseResource} from '../../../../../system/datamodels/AbstractBaseResource';

export class NoticeModel extends AbstractBaseResource implements BaseResourceInterface  {
    public id: number;
    /**
     * The primary key identifier
     * @type {string}
     */
    primaryKey: string = "id";
    /**
     * The creatable Attributes of the institutions
     */
    creatableAttributes: Array<string> = ['id', 'title', 'body', 'show_popup'];


}
