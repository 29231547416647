import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'HMS'
})
export class HMSPipe implements PipeTransform {

  transform(value: number): string {
    const time = Number(value);
    const h = Math.floor(time / 3600);
    const m = Math.floor(time % 3600 / 60);
    const s = Math.floor(time % 3600 % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
    const sDisplay = s > 0 ? s + (m === 1 ? "s" : "s") : "";
    return hDisplay + mDisplay + sDisplay;
  }
}
