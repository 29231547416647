import {NgModule} from '@angular/core';
import {PublicModuleComponent} from './public-module.component';
import {PublicModuleRoute} from './public-module.route';
import {PublicCommonModule} from "./public-common.module";
import {PublicPublishersComponent} from "./pages/publishers/public-publishers.component";
import {HomeComponent} from "./pages/home/home.component";
import {NicoPublisherCardComponent} from "./components/nico-publisher-card/nico-publisher-card.component";
import {PublicPublisherService} from "./services/public-publisher.service";
import {PublicIsbnsComponent} from "./pages/isbns/public-isbns.component";
import {NicoIsbnCardComponent} from "./components/nico-isbn-card/nico-isbn-card.component";
import {PublicIsbnsService} from "./services/public-isbns.service";
import {PublicIsbnShowComponent} from "./pages/isbn-show/public-isbn-show.component";
import { PublicNoticeComponent } from './pages/news/public-notice.component';
import { PublicNoticeService } from './services/public-news.service';
import { ViewPublicNoticeComponent } from './pages/news/view-public-notice/view-public-notice.component';
@NgModule({
    declarations: [
      PublicModuleComponent,
      PublicPublishersComponent,
      PublicIsbnsComponent,
      HomeComponent,
      NicoPublisherCardComponent,
      NicoIsbnCardComponent,
      PublicIsbnShowComponent,
      PublicNoticeComponent,
      ViewPublicNoticeComponent
    ],
    imports: [
        PublicCommonModule.forRoot(),
        PublicModuleRoute,
    ],
    providers: [PublicPublisherService, PublicIsbnsService, PublicNoticeService],
})
export class PublicModule {
}
