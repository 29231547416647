import {isNullOrUndefined} from 'util'
import {Helper} from "./Helper";
export class Collection<T> {

  protected _items: Array<T>;

  protected listChangeListeners: any = {};

  constructor(items?: Array<T>) {
    this._items = items;
    if (items === undefined || items === null) {
      this._items = [];
    }
  }

  public setItems(items?: Array<T>) {
    this._items = items;
    this.callListChangeListener();
  }

  /**
   * returns length of array
   */
  get length() {
    return this._items.length;
  }

    /**
     * Get new instance of the class.
     */
  public newInstance(): Collection<T> {
    return new Collection<T>();
  }

    /**
     * Set change listener
     * @param fn
     * @param name
     */
  public addChangeListener (fn: Function, name?: string) {
    if(!name){
      name = Helper.getRandomString(10);
    }
    this.listChangeListeners[name] = fn;
  }
    /**
     * Get item at given index
     * @param index
     * @return T
     */
  public getItem(index: number) {
    return this._items[index];
  }

    /**
     * Remove item from given index
     * @param index
     */
  public removeFromIndex (index: number) {
    this._items.splice(index,1);
  }

    /**
     * Map the collection to new collection
     * @param callbackFn
     */
  public map (callbackFn: (value: T, index: number) => any) {
    const ret: Collection<any> = new Collection<any>();
    this._items.forEach((item: T, index: number) => {
      ret.push(callbackFn(item, index));
    });
    return ret;
  }

    /**
     * Get index of the given item
     * @param item
     * @param compareProperty
     */
  public getIndex (item: T, compareProperty?: string) {
    if(compareProperty){
      let index = -1;
      for(let i=0;i<this._items.length;i++) {
        if(this._items[i][compareProperty] === item[compareProperty]) {
          return index;
        }
      }
      return index;
    }else {
        return this._items.indexOf(item);
    }
  }

    /**
     * Call list change listener
     */
  protected callListChangeListener () {
    for(const name in this.listChangeListeners){
      if(this.listChangeListeners.hasOwnProperty(name)){
          this.listChangeListeners[name]();
      }
    }
  }
  /**
   * add items to array
   * @param item
   * @param index
   */
  public add(item, index?: number) {
    if(isNaN(index) || index < 0 || index > this._items.length ) {
      index = this._items.length;
    }
    this._items.splice(index,0,item);
    this.callListChangeListener();
  }

  /**
   * remove items form array
   * @param item
   * @param compareKey
   */
  public remove(item, compareKey?: string) {
    const oldCount = this._items.length;
    this._items.forEach((value, index) => {
      if(compareKey) {
        if(item[compareKey] == value[compareKey]){
          this._items.splice(index, 1);
        }
      } else if (value === item) {
              this._items.splice(index, 1);
      }


    });
    if(oldCount != this._items.length){
        this.callListChangeListener();
    }
  }

  /**
   * merge to array
   * @param items
   */
  public merge(items?: Array<T>) {
    this._items = this._items.concat(items);
    this.callListChangeListener();
    return this;
  }

  [Symbol.iterator]() {
    return this._items.values();
  }

    /**
     * Empty
     */
  public empty() {
    this._items.length = 0;
    this.callListChangeListener();
    return this;
  }

  public all(): Array<T> {
    return this._items;
  }

  public count(): Number {
    return this._items.length;
  }

  /**
   * Iterate through the items
   * @param callbackFn
   */
  public forEach(callbackFn: (value: T, index: number, array: T[]) => void) {
    this._items.forEach(callbackFn);
  }

  /**
   * Push item to the collection
   * @param item
   */
  public push(item: T) {
    this._items.push(item);
    this.callListChangeListener();
  }

  /**
   * Pop item
   */
  public pop(): T {
    const ret = this._items.pop();
    this.callListChangeListener();
    return ret;
  }

  /**
   * Get first item
   * @param callbackFn
   */
  public first(callbackFn: (obj: any) => boolean): T {
    for (let i = 0; i < this._items.length; i++) {
      if (callbackFn(this._items[i]) === true) {
        return this._items[i];
      }
    }
    return null;
  }

  /**
   *
   * @param callbackFn
   */
  public where(callbackFn: (obj: any) => boolean): Collection<T> {
    const ret = this.newInstance();
    this._items.forEach(element => {
      if (callbackFn(element) === true) {
        ret.push(element);
      }
    });
    return ret;
  }

  /**
   * Compare given value with given operator
   * @param value
   * @param operator
   * @param compareValue
   * @returns boolean
   */
  public compareValue(value: any, operator: '=' | '<' | '>' | '<=' | '>=' | '!=', compareValue: any): boolean {
    switch (operator) {
      case '=':
        return value === compareValue;
      case '<=':
        return value <= compareValue;
      case '>=':
        return value >= compareValue;
      case '<':
        return value < compareValue;
      case '>':
        return value > compareValue;
      case '!=':
        return value !== compareValue;
      default:
        throw Error('Invalid comparision operator');
    }
  }

  /**
   * Find or search items in collection
   * @param  property
   * @param operator
   * @param  compareValue
   * @param  find
   * @returns any
   */
  private findOrSearch(property: number | string, operator: '=' | '<' | '>' | '<=' | '>=' | '!=', compareValue: string | number, find: boolean): any {
    let item: any = null;
    if (find === false) {
      item = this.newInstance();
    }
    let truth: boolean = false;
    this.forEach((v: any) => {
      if (isNullOrUndefined(property)) {
        truth = this.compareValue(v, operator, compareValue);
      } else {
        truth = this.compareValue(v[property], operator, compareValue);
      }
      if (truth === true) {
        if (find === true) {
          item = v;
          return true;
        } else {
          item.push(v);
        }
      }
    });
    return item;
  }

    /**
     * Sort the items
     * @param compareFn
     */
  public sort (compareFn?: (a: T, b: T) => number) {
    this._items.sort(compareFn);
    return this;
  }

}
