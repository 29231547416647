import {ComponentFactoryResolver, Injector, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {NicoModalController} from '../components/modal/NicoModalController';
import {NicoHttp} from '../requests/NicoHttp';
import {AjaxSpinner} from '../services/AjaxSpinner';
import {NicoSession} from '../services/NicoSession';
import {PageTitle} from '../services/PageTitle';
import {SmartValidationMessenger} from '../services/SmartValidationMessenger';
import {ToastEnum, ToastNotification} from '../services/ToastNotification';

import {Location, PlatformLocation} from '@angular/common';
import {StatusOptions} from '../enums/StatusOptions';
import {StatusEnum} from '../enums/status.enum';
import {ServerResponse} from '../responses/ServerResponse';
import {BaseService} from '../services/BaseService';
import {AbstractBaseResource} from '../datamodels/AbstractBaseResource';
import {AppService} from '../../app.service';
import {PaginatedCollection} from '../utilities/PaginatedCollection';
import {UrlParamsInterface} from '../../shared/enums/UrlParamsInterface';
import {AdvanceSearchParamsInterface} from '../components/advanced-search/advanced-search.component';
import {Observable, Subject, Subscription} from 'rxjs';
import {FlyMenuActionEnum} from '../components/action-fly-menu/action-fly-menu.component';
import {BaseResourceInterface} from '../datamodels/BaseResourceInterface';
import {AuthService} from '../../shared/auth/services/auth.service';
import {Collection} from '../utilities/Collection';
import {isDefined} from "@ngx-translate/core/lib/util";
import {isNullOrUndefined} from "util";
import {PreviewImageComponent} from "../../shared/preview-image-component/preview-image.component";
import {EnvironmentInterface} from "../datamodels/EnvironmentInterface";
import {environment} from "../../../environments/environment";
import {BaseUser} from "../../shared/base/contituents/user/models/BaseUser";
import { Helper } from "src/app/system/utilities/Helper";

export abstract class BaseComponent implements OnInit, OnDestroy {

  /**
   * The toast notification object
   */
  protected toast: ToastNotification;
  /**
   * The form builder object
   */
  protected formBuilder: FormBuilder;
  /**
   * Form group we are working on
   */
  public formGroup: FormGroup;
  /**
   * Form we are working on
   */
  public formId: string;
  /**
   * Current working model
   */
  public model: any;


  /**
   * The Nico Http Object
   */
  protected http: NicoHttp;
  /**
   * The ajax Spinner object
   */
  protected ajaxSpinner: AjaxSpinner;
  /**
   * The page title service
   */
  protected pageTitle: PageTitle;
  /**
   * Old page title of the document
   */
  protected oldPageTitle: string;

  protected translate: TranslateService;

  protected validationMessenger: SmartValidationMessenger;

  protected router: Router;

  protected activatedRoute: ActivatedRoute;

  protected routeParams: ParamMap;

  protected session: NicoSession;

  protected queryParameters: Params;

  protected location: Location;

  protected platformLocation: PlatformLocation;

  public statusOptions: StatusOptions = new StatusOptions();
  /**
   * @type BaseService The main service for the component.
   */
  protected service: BaseService;
  /**
   * @type AppService The main app service
   */
  protected appService: AppService;
  /**
   * @type AuthService
   */
  protected authService: AuthService;
  /**
   * @type UrlParamsInterface Url params when fetching list of items from baseService
   */
  public urlParams: UrlParamsInterface = {page: 1, sort_by: 'created_at', sort_order: 'desc'};
  /**
   * The main items to be displayed  on list component
   */
  public items: PaginatedCollection<AbstractBaseResource> = new PaginatedCollection();

  /**
   * The Nico Modal Controller object
   */
  protected nicoCtrl: NicoModalController;

  protected domainChangeSubscription: Subscription;

  public environment: EnvironmentInterface;

  public authUser: BaseUser;


  /**
   * Constructor
   * @param injector
   */
  public constructor(injector: Injector) {
    this.toast = injector.get(ToastNotification);
    this.formBuilder = injector.get(FormBuilder);
    this.http = injector.get(NicoHttp);
    this.ajaxSpinner = injector.get(AjaxSpinner);
    this.nicoCtrl = injector.get(NicoModalController);
    this.pageTitle = injector.get(PageTitle);
    this.translate = injector.get(TranslateService);
    this.validationMessenger = injector.get(SmartValidationMessenger);
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.session = injector.get(NicoSession);
    this.location = injector.get(Location);
    this.nicoCtrl.getViewController().setComponentFactoryResolver(injector.get(ComponentFactoryResolver));
    this.platformLocation = injector.get(PlatformLocation);
    this.appService = injector.get(AppService);
    this.authService = injector.get(AuthService);
    this.environment = environment;
    this.authUser = <BaseUser>this.session.getAuthUser();
  }

  /**
   *  the Method that is called after route param is initialized
   */
  public onRouteParamInitialized() {

  }

  /**
   * the init lifecycle method
   */
  public ngOnInit(): void {
    this.oldPageTitle = this.pageTitle.getTitle();
    this.pageTitle.showAddOn(true);
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
        this.queryParameters = queryParams;
        this.routeParams = params;
        this.onRouteParamInitialized();
      });

    });
  }

  public ngOnDestroy(): void {
    if (this.domainChangeSubscription) {
      this.domainChangeSubscription.unsubscribe();
    }
  }

  public createSubdomainUrl(domain: string) {
    const loc: any = (<any>(this.platformLocation)).location;

    return loc.protocol + '//' + domain + '.' + loc.host;

  }


  /**
   * Set page title
   * @param langString
   * @param extras Extra string that will be appended to the main title
   */
  public setPageTitle(langString: string, extras?: string) {
    this.translate.get(langString).subscribe((d: string) => {
      let title = d;
      if (extras) {
        title += ': ' + extras;
      }
      this.pageTitle.setTitle(title);
    });
  }

  /**
   *
   * @param langString
   * @param type
   * @param autoHide
   * @param timeout
   * @param position
   * @param id
   */
  public showToast(langString: string, type: ToastEnum, autoHide?: boolean, timeout?: number, position?: any, id?: string) {
    this.translate.get(langString).subscribe((d: string) => {
      this.toast.showToast(d, null, type, autoHide, timeout, position, id);
    });
  }

  /**
   * Show Success toast
   * @param langString
   * @param autoHide
   * @param timeout
   * @param position
   * @param id
   */
  public showSuccessToast(langString: string, autoHide?: boolean, timeout?: number, position?: any, id?: string) {
    this.showToast(langString, ToastEnum.Success, autoHide, timeout, position, id);
  }

  /**
   * Show Error Toast
   * @param langString
   * @param autoHide
   * @param timeout
   * @param position
   * @param id
   */
  public showErrorToast(langString: string, autoHide?: boolean, timeout?: number, position?: any, id?: string) {
    this.showToast(langString, ToastEnum.Danger, autoHide, timeout, position, id);
  }

  /**
   * Show ban Toast
   * @param langString
   * @param autoHide
   * @param timeout
   * @param position
   * @param id
   */
  public showBanToast(langString: string, autoHide?: boolean, timeout?: number, position?: any, id?: string) {
    this.showToast(langString, ToastEnum.Ban, autoHide, timeout, position, id);
  }

  /**
   * Show Info toast
   * @param langString
   * @param autoHide
   * @param timeout
   * @param position
   * @param id
   */
  public showInfoToast(langString: string, autoHide?: boolean, timeout?: number, position?: any, id?: string) {
    this.showToast(langString, ToastEnum.Info, autoHide, timeout, position, id);
  }

  /**
   * Show warning toast
   * @param langString
   * @param autoHide
   * @param timeout
   * @param position
   * @param id
   *
   */
  public ShowWarningToast(langString: string, autoHide?: boolean, timeout?: number, position?: any, id?: string) {
    this.showToast(langString, ToastEnum.Warning, autoHide, timeout, position, id);
  }

  /**
   * Show default toast
   * @param langString
   * @param autoHide
   * @param timeout
   * @param position
   * @param id
   *
   */
  public ShowDefaultToast(langString: string, autoHide?: boolean, timeout?: number, position?: any, id?: string) {
    this.showToast(langString, ToastEnum.Default, autoHide, timeout, position, id);
  }

  /**
   * app reload confirmation message
   */
  public getReloadConfirmationMessage(): string {
    return 'mod_commons.app_reload_confirmation_message';
  }

  /**
   * Toggle item's status
   * @param item
   */
  public togglePublish(item: AbstractBaseResource): Observable<any> {
    const ret = new Subject();
    this.service.togglePublish(item[item.primaryKey], item.status, {params: this.urlParams}).subscribe((response) => {
      item.status = item.status === StatusEnum.Published ? StatusEnum.Unpublished : (item.status === StatusEnum.Unpublished ?
        StatusEnum.Published : item.status);
      return ret.next(response);
    }, (response: ServerResponse) => {
      if (!response.status.messageShown) {
        this.showErrorToast(response.status.message);
      }
      return ret.error(response);
    });
    return ret;
  }

  /**
   * Get list of items
   */
  public getList(showToast?: boolean): Observable<any> {
    const spinner = this.ajaxSpinner.showSpinner();
    const ret = new Subject();
    const subscription = this.service.get({params: this.urlParams}, showToast).subscribe((collection: PaginatedCollection<AbstractBaseResource>) => {
      if (subscription) {
        subscription.unsubscribe();
      }
      spinner.hide();
      this.items = collection;
      return ret.next(collection);
    }, (response: ServerResponse) => {
      if (subscription) {
        subscription.unsubscribe();
      }
      spinner.hide();

      if (!response.status.messageShown) {
        if (isNullOrUndefined(showToast)) {
          this.showErrorToast(response.status.message);
        } else if (showToast) {
          this.showErrorToast(response.status.message);
        }
      }
      return ret.error(response);
    });
    return ret;
  }

  /**
   * On page change callback
   * @param page
   */
  public onPageChange(page: number) {
    this.urlParams.page = page;
    this.getList();
  }


  /**
   * Check if the collection is empty
   */
  public emptyList(): boolean {
    if (this.items) {
      return this.items.length === 0;
    }
    return true;
  }


  /**
   * Get delete confirmation message
   */
  protected getDeleteConfirmationMessage(): string {
    return 'mod_commons.delete_confirmation_message';
  }

  protected getDeleteSuccessMessage(): string {
    return 'mod_commons.delete_success_message';
  }

  /**
   * Destroy the item
   * @param item
   */
  destroy(item: AbstractBaseResource) {
    this.appService.showConfirmDialog({
      message: this.getDeleteConfirmationMessage(),
      onConfirm: () => {
        const spinner = this.ajaxSpinner.showSpinner();
        const subscription = this.service.destroy(item).subscribe(() => {
          if (subscription) {
            subscription.unsubscribe();
          }
          spinner.hide();
          this.urlParams.page = 1;
          this.getList();
          this.showSuccessToast(this.getDeleteSuccessMessage());
        }, (response: ServerResponse) => {
          spinner.hide();
          if (subscription) {
            subscription.unsubscribe();
          }

          if (!response.status.messageShown) {
            this.showErrorToast(response.status.message);
          }
        }, () => {
          spinner.hide();
        });
      }
    }).present();
  }

  save (): Observable<any> {
    const spinner = this.ajaxSpinner.showSpinner();
    const subject = new Subject();
    const subscription = this.service.save(this.formGroup.value, this.model ? this.model.id : null, null, false).subscribe((resp: any) => {
      if (subscription) {
        subscription.unsubscribe();
      }
      spinner.hide();
      this.showSuccessToast("The item has been saved successfully.");
      subject.next(resp);
    }, (response: ServerResponse) => {
      if (subscription) {
        subscription.unsubscribe();
      }
      subject.error(response);
      spinner.hide();
      this.showErrorToast(response.status.message);
      if (response.status.statusCode === 417 && this.formId) {
        this.validationMessenger.attach(this.formId, response.body);
      }
    });
    return subject;
  }

  /**
   * Edit the item
   * @param item
   */
  public editItem(item: BaseResourceInterface) {

  }

  /**
   *
   * @param evt
   * @param item
   */
  public onFlyMenuAction(evt: FlyMenuActionEnum | any, item: any) {
    switch (evt) {
      case FlyMenuActionEnum.Remove:
        this.destroy(item);
        break;
      case FlyMenuActionEnum.Edit:
        this.editItem(item);
        break;
      case FlyMenuActionEnum.Status:
        this.togglePublish(item);
        break;
      default:
    }
  }


  /**
   * On search change method
   * @param params
   */
  public onSearchChange(params: AdvanceSearchParamsInterface) {
    Helper.setStorage('titleSearch', params.title);
    this.urlParams.title = params.title;
    this.urlParams.status = <any>params.status;
    this.urlParams.sort_by = params.sort_by;
    this.urlParams.sort_order = params.sort_order;
    this.urlParams.page = 1;
    this.getList();
  }

  public viewImage (url: string, title: string) {
    if (url) {
      this.nicoCtrl.create(PreviewImageComponent, {
        data: {src: url},
        htmlTemplateAsIs: false,
        modalTitle: title,
        modalClass: "custom-modal custom-modal-md",
      }).present();
    }
  }

  /**
   * change older for given list
   * @param list
   */
  public changeOrder(list: Collection<BaseResourceInterface>) {
    const sub = new Subject();
    this.service.order(list).subscribe(() => {
      sub.next();
    }, (response: ServerResponse) => {
      return sub.error(response);
    });
    return sub;
  }
}
