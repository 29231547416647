import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from "../../../../system/controllers/BaseComponent";
import { Spinner } from "../../../../system/services/AjaxSpinner";
import { ServerResponse } from "../../../../system/responses/ServerResponse";

/**
 * This class represents the lazy loaded HomeComponent.
 */
@Component({
    //moduleId: module.id,
    selector: 'update-password',
    templateUrl: 'password-update.component.html',
    styleUrls: [],

})
export class PasswordUpdateComponent extends BaseComponent implements OnInit, OnDestroy {
    /**
     * The submitForm form
     * @type FormGroup
     */
    public cmptForm: FormGroup;
    /**
     * The Login error message
     * @type string
     */
    public loginErrorMessage: string;
    /**
     * Is submitForm submit busy
     * @type {boolean}
     */
    public formSubmitting: boolean = false;

    public resetSuccessResponse: boolean = false;

    public constructor(inject: Injector,
        protected authService: AuthService) {
        super(inject);
    }

    /**
     * Ng On init implemented method
     */
    public ngOnInit() {
        super.ngOnInit();
        this.setPageTitle('mod_password_update.module_title');
    }

    /**
     * Life cycle
     */
    public onRouteParamInitialized() {
        this.initForm();
    }



    /**
     * On onDestroy life cycle message
     */
    public ngOnDestroy() {
    }

    /**
     * Init the submitForm form
     */
    private initForm() {
        this.cmptForm = this.formBuilder.group({
            new_password: ['', [Validators.required]],
            old_password: ['', [Validators.required]],
            new_password_confirmation: ['', [Validators.required]],
        });
    }

    /**
     * Login
     */
    public submitForm(event: Event) {
        event.preventDefault();
        if (this.formSubmitting) {
            return;
        }
        this.formSubmitting = true;
        const spinner: Spinner = this.ajaxSpinner.showSpinner();
        this.authService.updatePassword(this.cmptForm.value)
            .subscribe((d: ServerResponse) => {
                this.formSubmitting = false;
                spinner.hide();
                this.resetSuccessResponse = true;
                this.showSuccessToast('mod_password_update.password_reset_success_label');
                this.showPasswordResetSuccessScreen();
            }, (d: ServerResponse) => {
                this.formSubmitting = false;
                if (d.status.statusCode === 417) {
                    this.validationMessenger.attach('cmpntForm', d.body);
                }
                spinner.hide();
            });
    }

    /**
     * Show password reset success screen
     */
    public showPasswordResetSuccessScreen() {
        this.router.navigate(['manage']);
        this.resetSuccessResponse = true;
    }


}
