import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { Observable } from 'rxjs';
import { ResetPasswordInterface } from '../Interfaces/ResetPasswordInterface';
import { ForgotPasswordInterface } from '../Interfaces/ForgotPasswordInterface';
import { CredentialInterface } from '../Interfaces/CredentialInterface';
import { map } from 'rxjs/internal/operators';
import { environment } from "../../../../environments/environment";
import { BaseService } from "../../../system/services/BaseService";
import { NicoHttp } from "../../../system/requests/NicoHttp";
import { ServerResponse } from "../../../system/responses/ServerResponse";
import {BaseUser} from "../../base/contituents/user/models/BaseUser";


@Injectable()
export class AuthService extends BaseService {
    /**
     * The resource
     * @type {string}
     */
    protected resourceName: string = 'auth';
    /**
     * Base resource url
     * @type {string}
     */
    protected resourceBaseUrl: string = '';
    /**
     *
     * @type {any}
     */
    protected resource: User = null;

    protected urlConfig: any = {
        login: 'authenticate',
        logout: 'logout',
        authUser: 'me',
        forgotPassword: 'password/reset',
        resetPassword: 'password/reset/{token}',
        passwordUpdate: 'me/password',
        register: 'auth/register'
    };


    /**
     * Constructor
     * @param http
     */
    public constructor(protected http: NicoHttp) {
        super(http, environment);
        this.resource = new User();
        this.setResourceName(this.resourceName);
    }

    /**
     * Login using the given credential
     * @param credential
     */
    public login(credential: CredentialInterface): Observable<any> {
        return this.http.post(`${this.resourceBaseUrl}/${this.urlConfig.login}`, credential, null, false).pipe(map((d: ServerResponse) => {
            return d.body;
        }));
    }

    /**
     *
     * @param {ForgotPasswordInterface} input
     * @returns {Observable<any>}
     */
    public forgotPassword(input: ForgotPasswordInterface): Observable<any> {
        return this.http.post(`${this.getBaseApiUrl()}/${this.urlConfig.forgotPassword}`, input);
    }

    public resetPassword(input: ResetPasswordInterface): Observable<any> {
        return this.http.post(this.buildUrlFromParams(this.getBaseApiUrl() + '/' + this.urlConfig.resetPassword, { token: input.token }), input);
    }

    public updatePassword(input: ResetPasswordInterface): Observable<any> {
        return this.http.put(this.resourceBaseUrl + '/' + this.urlConfig.passwordUpdate, input);
    }

    public register(input: BaseUser): Observable<any> {
        return this.http.post(`${this.getBaseApiUrl()}/${this.urlConfig.register}`, input, null, false);
    }

    /**
     * The Auth user info from server
     * @return {Observable<R>}
     */
    public getAuthUser(): Observable<any> {
        return this.http.get(`${this.resourceBaseUrl}/${this.urlConfig.authUser}`).pipe(map((d: ServerResponse) => {
            //set the authenticated user to the request
            const user = this.resource.create(d.body);
            this.http.setAuthUser(user);
            return user;
        }));
    }

    /**
     * logout of application
     * @returns {Observable<any>}
     */
    public logout(): Observable<any> {
        return this.http.get(`${this.resourceBaseUrl}/${this.urlConfig.logout}`);
    }
}
