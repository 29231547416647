import {Collection} from './Collection';
import {isNullOrUndefined} from 'util';

export class PaginatedCollection<T> extends Collection<T> {
  /**
   * @var {string} firstPageUrl
   */
  public firstPageUrl: string;
  /**
   * @var {number} from
   */
  public from: number;
  /**
   * @var {string} lastPageUrl
   */
  public lastPageUrl: string;
  /**
   * @var {string} nextPageUrl
   */
  public nextPageUrl: string;
  /**
   * @var {string} path
   */
  public path: string;
  /**
   * @var {number} perPage
   */
  public perPage: number;
  /**
   * @var {string} prevPageUrl
   */
  public prevPageUrl: string;
  /**
   * @var {number} to
   */
  public to: number;
  /**
   * @var {number} total
   */
  public total: number;
  /**
   * @var {number} total
   */
  public currentPage: number;

  /**
   * pagination properties
   */
  protected paginatorProperties: Array<string>;

  /**
   * Constructor
   * @param items
   */
  public constructor(items?: Array<T>) {
    {
      super(items);
      this.paginatorProperties = ['firstPageUrl', 'lastPageUrl', 'nextPageUrl', 'path', 'perPage', 'prevPageUrl', 'to', 'total', 'currentPage'];
    }
  }

    /**
     * Get new instance
     */
  public newInstance (): PaginatedCollection<T> {
    return new PaginatedCollection<T>();
  }

  /**
   * set pagination properties
   * @param values
   */
  public setPaginatorProperties(values: any) {
    this.paginatorProperties.forEach(item => {
      if (!isNullOrUndefined(values[item])) {
        this[item] = values[item];
      }
    });
    return this;
  }
}
