import {Component, Injector, OnInit} from "@angular/core";
import {BaseComponent} from "../../../../system/controllers/BaseComponent";
import {PublicIsbnsService} from "../../../services/public-isbns.service";
import {AuthorsListComponent} from "../../../../shared/authors-list-component/authors-list.component";

@Component({
  selector: 'view-public-notice',
  templateUrl: './view-public-notice.component.html'
})
export class ViewPublicNoticeComponent extends BaseComponent implements  OnInit {

  public constructor(injector: Injector) {
    super(injector);
    this.service = injector.get(PublicIsbnsService);
  }

  public ngOnInit(): void {
    // this.getList();
  }


}
